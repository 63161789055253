import React from "react";
import { faAdd, faPrint, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MultiSelect,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import "./RoomRack.scss";

interface Room {
  id: number; // Odanın benzersiz kimliği
  numara: string; // Odanın numarası
  tur: string; // Odanın türü (tek kişilik, çift kişilik, süit, vb.)
  durum: string; // Odanın durumu (boş, dolu, temizleniyor, vb.)
  fiyat: number; // Odanın gecelik fiyatı
  misafir?: string; // (Opsiyonel) Oda doluysa, mevcut misafir adı
}


const RoomRack: React.FC = () => {
  const [value1, setValue1] = React.useState<string[]>(["KONUM1"]);

  const onChange = (event: MultiSelectChangeEvent) => {
    setValue1([...event.value]);
  };

  return (
    <>
      <div className="container-fluid my-2 ">
        <div className="row">
          <div className="col-md-12 bg-white shadow-lg rounded p-3">
            <div className="row">
              <div className="col-1">
                <div className="d-flex flex-row gap-2">
                  <div className="">
                    <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                      <FontAwesomeIcon icon={faRefresh} />
                    </div>
                  </div>
                  <div className="">
                    <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                      <FontAwesomeIcon icon={faAdd} />
                    </div>
                  </div>
                  <div className="">
                    <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                      <FontAwesomeIcon icon={faPrint} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1">
                <div className="d-flex flex-column border">
                  <p>Konum/kat</p>
                  <div>
                    <MultiSelect
                      data={sports}
                      onChange={onChange}
                      value={value1}
                      placeholder="Please select ..."
                    />
                  </div>
                  <div>
                    <MultiSelect
                      data={sports}
                      onChange={onChange}
                      value={value1}
                      placeholder="Please select ..."
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="border">
                  <p>Fiyat Bilgileri</p>
                  <div className="row">
                    <div className="col-md-6 d-flex flex-column">
                      <div className="d-flex align-items-center justify-content-Start">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Konaklama
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Konaklama"
                        ></Input>
                      </div>
                      <div className="d-flex align-items-center justify-content-Start">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Fiyat
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Fiyat"
                        ></Input>
                      </div>
                    </div>
                    <div className="col-md-6 col-md-6 d-flex flex-column">
                      <div className="d-flex align-items-center justify-content-Start">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Room
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Room"
                        ></Input>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Room"
                        ></Input>
                      </div>
                      <div className="d-flex align-items-center justify-content-Start">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Extra
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Extra"
                        ></Input>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Extra"
                        ></Input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="container-fluid">
                  <div className="row">
                    <div className=" gap-2 d-flex flex-wrap  ">
                      <div className="d-flex w-15 bg-success align-items-center justify-content-Start border rounded">
                        <span className="w-40 text-white d-flex justify-content-start align-items-center">
                          Boş
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div
                        className="d-flex w-15 align-items-center justify-content-Start border rounded"
                        style={{ backgroundColor: "#9B59B6" }}
                      >
                        <span className="w-40 text-white d-flex justify-content-start align-items-center">
                          Arızalı
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex w-15 bg-warning align-items-center justify-content-Start border rounded">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Doğum Günü
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          HK Durum
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Oda
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex  w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Pax
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40 text-white d-flex justify-content-start align-items-center">
                          Ödeme
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex  bg-danger w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40  d-flex justify-content-start align-items-center">
                          Dolu
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div
                        className="d-flex w-15 align-items-center justify-content-Start border rounded"
                        style={{ backgroundColor: "#2980B9" }}
                      >
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Boş Kirli
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div
                        className="d-flex w-15 align-items-center justify-content-Start border rounded"
                        style={{ backgroundColor: "#FADBD8" }}
                      >
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Evlilik Yıldönümü
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Konum
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Adı
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Giriş Tarihi
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Alış Tarihi
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Blokeli
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div
                        className="d-flex w-15 align-items-center justify-content-Start border rounded"
                        style={{ backgroundColor: "#e59866" }}
                      >
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Çıkacak
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div
                        className="d-flex w-15 align-items-center justify-content-Start border rounded"
                        style={{ backgroundColor: "#aed6f1" }}
                      >
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Repeat
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Yatak
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Soyadı
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Çıkış Tarihi
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                      <div className="d-flex  w-15 align-items-center justify-content-Start border rounded">
                        <span className="w-40 d-flex justify-content-start align-items-center">
                          Acenta
                        </span>
                        <Input
                          className="w-60"
                          type="text"
                          placeholder="Acenta Kodu"
                        ></Input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 bg-white shadow-lg rounded p-3 mt-4">
            <RoomRack1 rooms={rooms} />
          </div>
        </div>
      </div>
    </>
  );
};
export default RoomRack;

const RoomCard = ({ numara, tur, durum, fiyat, misafir }: Room) => {
  return (
    <div className={`room-card ${durum.toLowerCase()}`}>
      <h2>Oda {numara}</h2>
      <p>Tür: {tur}</p>
      <p>Durum: {durum}</p>
      <p>Fiyat: ${fiyat}</p>
      {misafir && <p>Misafir: {misafir}</p>}
    </div>
  );
};
const RoomRack1 = ({ rooms }: { rooms: Room[] }) => {
  return (
    <div className="room-rack d-flex  gap-2 flex-wrap ">
      {rooms.map((room) => (
        <RoomCard key={room.id} {...room} />
      ))}
    </div>
  );
};
const rooms: Room[] = [
  {
    id: 1,
    numara: "101",
    tur: "Tek Kişilik",
    durum: "Available",
    fiyat: 100,
  },
  {
    id: 2,
    numara: "102",
    tur: "Çift Kişilik",
    durum: "Available",
    fiyat: 150,
    misafir: "John Doe",
  },
  {
    id: 3,
    numara: "103",
    tur: "Süit",
    durum: "Occupied",
    fiyat: 200,
    misafir: "Jane Doe",
  },
  {
    id: 4,
    numara: "104",
    tur: "Tek Kişilik",
    durum: "Occupied",
    fiyat: 100,
  },
  {
    id: 5,
    numara: "105",
    tur: "Çift Kişilik",
    durum: "Cleaning",
    fiyat: 150,
    misafir: "Jane Doe",
  },
  {
    id: 6,
    numara: "106",
    tur: "Süit",
    durum: "Cleaning",
    fiyat: 200,
  },
  {
    id: 7,
    numara: "107",
    tur: "Tek Kişilik",
    durum: "Available",
    fiyat: 100,
  },
  {
    id: 8,
    numara: "108",
    tur: "Çift Kişilik",
    durum: "Available",
    fiyat: 150,
  },
  {
    id: 9,
    numara: "109",
    tur: "Süit",
    durum: "Occupied",
    fiyat: 200,
    misafir: "Jane Doe",
  },
  {
    id: 10,
    numara: "110",
    tur: "Tek Kişilik",
    durum: "Occupied",
    fiyat: 100,
    misafir: "John Doe",
  },
  {
    id: 11,
    numara: "111",
    tur: "Çift Kişilik",
    durum: "Cleaning",
    fiyat: 150,
  },
  {
    id: 12,
    numara: "112",
    tur: "Süit",
    durum: "Cleaning",
    fiyat: 200,
  },
  {
    id: 13,
    numara: "113",
    tur: "Tek Kişilik",
    durum: "Available",
    fiyat: 100,
  },
  {
    id: 14,
    numara: "114",
    tur: "Çift Kişilik",
    durum: "Available",
    fiyat: 150,
  },
  {
    id: 15,
    numara: "115",
    tur: "Süit",
    durum: "Occupied",
    fiyat: 200,
    misafir: "Jane Doe",
  },
  {
    id: 16,
    numara: "116",
    tur: "Tek Kişilik",
    durum: "Occupied",
    fiyat: 100,
    misafir: "John Doe",
  },
  {
    id: 17,
    numara: "117",
    tur: "Çift Kişilik",
    durum: "Cleaning",
    fiyat: 150,
  },
  {
    id: 18,
    numara: "118",
    tur: "Süit",
    durum: "Cleaning",
    fiyat: 200,
  },
  {
    id: 19,
    numara: "119",
    tur: "Tek Kişilik",
    durum: "Available",
    fiyat: 100,
  },
  {
    id: 20,
    numara: "120",
    tur: "Çift Kişilik",
    durum: "Available",
    fiyat: 150,
  },
  {
    id: 21,
    numara: "121",
    tur: "Süit",
    durum: "Occupied",
    fiyat: 200,
    misafir: "Jane Doe",
  },
  {
    id: 22,
    numara: "122",
    tur: "Tek Kişilik",
    durum: "Occupied",
    fiyat: 100,
    misafir: "John Doe",
  },
  {
    id: 23,
    numara: "123",
    tur: "Çift Kişilik",
    durum: "Cleaning",
    fiyat: 150,
  },
  {
    id: 24,
    numara: "124",
    tur: "Süit",
    durum: "Cleaning",
    fiyat: 200,
  },
  {
    id: 25,
    numara: "125",
    tur: "Tek Kişilik",
    durum: "Available",
    fiyat: 100,
  },
  {
    id: 26,
    numara: "126",
    tur: "Çift Kişilik",
    durum: "Available",
    fiyat: 150,
  },
  {
    id: 27,
    numara: "127",
    tur: "Süit",
    durum: "Occupied",
    fiyat: 200,
    misafir: "Jane Doe",
  },
  {
    id: 28,
    numara: "128",
    tur: "Tek Kişilik",
    durum: "Occupied",
    fiyat: 100,
    misafir: "John Doe",
  },
  {
    id: 29,
    numara: "129",
    tur: "Çift Kişilik",
    durum: "Cleaning",
    fiyat: 150,
  },
  {
    id: 30,
    numara: "130",
    tur: "Süit",
    durum: "Cleaning",
    fiyat: 200,
  },
  {
    id: 31,
    numara: "131",
    tur: "Tek Kişilik",
    durum: "Available",
    fiyat: 100,
  },
  {
    id: 32,
    numara: "132",
    tur: "Çift Kişilik",
    durum: "Available",
    fiyat: 150,
  },
  {
    id: 33,
    numara: "133",
    tur: "Süit",
    durum: "Occupied",
    fiyat: 200,
    misafir: "Jane Doe",
  },
  {
    id: 34,
    numara: "134",
    tur: "Tek Kişilik",
    durum: "Occupied",
    fiyat: 100,
    misafir: "John Doe",
  },
  {
    id: 35,
    numara: "135",
    tur: "Çift Kişilik",
    durum: "Cleaning",
    fiyat: 150,
  },
  {
    id: 36,
    numara: "136",
    tur: "Süit",
    durum: "Cleaning",
    fiyat: 200,
  },
  {
    id: 37,
    numara: "137",
    tur: "Tek Kişilik",
    durum: "Available",
    fiyat: 100,
  },
  {
    id: 38,
    numara: "138",
    tur: "Çift Kişilik",
    durum: "Available",
    fiyat: 150,
  },
  {
    id: 39,
    numara: "139",
    tur: "Süit",
    durum: "Occupied",
    fiyat: 200,
    misafir: "Jane Doe",
  },
  {
    id: 40,
    numara: "140",
    tur: "Tek Kişilik",
    durum: "Occupied",
    fiyat: 100,
    misafir: "John Doe",
  },
  {
    id: 41,
    numara: "141",
    tur: "Çift Kişilik",
    durum: "Cleaning",
    fiyat: 150,
  },
  {
    id: 42,
    numara: "142",
    tur: "Süit",
    durum: "Cleaning",
    fiyat: 200,
  },
  {
    id: 43,
    numara: "143",
    tur: "Tek Kişilik",
    durum: "Available",
    fiyat: 100,
  },
  {
    id: 44,
    numara: "144",
    tur: "Çift Kişilik",
    durum: "Available",
    fiyat: 150,
  },
  {
    id: 45,
    numara: "145",
    tur: "Süit",
    durum: "Occupied",
    fiyat: 200,
    misafir: "Jane Doe",
  },
  {
    id: 46,
    numara: "146",
    tur: "Tek Kişilik",
    durum: "Occupied",
    fiyat: 100,
    misafir: "John Doe",
  },
  {
    id: 47,
    numara: "147",
    tur: "Çift Kişilik",
    durum: "Cleaning",
    fiyat: 150,
  },
  {
    id: 48,
    numara: "148",
    tur: "Süit",
    durum: "Cleaning",
    fiyat: 200,
  },
  {
    id: 49,
    numara: "149",
    tur: "Tek Kişilik",
    durum: "Available",
    fiyat: 100,
  },
  {
    id: 50,
    numara: "150",
    tur: "Çift Kişilik",
    durum: "Available",
    fiyat: 150,
  },
  {
    id: 51,
    numara: "151",
    tur: "Süit",
    durum: "Occupied",
    fiyat: 200,
    misafir: "Jane Doe",
  },
  {
    id: 52,
    numara: "152",
    tur: "Tek Kişilik",
    durum: "Occupied",
    fiyat: 100,
    misafir: "John Doe",
  },
  {
    id: 53,
    numara: "153",
    tur: "Çift Kişilik",
    durum: "Cleaning",
    fiyat: 150,
  },
  {
    id: 54,
    numara: "154",
    tur: "Süit",
    durum: "Cleaning",
    fiyat: 200,
  },
  {
    id: 55,
    numara: "155",
    tur: "Tek Kişilik",
    durum: "Available",
    fiyat: 100,
  },
  {
    id: 56,
    numara: "156",
    tur: "Çift Kişilik",
    durum: "Available",
    fiyat: 150,
  },
  {
    id: 57,
    numara: "157",
    tur: "Süit",
    durum: "Occupied",
    fiyat: 200,
    misafir: "Jane Doe",
  },
  {
    id: 58,
    numara: "158",
    tur: "Tek Kişilik",
    durum: "Occupied",
    fiyat: 100,
    misafir: "John Doe",
  },
  {
    id: 59,
    numara: "159",
    tur: "Çift Kişilik",
    durum: "Cleaning",
    fiyat: 150,
  },
  {
    id: 60,
    numara: "160",
    tur: "Süit",
    durum: "Cleaning",
    fiyat: 200,
  },
  {
    id: 61,
    numara: "161",
    tur: "Tek Kişilik",
    durum: "Available",
    fiyat: 100,
  },
  {
    id: 62,
    numara: "162",
    tur: "Çift Kişilik",
    durum: "Available",
    fiyat: 150,
  },
  {
    id: 63,
    numara: "163",
    tur: "Süit",
    durum: "Occupied",
    fiyat: 200,
    misafir: "Jane Doe",
  },
  {
    id: 64,
    numara: "164",
    tur: "Tek Kişilik",
    durum: "Occupied",
    fiyat: 100,
    misafir: "John Doe",
  },
  {
    id: 65,
    numara: "165",
    tur: "Çift Kişilik",
    durum: "Cleaning",
    fiyat: 150,
  },
  {
    id: 66,
    numara: "166",
    tur: "Süit",
    durum: "Cleaning",
    fiyat: 200,
  },
  {
    id: 67,
    numara: "167",
    tur: "Tek Kişilik",
    durum: "Available",
    fiyat: 100,
  },
  {
    id: 68,
    numara: "168",
    tur: "Çift Kişilik",
    durum: "Available",
    fiyat: 150,
  },
  {
    id: 69,
    numara: "169",
    tur: "Süit",
    durum: "Occupied",
    fiyat: 200,
    misafir: "Jane Doe",
  },
  {
    id: 70,
    numara: "170",
    tur: "Tek Kişilik",
    durum: "Occupied",
    fiyat: 100,
    misafir: "John Doe",
  },
  {
    id: 71,
    numara: "171",
    tur: "Çift Kişilik",
    durum: "Cleaning",
    fiyat: 150,
  },
  {
    id: 72,
    numara: "172",
    tur: "Süit",
    durum: "Cleaning",
    fiyat: 200,
  },
  {
    id: 73,
    numara: "173",
    tur: "Tek Kişilik",
    durum: "Available",
    fiyat: 100,
  },
  {
    id: 74,
    numara: "174",
    tur: "Çift Kişilik",
    durum: "Available",
    fiyat: 150,
  },
];
const sports = ["KONUM1", "KONUM2", "KONUM3", "KONUM4", "KONUM5", "KONUM6"];
