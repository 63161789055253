import React, { useEffect, useState } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import "./RezervasyonRaporu1.css";
import {
  FaSignInAlt,
  FaClock,
  FaSignOutAlt,
  FaShoppingCart,
} from "react-icons/fa";
import RmosGrid from "../../../../../../Components/DinamikRmosGrid/RmosGrid";
import { useDbId } from "../../../../../../Context/LocalStrogeContext/OnburoDbContext";

const RezervasyonRaporu1: React.FC = () => {
  const apiUrl = "https://frontapi.rmosweb.com/api/Procedure/StpReztara_Giris";
  const gridKey = "RezervasyonRaporu1";
  const { dbId } = useDbId();

  const initialRequestBody = {
    db_Id: dbId,
    xRez_Id: 0,
    xRez_Master_detay: "M",
    xRez_Sirket: 1,
    xtip: 2,
    xRez_rih: "T",
    xBas_Tar: "2023-07-10",
    xBit_Tar: "2023-07-10",
    xRez_Alis_tarihi: null,
    xRez_Blokeli: 0,
    xRez_Odano: null,
    tek_durum: 0,
    xArama: 1,
    xRez_C_W: "C",
    xIptal_10: 0,
    xFazla_Otel: null,
    xchkFis_Fazla_otel_10: 0,
    xGrup_10: 0,
    odeme_10: 0,
    hkdurum: null,
    online_10: 0,
    ozet_10: 0,
    package_10: 0,
    xAcentalar: null,
    rez_Rep_10: 0,
    rez_Devre_Gelir_10: 0,
  };
  const [requestBody, setRequestBody] = useState<any>(initialRequestBody);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  useEffect(() => {
    setRequestBody((prev: any) => ({ ...prev, db_Id: dbId }));
  }, [dbId]);

  useEffect(() => {
    setRequestBody((prev: any) => ({
      ...prev,
      xBas_Tar: startDate ? formatDate(startDate) : "",
      xBit_Tar: endDate ? formatDate(endDate) : "",
    }));
  }, [startDate, endDate]);

  const handleButtonClick = (xArama: number, xtip: number) => {
    setRequestBody((prev: any) => ({ ...prev, xArama, xtip }));
    // API call can be triggered here
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // handle the response data
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestBody((prev: any) => ({ ...prev, xRez_C_W: event.target.value }));
  };

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-md-12 bg-white shadow-lg rounded p-3 d-flex align-items-center justify-content-between">
            <div className="container d-flex align-items-center justify-content-between">
              <div className="date-picker-group">
                <div className="date-picker-item">
                  <label>Başlangıç Tarihi</label>
                  <DatePicker
                    defaultValue={new Date()}
                    format="dd.MM.yyyy"
                    value={startDate}
                    onChange={(e) => setStartDate(e.value)}

                  />
                </div>
                <div className="date-picker-item">
                  <label>Bitiş Tarihi</label>
                  <DatePicker
                    defaultValue={new Date()}
                    format="dd.MM.yyyy"
                    value={endDate}
                    onChange={(e) => setEndDate(e.value)}
                  />
                </div>
              </div>
              <div className="button-group">
                <Button className="btn" onClick={() => handleButtonClick(1, 2)}>
                  <FaSignInAlt className="icon" />
                  Giriş Tarihi
                </Button>
                <Button className="btn" onClick={() => handleButtonClick(5, 0)}>
                  <FaClock className="icon" />
                  Sistem Tarihi
                </Button>
                <Button className="btn" onClick={() => handleButtonClick(2, 0)}>
                  <FaSignOutAlt className="icon" />
                  Çıkış Tarihi
                </Button>
                <Button className="btn" onClick={() => handleButtonClick(3, 0)}>
                  <FaShoppingCart className="icon" />
                  Alış Tarihi
                </Button>
                <div className="radio-group">
                  <label>
                    <input
                      type="radio"
                      name="rezType"
                      value="C"
                      onChange={handleRadioChange}
                      defaultChecked
                    />
                    Kesin
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="rezType"
                      value="T"
                      onChange={handleRadioChange}
                    />
                    Geçici (Tentative)
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 bg-white shadow-lg rounded p-3 mt-4">
            <RmosGrid apiUrl={apiUrl} requestBody={requestBody} gridKey={gridKey} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RezervasyonRaporu1;
