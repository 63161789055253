import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column, GridColumnProps } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Checkbox, Input, RadioButton } from "@progress/kendo-react-inputs";
import { Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import UstMenuBar from "../../../../../Components/Basliklar/UstMenuBar";
import RmosGrid from "../../../../../Components/DinamikRmosGrid/RmosGrid";
import { ColumnMenu } from "../../../../../Kendo/ColumnMenu";
import { useDbId } from "../../../../../Context/LocalStrogeContext/OnburoDbContext";

const initialDataState = {
  take: 10,
  skip: 0,
  group: [],
  filter: null,
};

const MisafirListesi: React.FC = () => {
  const apiUrl = 'https://frontapi.rmosweb.com/api/Procedure/StpRez_Bul';
  const { dbId } = useDbId();

  const initialRequestBody = {
    db_Id: dbId,
    xRez_Id: 0,
    xRez_Master_detay: "D",
    xRez_Sirket: 0,
    xtip: 2,
    xMacente_Kodu: "T",
    xRez_rih: "I",
    xRez_Giris_tarihi: "2023-08-01",
    xRez_Cikis_tarihi: "2023-08-01",
    xRez_Alis_tarihi: "2023-08-01",
    xRez_Sistem_tarihi: "2024-08-01",
    xRez_Blokeli: 9,
    xRez_Odano: null,
    tek_durum: 0,
    xchkKonum_10: 0,
    xKonumlar: null,
    xchkAcenta_10: 0,
    xAcentalar: null,
    xchkKonaklama_10: 0,
    xKonaklama: null,
    xchkGiris_tarihi_10: 0,
    xGiris_Tarih_1: null,
    xGiris_Tarih_2: null,
    xchkCikis_tarihi_10: 0,
    xCikis_Tarih_1: null,
    xCikis_Tarih_2: null,
    xchkAlis_tarihi_10: 0,
    xAlis_Tarih_1: null,
    xAlis_Tarih_: null,
    xchkKonaklama_tar_10: 0,
    xKonaklama_Tar1: null,
    xKonaklama_Tar2: null,
    xchkOdeme_10: 0,
    xOdeme: null,
    xchkMusteritipi_10: 0,
    xMusteritipi: null,
    xchkUlke_10: 0,
    xUlke: null,
    xchkSource_10: 0,
    xSource: null,
    xchkChanel_10: 0,
    xChanel: null,
    xchkFis_Fazla_otel_10: 0,
    xFazla_Otel: null,
    xchkVoucher_10: 0,
    xVoucher: "0",
    xchkOda_10: 0,
    xOda: "0",
    gecerli_Yil: 2023,
    xchkAdi_1: 0,
    xAdi_1: null,
    xchkSoy_1: 0,
    xSoy_1: null,
    beraber_10: 0,
    yerliyabanci: 0,
    devam_10: 0,
    xRez_C_W: "C",
    gir_cik: 0,
    bakiye_10: 0,
    eski_Kontrol_10: 0,
    extra_10: 0,
    olustur: null,
    olustur_Kumhrk: null,
    xchkRih_10: 0,
    xRih: "T",
    otel_Kodu: "001",
    hizli_Normal: 0
  };

  const gridKey = 'MisafirListesi';
  const [requestBody, setRequestBody] = useState(initialRequestBody);
  const [xRezRih, setXRezRih] = useState("I");

  useEffect(() => {
    setRequestBody((prev) => ({ ...prev, db_Id: dbId }));
  }, [dbId]);

  useEffect(() => {
    setRequestBody((prevData) => ({
      ...prevData,
      xRez_rih: xRezRih,
    }));
  }, [xRezRih]);

  const handleRadioChange = (value: string) => {
    setXRezRih(value);
  };

  useEffect(() => {
    console.log("requestBody", requestBody);
  }, []);
  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row">
          <UstMenuBar>
            <div>
              <RadioButton
                name="rihGroup"
                value="I"
                label="InHouse"
                checked={xRezRih === "I"}
                onChange={() => handleRadioChange("I")}
              />
            </div>
            <div>
              <RadioButton
                name="rihGroup"
                value="H"
                label="History"
                checked={xRezRih === "H"}
                onChange={() => handleRadioChange("H")}
              />
            </div>
            <div>
              <RadioButton
                name="rihGroup"
                value="T"
                label="All"
                checked={xRezRih === "T"}
                onChange={() => handleRadioChange("T")}
              />
            </div>
          </UstMenuBar>
          <div className="col-md-12 bg-white shadow-lg rounded p-3 mt-1">
            <Tabs defaultActiveKey="MusteriDetay" id="uncontrolled-tab-example">
              <Tab eventKey="MusteriDetay" title="Müşteri Detay">
                <RmosGrid
                  apiUrl={apiUrl}
                  requestBody={requestBody}
                  gridKey={gridKey}
                   initialColumns={initialColumns}
                />
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex justify-content-between gap-2">
                        <div className="w-100 d-flex justify-content-between gap-2">
                          <div className="text-start">
                            <Checkbox label={"Tutar Çıkmasın"} color="red" />
                          </div>
                          <div className="text-start">
                            <Checkbox
                              defaultChecked={true}
                              label={"Beraberindekiler Çıksın"}
                            />
                          </div>
                         
                          <div className="text-start">
                            <Checkbox
                              defaultChecked={true}
                              label={"Extra Foliolar Çıksın"}
                            />
                          </div>
                          <div className="text-start">
                            <Checkbox
                              defaultChecked={true}
                              label={"İsimler Maskeli Çıksın"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="ResmiMusteriListesi" title="Resmi Müşteri Listesi">
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-content-between gap-2">
                        <div className="d-flex justify-content-between align-items-center">
                          Liste Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Şekil (1)"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Şekil (2)"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Şekil (3) İkili"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Tarihler Arası"
                          />
                        </div>
                        <div>
                          <Checkbox
                            label={"Eski Inhouse Kontrol"}
                            color="red"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Inhouse (AKtif)"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Eski Inhouse"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Eski Inhouse (Basılan)"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="T.Arası (Eski Inhouse)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Grid
                  data={process(AktivitelerData1, dataState)}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid> */}
              </Tab>
              <Tab eventKey="PolisDefteri" title="Polis Defteri">
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-contet-start gap-2">
                        <div className="d-flex justify-content-center align-items-center">
                          Liste Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          Jan.Akbs Kodu
                        </div>
                        <div>
                          <Input type="text" placeholder=""></Input>
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="TC Vatandaşı"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Yabancı Misafir"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Tüm"
                          />
                        </div>
                        <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                          <FontAwesomeIcon icon={faRefresh} /> EGM'den Doldur
                        </div>
                        <div>
                          <Checkbox
                            label={"Beraberindekiler Çıksın"}
                            color="red"
                          />
                        </div>
                        <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                          <FontAwesomeIcon icon={faRefresh} /> Tabloyu Düş
                        </div>
                        <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                          <FontAwesomeIcon icon={faRefresh} /> Boş Kayıtları
                          Temizle
                        </div>
                        <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                          <FontAwesomeIcon icon={faRefresh} /> Hatalı Kayıtlar
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Grid
                  data={processedData}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid> */}

                <div className="col-md-10 w-100 d-flex align-items-start  justify-contet-start gap-2 mt-3">
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> Değişkenleri Kaydet
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> XML Oluştur
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> CRM Kontrol
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> AKBS Robot
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> Excel (EGM)
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> AKBS Web
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> EGM Gönder
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> EGm (Parçalı)
                  </div>
                  <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">
                    <FontAwesomeIcon icon={faRefresh} /> EGM Otomatik
                  </div>
                </div>
              </Tab>
              <Tab eventKey="OdaDegisimi" title="Oda Değişimi">
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-content-start gap-2">
                        <div className="d-flex justify-content-between align-items-center">
                          Başlangıç Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          Bitiş Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Grid
                  data={processedData}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid> */}
              </Tab>
              <Tab eventKey="UzatmaAlanlar" title="Uzatma Alanlar">
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-content-start gap-2">
                        <div className="d-flex justify-content-between align-items-center">
                          Başlangıç Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          Bitiş Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                        <div>
                          <RadioButton
                            className="ms-3"
                            name="group1"
                            value="Firma"
                            label="Erken Çıkanlar"
                            checked={true}
                          />
                        </div>
                        <div>
                          <RadioButton
                            className="ms-3"
                            name="group1"
                            value="Firma"
                            label="Uzatma Yapanlar"
                          />
                        </div>
                        <div>
                          <RadioButton
                            className="ms-3"
                            name="group1"
                            value="Firma"
                            label="Hepsi"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Grid
                  data={processedData}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid> */}
              </Tab>
              <Tab eventKey="Ozet" title="Özet">
                {/* <Grid
                  data={processedData}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid> */}
              </Tab>
              <Tab eventKey="InhouseSpecialDate" title="Inhouse Special Date">
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-content-start gap-2">
                        <div>
                          <RadioButton
                            className="ms-3"
                            name="group1"
                            value="Firma"
                            label="Doğum Günü"
                            checked={true}
                          />
                        </div>
                        <div>
                          <RadioButton
                            className="ms-3"
                            name="group1"
                            value="Firma"
                            label="Evlilik Yıldönümü"
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          Rapor Tarihi
                        </div>
                        <div>
                          <DatePicker defaultValue={new Date()} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Grid
                  data={processedData}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid> */}
              </Tab>
              <Tab
                eventKey="InhouseRepeatSpecial"
                title="Inhouse Repeat Special"
              >
                <div className="col-md-12 bg-white shadow-lg rounded p-3 mb-3 mt-2">
                  <div className="">
                    <div>
                      <div className="w-100 d-flex align-items-center  justify-content-between gap-2">
                        {/* birinci */}
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Repeat Guest List"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Posting Yapmayacak Odalar"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Inhouse Connection"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="NoShow"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Pax Cout"
                          />
                        </div>
                      </div>
                      <div className="w-100 d-flex align-items-center  justify-content-between gap-2">
                        {/* ikinci */}
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Konum Uyuşmazlık"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Beraber Odalar (Birleşen)"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Dışarda Geceleyenler"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Yöndirilmiş Odalar(Routing)"
                          />
                        </div>
                        <div>
                          <RadioButton
                            name="group1"
                            value="Firma"
                            label="Hayali (PM) Odalar"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Grid
                  data={processedData}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={true}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  style={{ height: "500px" }}
                >
                  <Column field="AktiviteAdi" title="Aktivite Adı" />
                  <Column field="FirmaAdi" title="Firma Adı" />
                  <Column field="Kontak" title="Kontak" />
                  <Column field="Iletişim" title="İletişim" />
                  <Column field="BaslangicTarihi" title="Başlangıç Tarihi" />
                  <Column field="BİtisTarihi" title="Bitiş Tarihi" />
                  <Column field="PersonelAdsoyad" title="Personel Ad Soyad" />
                </Grid> */}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};
export default MisafirListesi;
const columns={
  "OdaNo": "1201",
  "RezId": 104351,
  "Adi": "CORNELIA GERTRUDE",
  "Soyadı": "NUB GEB. BOHNEN",
  "Oda Sayısı": 1,
  "Yetişkin": 2,
  "Çocuk": 0,
  "Free": 0,
  "Konaklama": "AL",
  "Ödeme Adı": "AL",
  "Acenta Adı": "BIGXTRA TRAVEL 2023 YAZ",
  "Giriş Tarihi": "05/23/2023 00:00:00",
  "Geceleme": 10,
  "Çıkış Tarihi": "06/02/2023 00:00:00",
  "İstenen": "ECO",
  "Verilen": "STD",
  "Kur Adı": "EURO",
  "Toplam Tutar": 123.87,
  "Uygulanan Kur": 21.4186,
  "Toplam TL Toplam": 2653.12,
  "Müşteri Tipi Tanımı": "AVRUPA PAZARI",
  "Ülke Adı": "ALMANYA",
  "Source Tanımı": null,
  "Chanel Tanımı": null,
  "Doğum Tarihi": "01/10/1963 00:00:00",
  "Giriş Saati": "08:55",
  "Çıkış Saati": null,
  "Pazar Tanımı": "AVRUPA PAZARI",
  "Evlilik Tarihi": null,
  "Voucher": "7566892",
  "Vip Kodu": null,
  "Rehber": "",
  "Plaka": "",
  "Notlar": null,
  "Notlar Onburo": null,
  "MD": "M",
  "Müşteri Tipi Kodu": "001",
  "Ulke Kodu": "002",
  "MasterId": 104351,
  "Kimlik No": "L7XV3ZY66",
  "Rengi": "001",
  "Grup Id": 0,
  "Grup Kodu": null,
  "Bulunduğu Yerler": null,
  "Kronik Rahatsızlık": null,
  "Covid": "0",
  "Covid Açıklama": null,
  "Hes Kodu": "",
  "Notlar Web": null,
  "Pansiyon Ozel Kod": "DEF",
  "Kimlik Etk": "Hayir",
  "Early Cout": "H",
  "Gelmedi(1/0)": 0,
  "Gelmedi": "Geldi",
  "Acenta Kodu": "BIGXTRA23",
  "Grup Adı": null,
  "Ind Tutar": 508.75,
  "Toplam_tutar": 1310,
  "Net tutar": 801.25,
  "Ind%": "38.8358778625954198",
  "Toplam Kişi": "2",
  "Verilen Kodu": "001",
  "Reztr_Saat_gel": null,
  "Reztr_Saat_don": null,
  "Reztr_Tarihi_gel": null,
  "Reztr_Tarihi_don": null,
  "Reztr_Ucak_kodu_gel": null,
  "Reztr_Ucak_kodu_don": null,
  "Alerjenn Kodu": null,
  "Engelli Kodu": null,
  "Verilen Room Adı": "STD",
  "Verilen_Hata": "OK",
  "Rez_Kimlik_gor_10_adi": "Kullanılsın",
  "Kimlik_Uyruk": "002",
  "Kimlik_Uyruk_Adi": "ALMANYA",
  "Alis_tarihi": "2023-01-24T00:00:00",
  "Sistem_giris_saati": "13:41:00",
  "Sistem_cikis_saati": "10:24:00",
  "Inh_oda": 123.87,
  "Inh_kahvalti": 0,
  "Inh_oyle": 0,
  "Inh_aksam": 0,
  "Inh_yiyecek": 0,
  "Inh_icecek": 0,
  "Inh_extra": 0,
  "Inh_tloda": 2653.12,
  "Inh_tlkahval": 0,
  "Inh_tloyle": 0,
  "Inh_tlaksam": 0,
  "Inh_tlyiyece": 0,
  "Inh_tlicecek": 0,
  "Inh_tlextra": 0,
  "Rez_Yas_tanimi": "60",
  "Rez_Fiat_om": "O",
  "Rez_Room_chg_eh": "H",
  "Rez_Kayitno": null,
  "Uzatma_eh": "H",
  "Grup_nor": "N",
  "Co_city_eh": "E",
  "Uye_depind_": null,
  "Notlar(Ozel)": null,
  "Birlesme": "0",
  "Adres1": "",
  "Adres2": "",
  "Adres3": "",
  "Adresilc": "",
  "Adresil": "",
  "Posting_oda": null,
  "Posting_fol": null,
  "Pmmaster_no": null,
  "Pm_Oda": null,
  "Pm_Rez_Adi_1": null,
  "Pm_Rez_Adi_2": null,
  "Metrekare": "0",
  "Dairetip": "",
  "Tbl_kodu": "BIGXTRA23AKS0001",
  "Tbl_kodu3": "",
  "Ekdosya_10": null,
  "R_I_H": "H",
  "Previl_id": "91454",
  "Mail Genel": "",
  "Notlar_R_id": null,
  "Notlar_O_id": null,
  "Kimlik_Tc": "",
  "Cin_Yapan_adi": null,
  "_Muslst_eh": null,
  "_Kartno": null,
  "Kimlik_Gelis": "1",
  "Notlar_Hk": null,
  "Bebek_yatak": "0",
  "incognito_10": "0",
  "Polis_eh": "E",
  "Posting_eh": "H",
  "Tbl_Kodu2": "",
  "Kapat_eh": null,
  "Aracno": null,
  "Aracno2": null,
  "Blokaj": "1201",
  "Manzara_Adi": null,
  "Katlar_Adi": "A-BLOK 2.KAT",
  "Yatak_Adi": "FRN+1SNG",
  "Rez_Havlu_bebek_": null,
  "Kimlik_Dogumyeri": "",
  "Kimlik_Ana": "",
  "Kimlik_Baba": "",
  "Donem_Adi": null,
  "Uye_Adi": null,
  "Uye_Kodu": null,
  "Kimlik_Tel": "",
  "Kimlik_Ev_tel": null,
  "Kimlik_Gsm": null,
  "Kimlik_Gsm2": null,
  "Rez_Yapan": "REZ",
  "Kulanici_Adi": null,
  "Rci_Adi": null,
  "Rci_Soyadi": null,
  "Konaklama_Gun": "70",
  "Room_Bak": "0.00",
  "Ext_Bak": "0.00",
  "Toplam_Bak": "0.00",
  "Sanal_10": "0",
  "Bolge_adi": null,
  "Apart_Adi": "A-BLOK 2.KAT",
  "Katno": "0",
  "Gorev_Yenioda": null,
  "Gorev_Id": "0",
  "Gorev_Giren_aciklama": null,
  "Gorev_Uygulanacak_tarih": null,
  "Kimlik_Kart": null,
  "Kart_Turu": null,
  "Uyeliktipi": null,
  "Rez_Net_tutar_tl": "17161.6500000",
  "Gorev_Hk_stadi": null,
  "Uyusmazlik_10": "1",
  "Notlar_Uyusmaz": null,
  "Kimlik_Cinsiyet": "K",
  "Tel": null,
  "Mail": ""
}

const initialColumns: GridColumnProps[] = [
  { id: 'OdaNo', field: 'OdaNo', title: 'Oda No', columnMenu: ColumnMenu },
  { id: 'RezId', field: 'RezId', title: 'Rez Id', columnMenu: ColumnMenu },
  { id: 'Adi', field: 'Adi', title: 'Adı', filter: 'numeric', columnMenu: ColumnMenu },
  { id: 'Soyadı', field: 'Soyadı', title: 'Soyadı', columnMenu: ColumnMenu },
  { id: 'Oda Sayısı', field: 'Oda Sayısı', title: 'Oda Sayısı', columnMenu: ColumnMenu },
  { id: 'Yetişkin', field: 'Yetişkin', title: 'Yetişkin', columnMenu: ColumnMenu },
  { id: 'Çocuk', field: 'Çocuk', title: 'Çocuk', columnMenu: ColumnMenu },
  { id: 'Free', field: 'Free', title: 'Free', columnMenu: ColumnMenu },
  { id: 'Konaklama', field: 'Konaklama', title: 'Konaklama', columnMenu: ColumnMenu },
  { id: 'Ödeme Adı', field: 'Ödeme Adı', title: 'Ödeme Adı', columnMenu: ColumnMenu },
  { id: 'Acenta Adı', field: 'Acenta Adı', title: 'Acenta Adı', columnMenu: ColumnMenu },
  { id: 'Giriş Tarihi', field: 'Giriş Tarihi', title: 'Giriş Tarihi', columnMenu: ColumnMenu },
  { id: 'Geceleme', field: 'Geceleme', title: 'Geceleme', columnMenu: ColumnMenu },
  { id: 'Çıkış Tarihi', field: 'Çıkış Tarihi', title: 'Çıkış Tarihi', columnMenu: ColumnMenu },
  { id: 'İstenen', field: 'İstenen', title: 'İstenen', columnMenu: ColumnMenu },
  { id: 'Verilen', field: 'Verilen', title: 'Verilen', columnMenu: ColumnMenu },
  { id: 'Kur Adı', field: 'Kur Adı', title: 'Kur Adı', columnMenu: ColumnMenu },
  { id: 'Toplam Tutar', field: 'Toplam Tutar', title: 'Toplam Tutar', columnMenu: ColumnMenu },
  { id: 'Uygulanan Kur', field: 'Uygulanan Kur', title: 'Uygulanan Kur', columnMenu: ColumnMenu },
  { id: 'Toplam TL Toplam', field: 'Toplam TL Toplam', title: 'Toplam TL Toplam', columnMenu: ColumnMenu },
  { id: 'Müşteri Tipi Tanımı', field: 'Müşteri Tipi Tanımı', title: 'Müşteri Tipi Tanımı', columnMenu: ColumnMenu },
  { id: 'Ülke Adı', field: 'Ülke Adı', title: 'Ülke Adı', columnMenu: ColumnMenu },
  { id: 'Source Tanımı', field: 'Source Tanımı', title: 'Source Tanımı', columnMenu: ColumnMenu },
  { id: 'Chanel Tanımı', field: 'Chanel Tanımı', title: 'Chanel Tanımı', columnMenu: ColumnMenu },
  { id: 'Doğum Tarihi', field: 'Doğum Tarihi', title: 'Doğum Tarihi', columnMenu: ColumnMenu },
  { id: 'Giriş Saati', field: 'Giriş Saati', title: 'Giriş Saati', columnMenu: ColumnMenu },
  { id: 'Çıkış Saati', field: 'Çıkış Saati', title: 'Çıkış Saati', columnMenu: ColumnMenu },
  { id: 'Pazar Tanımı', field: 'Pazar Tanımı', title: 'Pazar Tanımı', columnMenu: ColumnMenu },
  { id: 'Evlilik Tarihi', field: 'Evlilik Tarihi', title: 'Evlilik Tarihi', columnMenu: ColumnMenu },
  { id: 'Voucher', field: 'Voucher', title: 'Voucher', columnMenu: ColumnMenu },
  { id: 'Vip Kodu', field: 'Vip Kodu', title: 'Vip Kodu', columnMenu: ColumnMenu },
  { id: 'Rehber', field: 'Rehber', title: 'Rehber', columnMenu: ColumnMenu },
  { id: 'Plaka', field: 'Plaka', title: 'Plaka', columnMenu: ColumnMenu },
  { id: 'Notlar', field: 'Notlar', title: 'Notlar', columnMenu: ColumnMenu },
  { id: 'Notlar Onburo', field: 'Notlar Onburo', title: 'Notlar Onburo', columnMenu: ColumnMenu },
  { id: 'MD', field: 'MD', title: 'MD', columnMenu: ColumnMenu },
  { id: 'Müşteri Tipi Kodu', field: 'Müşteri Tipi Kodu', title: 'Müşteri Tipi Kodu', columnMenu: ColumnMenu },
  { id: 'Ulke Kodu', field: 'Ulke Kodu', title: 'Ulke Kodu', columnMenu: ColumnMenu },
  { id: 'MasterId', field: 'MasterId', title: 'MasterId', columnMenu: ColumnMenu },
  { id: 'Kimlik No', field: 'Kimlik No', title: 'Kimlik No', columnMenu: ColumnMenu },
  { id: 'Rengi', field: 'Rengi', title: 'Rengi', columnMenu: ColumnMenu },
  { id: 'Grup Id', field: 'Grup Id', title: 'Grup Id', columnMenu: ColumnMenu },
  { id: 'Grup Kodu', field: 'Grup Kodu', title: 'Grup Kodu', columnMenu: ColumnMenu },
  { id: 'Bulunduğu Yerler', field: 'Bulunduğu Yerler', title: 'Bulunduğu Yerler', columnMenu: ColumnMenu },
  { id: 'Kronik Rahatsızlık', field: 'Kronik Rahatsızlık', title: 'Kronik Rahatsızlık', columnMenu: ColumnMenu },
  { id: 'Covid', field: 'Covid', title: 'Covid', columnMenu: ColumnMenu },
  { id: 'Covid Açıklama', field: 'Covid Açıklama', title: 'Covid Açıklama', columnMenu: ColumnMenu },
  { id: 'Hes Kodu', field: 'Hes Kodu', title: 'Hes Kodu', columnMenu: ColumnMenu },
  { id: 'Notlar Web', field: 'Notlar Web', title: 'Notlar Web', columnMenu: ColumnMenu },
  { id: 'Pansiyon Ozel Kod', field: 'Pansiyon Ozel Kod', title: 'Pansiyon Ozel Kod', columnMenu: ColumnMenu },
  { id: 'Kimlik Etk', field: 'Kimlik Etk', title: 'Kimlik Etk', columnMenu: ColumnMenu },
  { id: 'Early Cout', field: 'Early Cout', title: 'Early Cout', columnMenu: ColumnMenu },
  { id: 'Gelmedi(1/0)', field: 'Gelmedi(1/0)', title: 'Gelmedi(1/0)', columnMenu: ColumnMenu },
  { id: 'Gelmedi', field: 'Gelmedi', title: 'Gelmedi', columnMenu: ColumnMenu },
  { id: 'Acenta Kodu', field: 'Acenta Kodu', title: 'Acenta Kodu', columnMenu: ColumnMenu },
  { id: 'Grup Adı', field: 'Grup Adı', title: 'Grup Adı', columnMenu: ColumnMenu },
  { id: 'Ind Tutar', field: 'Ind Tutar', title: 'Ind Tutar', columnMenu: ColumnMenu },
  { id: 'Toplam_tutar', field: 'Toplam_tutar', title: 'Toplam_tutar', columnMenu: ColumnMenu },
  { id: 'Net tutar', field: 'Net tutar', title: 'Net tutar', columnMenu: ColumnMenu },
  { id: 'Ind%', field: 'Ind%', title: 'Ind%', columnMenu: ColumnMenu },
  { id: 'Toplam Kişi', field: 'Toplam Kişi', title: 'Toplam Kişi', columnMenu: ColumnMenu },
  { id: 'Verilen Kodu', field: 'Verilen Kodu', title: 'Verilen Kodu', columnMenu: ColumnMenu },
  { id: 'Reztr_Saat_gel', field: 'Reztr_Saat_gel', title: 'Reztr_Saat_gel', columnMenu: ColumnMenu },
  { id: 'Reztr_Saat_don', field: 'Reztr_Saat_don', title: 'Reztr_Saat_don', columnMenu: ColumnMenu },
  { id: 'Reztr_Tarihi_gel', field: 'Reztr_Tarihi_gel', title: 'Reztr_Tarihi_gel', columnMenu: ColumnMenu },
  { id: 'Reztr_Tarihi_don', field: 'Reztr_Tarihi_don', title: 'Reztr_Tarihi_don', columnMenu: ColumnMenu },
  { id: 'Reztr_Ucak_kodu_gel', field: 'Reztr_Ucak_kodu_gel', title: 'Reztr_Ucak_kodu_gel', columnMenu: ColumnMenu },
  { id: 'Reztr_Ucak_kodu_don', field: 'Reztr_Ucak_kodu_don', title: 'Reztr_Ucak_kodu_don', columnMenu: ColumnMenu },
  { id: 'Alerjenn Kodu', field: 'Alerjenn Kodu', title: 'Alerjenn Kodu', columnMenu: ColumnMenu },
  { id: 'Engelli Kodu', field: 'Engelli Kodu', title: 'Engelli Kodu', columnMenu: ColumnMenu },
  { id: 'Verilen Room Adı', field: 'Verilen Room Adı', title: 'Verilen Room Adı', columnMenu: ColumnMenu },
  { id: 'Verilen_Hata', field: 'Verilen_Hata', title: 'Verilen_Hata', columnMenu: ColumnMenu },
  { id: 'Rez_Kimlik_gor_10_adi', field: 'Rez_Kimlik_gor_10_adi', title: 'Rez_Kimlik_gor_10_adi', columnMenu: ColumnMenu },
  { id: 'Kimlik_Uyruk', field: 'Kimlik_Uyruk', title: 'Kimlik_Uyruk', columnMenu: ColumnMenu },
  { id: 'Kimlik_Uyruk_Adi', field: 'Kimlik_Uyruk_Adi', title: 'Kimlik_Uyruk_Adi', columnMenu: ColumnMenu },
  { id: 'Alis_tarihi', field: 'Alis_tarihi', title: 'Alis_tarihi', columnMenu: ColumnMenu },
  { id: 'Sistem_giris_saati', field: 'Sistem_giris_saati', title: 'Sistem_giris_saati', columnMenu: ColumnMenu },
  { id: 'Sistem_cikis_saati', field: 'Sistem_cikis_saati', title: 'Sistem_cikis_saati', columnMenu: ColumnMenu },
  { id: 'Inh_oda', field: 'Inh_oda', title: 'Inh_oda', columnMenu: ColumnMenu },
  { id: 'Inh_kahvalti', field: 'Inh_kahvalti', title: 'Inh_kahvalti', columnMenu: ColumnMenu },
  { id: 'Inh_oyle', field: 'Inh_oyle', title: 'Inh_oyle', columnMenu: ColumnMenu },
  { id: 'Inh_aksam', field: 'Inh_aksam', title: 'Inh_aksam', columnMenu: ColumnMenu },
  { id: 'Inh_yiyecek', field: 'Inh_yiyecek', title: 'Inh_yiyecek', columnMenu: ColumnMenu },
  { id: 'Inh_icecek', field: 'Inh_icecek', title: 'Inh_icecek', columnMenu: ColumnMenu },
  { id: 'Inh_extra', field: 'Inh_extra', title: 'Inh_extra', columnMenu: ColumnMenu },
  { id: 'Inh_tloda', field: 'Inh_tloda', title: 'Inh_tloda', columnMenu: ColumnMenu },
  { id: 'Inh_tlkahval', field: 'Inh_tlkahval', title: 'Inh_tlkahval', columnMenu: ColumnMenu },
  { id: 'Inh_tloyle', field: 'Inh_tloyle', title: 'Inh_tloyle', columnMenu: ColumnMenu },
  { id: 'Inh_tlaksam', field: 'Inh_tlaksam', title: 'Inh_tlaksam', columnMenu: ColumnMenu },
  { id: 'Inh_tlyiyece', field: 'Inh_tlyiyece', title: 'Inh_tlyiyece', columnMenu: ColumnMenu },
  { id: 'Inh_tlicecek', field: 'Inh_tlicecek', title: 'Inh_tlicecek', columnMenu: ColumnMenu },
  { id: 'Inh_tlextra', field: 'Inh_tlextra', title: 'Inh_tlextra', columnMenu: ColumnMenu },
  { id: 'Rez_Yas_tanimi', field: 'Rez_Yas_tanimi', title: 'Rez_Yas_tanimi', columnMenu: ColumnMenu },
  { id: 'Rez_Fiat_om', field: 'Rez_Fiat_om', title: 'Rez_Fiat_om', columnMenu: ColumnMenu },
  { id: 'Rez_Room_chg_eh', field: 'Rez_Room_chg_eh', title: 'Rez_Room_chg_eh', columnMenu: ColumnMenu },
  { id: 'Rez_Kayitno', field: 'Rez_Kayitno', title: 'Rez_Kayitno', columnMenu: ColumnMenu },
  { id: 'Uzatma_eh', field: 'Uzatma_eh', title: 'Uzatma_eh', columnMenu: ColumnMenu },
  { id: 'Grup_nor', field: 'Grup_nor', title: 'Grup_nor', columnMenu: ColumnMenu },
  { id: 'Co_city_eh', field: 'Co_city_eh', title: 'Co_city_eh', columnMenu: ColumnMenu },
  { id: 'Uye_depind_', field: 'Uye_depind_', title: 'Uye_depind_', columnMenu: ColumnMenu },
  { id: 'Notlar(Ozel)', field: 'Notlar(Ozel)', title: 'Notlar(Ozel)', columnMenu: ColumnMenu },
  { id: 'Birlesme', field: 'Birlesme', title: 'Birlesme', columnMenu: ColumnMenu },
  { id: 'Adres1', field: 'Adres1', title: 'Adres1', columnMenu: ColumnMenu },
  { id: 'Adres2', field: 'Adres2', title: 'Adres2', columnMenu: ColumnMenu },
  { id: 'Adres3', field: 'Adres3', title: 'Adres3', columnMenu: ColumnMenu },
  { id: 'Adresilc', field: 'Adresilc', title: 'Adresilc', columnMenu: ColumnMenu },
  { id: 'Adresil', field: 'Adresil', title: 'Adresil', columnMenu: ColumnMenu },
  { id: 'Posting_oda', field: 'Posting_oda', title: 'Posting_oda', columnMenu: ColumnMenu },
  { id: 'Posting_fol', field: 'Posting_fol', title: 'Posting_fol', columnMenu: ColumnMenu },
  { id: 'Pmmaster_no', field: 'Pmmaster_no', title: 'Pmmaster_no', columnMenu: ColumnMenu },
  { id: 'Pm_Oda', field: 'Pm_Oda', title: 'Pm_Oda', columnMenu: ColumnMenu },
  { id: 'Pm_Rez_Adi_1', field: 'Pm_Rez_Adi_1', title: 'Pm_Rez_Adi_1', columnMenu: ColumnMenu },
  { id: 'Pm_Rez_Adi_2', field: 'Pm_Rez_Adi_2', title: 'Pm_Rez_Adi_2', columnMenu: ColumnMenu },
  { id: 'Metrekare', field: 'Metrekare', title: 'Metrekare', columnMenu: ColumnMenu },
  { id: 'Dairetip', field: 'Dairetip', title: 'Dairetip', columnMenu: ColumnMenu },
  { id: 'Tbl_kodu', field: 'Tbl_kodu', title: 'Tbl_kodu', columnMenu: ColumnMenu },
  { id: 'Tbl_kodu3', field: 'Tbl_kodu3', title: 'Tbl_kodu3', columnMenu: ColumnMenu },
  { id: 'Ekdosya_10', field: 'Ekdosya_10', title: 'Ekdosya_10', columnMenu: ColumnMenu },
  { id: 'R_I_H', field: 'R_I_H', title: 'R_I_H', columnMenu: ColumnMenu },
  { id: 'Previl_id', field: 'Previl_id', title: 'Previl_id', columnMenu: ColumnMenu },
  { id: 'Mail Genel', field: 'Mail Genel', title: 'Mail Genel', columnMenu: ColumnMenu },
  { id: 'Notlar_R_id', field: 'Notlar_R_id', title: 'Notlar_R_id', columnMenu: ColumnMenu },
  { id: 'Notlar_O_id', field: 'Notlar_O_id', title: 'Notlar_O_id', columnMenu: ColumnMenu },
  { id: 'Kimlik_Tc', field: 'Kimlik_Tc', title: 'Kimlik_Tc', columnMenu: ColumnMenu },
  { id: 'Cin_Yapan_adi', field: 'Cin_Yapan_adi', title: 'Cin_Yapan_adi', columnMenu: ColumnMenu },
  { id: '_Muslst_eh', field: '_Muslst_eh', title: '_Muslst_eh', columnMenu: ColumnMenu },
  { id: '_Kartno', field: '_Kartno', title: '_Kartno', columnMenu: ColumnMenu },
  { id: 'Kimlik_Gelis', field: 'Kimlik_Gelis', title: 'Kimlik_Gelis', columnMenu: ColumnMenu },
  { id: 'Notlar_Hk', field: 'Notlar_Hk', title: 'Notlar_Hk', columnMenu: ColumnMenu },
  { id: 'Bebek_yatak', field: 'Bebek_yatak', title: 'Bebek_yatak', columnMenu: ColumnMenu },
  { id: 'incognito_10', field: 'incognito_10', title: 'incognito_10', columnMenu: ColumnMenu },
  { id: 'Polis_eh', field: 'Polis_eh', title: 'Polis_eh', columnMenu: ColumnMenu },
  { id: 'Posting_eh', field: 'Posting_eh', title: 'Posting_eh', columnMenu: ColumnMenu },
  { id: 'Tbl_Kodu2', field: 'Tbl_Kodu2', title: 'Tbl_Kodu2', columnMenu: ColumnMenu },
  { id: 'Kapat_eh', field: 'Kapat_eh', title: 'Kapat_eh', columnMenu: ColumnMenu },
  { id: 'Aracno', field: 'Aracno', title: 'Aracno', columnMenu: ColumnMenu },
  { id: 'Aracno2', field: 'Aracno2', title: 'Aracno2', columnMenu: ColumnMenu },
  { id: 'Blokaj', field: 'Blokaj', title: 'Blokaj', columnMenu: ColumnMenu },
  { id: 'Manzara_Adi', field: 'Manzara_Adi', title: 'Manzara_Adi', columnMenu: ColumnMenu },
  { id: 'Katlar_Adi', field: 'Katlar_Adi', title: 'Katlar_Adi', columnMenu: ColumnMenu },
  { id: 'Yatak_Adi', field: 'Yatak_Adi', title: 'Yatak_Adi', columnMenu: ColumnMenu },
  { id: 'Rez_Havlu_bebek_', field: 'Rez_Havlu_bebek_', title: 'Rez_Havlu_bebek_', columnMenu: ColumnMenu },
  { id: 'Kimlik_Dogumyeri', field: 'Kimlik_Dogumyeri', title: 'Kimlik_Dogumyeri', columnMenu: ColumnMenu },
  { id: 'Kimlik_Ana', field: 'Kimlik_Ana', title: 'Kimlik_Ana', columnMenu: ColumnMenu },
  { id: 'Kimlik_Baba', field: 'Kimlik_Baba', title: 'Kimlik_Baba', columnMenu: ColumnMenu },
  { id: 'Donem_Adi', field: 'Donem_Adi', title: 'Donem_Adi', columnMenu: ColumnMenu },
  { id: 'Uye_Adi', field: 'Uye_Adi', title: 'Uye_Adi', columnMenu: ColumnMenu },
  { id: 'Uye_Kodu', field: 'Uye_Kodu', title: 'Uye_Kodu', columnMenu: ColumnMenu },
  { id: 'Kimlik_Tel', field: 'Kimlik_Tel', title: 'Kimlik_Tel', columnMenu: ColumnMenu },
  { id: 'Kimlik_Ev_tel', field: 'Kimlik_Ev_tel', title: 'Kimlik_Ev_tel', columnMenu: ColumnMenu },
  { id: 'Kimlik_Gsm', field: 'Kimlik_Gsm', title: 'Kimlik_Gsm', columnMenu: ColumnMenu },
  { id: 'Kimlik_Gsm2', field: 'Kimlik_Gsm2', title: 'Kimlik_Gsm2', columnMenu: ColumnMenu },
  { id: 'Rez_Yapan', field: 'Rez_Yapan', title: 'Rez_Yapan', columnMenu: ColumnMenu },
  { id: 'Kulanici_Adi', field: 'Kulanici_Adi', title: 'Kulanici_Adi', columnMenu: ColumnMenu },
  { id: 'Rci_Adi', field: 'Rci_Adi', title: 'Rci_Adi', columnMenu: ColumnMenu },
  { id: 'Rci_Soyadi', field: 'Rci_Soyadi', title: 'Rci_Soyadi', columnMenu: ColumnMenu },
  { id: 'Konaklama_Gun', field: 'Konaklama_Gun', title: 'Konaklama_Gun', columnMenu: ColumnMenu },
  { id: 'Room_Bak', field: 'Room_Bak', title: 'Room_Bak', columnMenu: ColumnMenu },
  { id: 'Ext_Bak', field: 'Ext_Bak', title: 'Ext_Bak', columnMenu: ColumnMenu },
  { id: 'Toplam_Bak', field: 'Toplam_Bak', title: 'Toplam_Bak', columnMenu: ColumnMenu },
  { id: 'Sanal_10', field: 'Sanal_10', title: 'Sanal_10', columnMenu: ColumnMenu },
  { id: 'Bolge_adi', field: 'Bolge_adi', title: 'Bolge_adi', columnMenu: ColumnMenu },
  { id: 'Apart_Adi', field: 'Apart_Adi', title: 'Apart_Adi', columnMenu: ColumnMenu },
  { id: 'Katno', field: 'Katno', title: 'Katno', columnMenu: ColumnMenu },
  { id: 'Gorev_Yenioda', field: 'Gorev_Yenioda', title: 'Gorev_Yenioda', columnMenu: ColumnMenu },
  { id: 'Gorev_Id', field: 'Gorev_Id', title: 'Gorev_Id', columnMenu: ColumnMenu },
  { id: 'Gorev_Giren_aciklama', field: 'Gorev_Giren_aciklama', title: 'Gorev_Giren_aciklama', columnMenu: ColumnMenu },
  { id: 'Gorev_Uygulanacak_tarih', field: 'Gorev_Uygulanacak_tarih', title: 'Gorev_Uygulanacak_tarih', columnMenu: ColumnMenu },
  { id: 'Kimlik_Kart', field: 'Kimlik_Kart', title: 'Kimlik_Kart', columnMenu: ColumnMenu },
  { id: 'Kart_Turu', field: 'Kart_Turu', title: 'Kart_Turu', columnMenu: ColumnMenu },
  { id: 'Uyeliktipi', field: 'Uyeliktipi', title: 'Uyeliktipi', columnMenu: ColumnMenu },
  { id: 'Rez_Net_tutar_tl', field: 'Rez_Net_tutar_tl', title: 'Rez_Net_tutar_tl', columnMenu: ColumnMenu },
  { id: 'Gorev_Hk_stadi', field: 'Gorev_Hk_stadi', title: 'Gorev_Hk_stadi', columnMenu: ColumnMenu },
  { id: 'Uyusmazlik_10', field: 'Uyusmazlik_10', title: 'Uyusmazlik_10', columnMenu: ColumnMenu },
  { id: 'Notlar_Uyusmaz', field: 'Notlar_Uyusmaz', title: 'Notlar_Uyusmaz', columnMenu: ColumnMenu },
  { id: 'Kimlik_Cinsiyet', field: 'Kimlik_Cinsiyet', title: 'Kimlik_Cinsiyet', columnMenu: ColumnMenu },
  { id: 'Tel', field: 'Tel', title: 'Tel', columnMenu: ColumnMenu },
  { id: 'Mail', field: 'Mail', title: 'Mail', columnMenu: ColumnMenu },
];