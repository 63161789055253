import React, { useContext } from 'react';
import { AiFillHome } from "react-icons/ai";
import { LanguageContext } from '../../../Context/LanguageContext/LanguageContext';
import { MenuItemsComponents } from './MenuItemsComponents';

const useMenuItems = () => {
  // Dil context'ini kullan
  const languageContext = useContext(LanguageContext);
  const userPermissions = JSON.parse(localStorage.getItem('user') || '{}');
  const generalPermission = userPermissions.genelYetki;
  // console.log(generalPermission);
  const { translations = {} } = languageContext || {};

  // menuItems dizisini çevirilerle güncelle
  let  menuItems = [
    {
      id: 1,
      title: translations.MenuFrontOffice_1 || "FrontOffice", // Eğer çeviri yoksa varsayılan değeri kullan
      icon: AiFillHome,
      Component: MenuItemsComponents.FrontComponent,
      permission:'front',
      children: [
        {
          id: 2,
          title: translations.MenuSatisVeAcentaIslemleri_1_1 || "Satış Ve Acenta İşlemleri",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 3,
              title: translations.MenuKontratGirisleri_1_1_1 || "Acenta İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 4,
                  title: translations.MenuKontratGirisleri_1_1_1_1 || "Kontrat Girişleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.KontratGirisleri,
                  children: []
                },
                {
                  id: 5,
                  title: translations.MenuAktiviteler_1_1_1_2 || "Aktiviteler",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.Aktiviteler,
                  children: []
                },
              ]
            },
            {
              id: 6,
              title: translations.MenuSatisRaporlari_1_2 || "Satış Raporları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 7,
                  title: translations.MenuKartex_1_2_1 || "Kartex",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.Kartex,
                  children: []
                },
                {
                  id: 8,
                  title: translations.MenuSatisRaporlari_1_2_2 || "Satış Raporları",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 9,
                  title: translations.MenuSatislarinDagilimi_1_2_3 || "Satışların Dağılımı",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 10,
                  title: translations.MenuSatislarinEBDagilimi_1_2_4 || "Satışların EB% Dağılımı",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 11,
                  title: translations.MenuOrtalamaFiyatlar_1_2_5 || "Ortalama Fiyatlar",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 12,
              title: translations.MenuConsolideIslemleri_1_3 || "Consolide İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 13,
                  title: translations.MenuConsolideRaporlar_1_3_1 || "Consolide Raporlar",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 14,
                  title: translations.MenuOtellerArasiTransfer_1_3_2 || "Oteller Arası Transfer",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 15,
              title: translations.MenuButceIslemleri_1_4 || "Bütçe İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 16,
                  title: translations.MenuButce_1_4_1 || "Bütçe 1",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 17,
                  title: translations.MenuButce_1_4_2 || "Bütçe 2",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 18,
              title: translations.MenuCityLedger_1_5 || "City Ledger",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 19,
                  title: translations.MenuPazarFiyatlari_1_5_1 || "Pazar Fiyatları",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 20,
                  title: translations.MenuCityLedger_1_5_2 || "City Ledger",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },

          ]
        },
        {
          id: 21,
          title: translations.MenuRezervasyon_1_2 || "Rezervasyon",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 22,
              title: translations.MenuRezervasyonIslemleri_1_2_1 || "Rezervasyon İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 23,
                  title: translations.MenuRezervasyonGirisi_1_2_1_1 || "Rezervayon Girişi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.RezervasyonGirisi,
                  children: []
                },
                {
                  id: 24,
                  title: translations.MenuRezervasyonRaporu1_1_2_1_2 || "Rezervasyon Raporu 1",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.RezervasyonRaporu1,
                  children: []
                },
                {
                  id: 25,
                  title: translations.MenuRezervasyonRaporu2_1_2_1_3 || "Rezervasyon Raporu 2",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 26,
                  title: translations.MenuInventorySpool_1_2_1_4 || "İnventory Spool",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 27,
                  title: translations.MenuKontenjanGirisi_1_2_1_5 || "Kontenjan Girişi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 28,
                  title: translations.MenuStopSale_1_2_1_6 || "Stop Sale",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 29,
                  title: translations.MenuGrupRezervasyon_1_2_1_7 || "Grup Rezervasyon",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 30,
                  title: translations.MenuForecastGirisi_1_2_1_8 || "Forecast Girişi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 31,
                  title: translations.MenuAcentaOnlineIslemler_1_2_1_9 || "Acenta Online İşlemler",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },

              ]
            },
            {
              id: 32,
              title: translations.MenuForecastIslemleri_1_2_2 || "Forecast İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 33,
                  title: translations.MenuForecast_1_2_2_1 || "Forecast",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 34,
                  title: translations.MenuDolulukAnalizleri_1_2_2_2 || "Doluluk Analizleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 35,
                  title: translations.MenuBilboard_1_2_2_3 || "Bilboard",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 36,
                  title: translations.MenuYillikForecast_1_2_2_4 || "Yıllık Forecast",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 37,
                  title: translations.MenuKlasikForecastlar_1_2_2_5 || "Klasik Forecastlar",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 38,
                  title: translations.MenuIndirimListeleri_1_2_2_6 || "İndirim Listeleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 39,
                  title: translations.MenuPaxDagilimDurumu_1_2_2_7 || "Pax Dağılım Durumu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
          ]
        },
        {
          id: 40,
          title: translations.MenuResepsiyon_1_3 || "Resepsiyon",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 41,
              title: translations.MenuGunlukIslemler_1_3_1 || "Günlük İşlemler",
              icon: AiFillHome,
              Component: MenuItemsComponents.GunlukIslemler,
              children: []
            },
            {
              id: 42,
              title: translations.MenuWalkIn_1_3_2 || "Walk In",
              icon: AiFillHome,
              Component: MenuItemsComponents.WalkIn,
              children: []
            },
            {
              id: 43,
              title: translations.MenuMisafirListesi_1_3_3 || "Misafir Listesi",
              icon: AiFillHome,
              Component: MenuItemsComponents.MisafirListesi,
              children: []
            },
            {
              id: 44,
              title: translations.MenuRoomRack_1_3_4 || "Room Rack",
              icon: AiFillHome,
              Component: MenuItemsComponents.RoomRack,
              children: []
            },
            {
              id: 45,
              title: translations.MenuFiyatKontrol_1_3_5 || "Fiyat Kontrol",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 46,
              title: translations.MenuBlokaj_1_3_6 || "Blokaj",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 47,
              title: translations.MenuKurGirisi_1_3_7 || "Kur Girişi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 48,
              title: translations.MenuKimlikBilgileri_1_3_8 || "Kimlik Bilgileri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 49,
              title: translations.MenuResepsiyonOdaDurumu_1_3_9 || "Resepsiyon Oda Durumu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 50,
              title: translations.MenuBlackList_1_3_10 || "Black List",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 51,
              title: translations.MenuKapiKilitSistemi_1_3_11 || "Kapı Kilit Sistemi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontDeneme2,
              children: []
            },
            {
              id: 52,
              title: translations.MenuIsAkisi_1_3_12 || "İş Akışı (Trace)",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 53,
              title: translations.MenuEvrakTakipZimmetleme_1_3_13 || "Evrak Takip Zimmetleme",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 54,
              title: translations.MenuDetayRoomRack_1_3_14 || "Detay Room Rack",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 55,
              title: translations.MenuGirisCikisListesi_1_3_15 || "Giriş Çıkış Listesi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 56,
              title: translations.MenuJandarmaBildirim_1_3_16 || "Jandarma Bildirimi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 57,
              title: translations.MenuRmosOptikOku_1_3_17 || "Rmos Optik Okuyucu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 58,
              title: translations.MenuIysGonder_1_3_18 || "İys Gönder",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
          ]
        },
        {
          id: 59,
          title: translations.MenuOnkasa_1_4 || "Ön Kasa",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 60,
              title: translations.MenuOnkasaIslemleri_1_4_1 || "Ön Kasa İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 61,
                  title: translations.MenuDovizBozma_1_4_1_1 || "Döviz Bozma",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 62,
                  title: translations.MenuHesapBakma_1_4_1_2 || "Hesap Bakma",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 63,
                  title: translations.MenuDepartmanRaporu_1_4_1_3 || "Departman Raporu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 64,
                  title: translations.MenuHesaplarBordrosu_1_4_1_4 || "Hesaplar Bordrosu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 65,
                  title: translations.MenuMusteriCikis_1_4_1_5 || "Müşteri Çıkış",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 66,
                  title: translations.MenuKasiyerIslemleri_1_4_1_6 || "Kasiyer İşlemleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 67,
                  title: translations.MenuHesapTransferi_1_4_1_7 || "Hesap Transferi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 68,
                  title: translations.MenuKartTanitim_1_4_1_8 || "Kart Tanıtım",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 69,
                  title: translations.MenuExtraFolioAcma_1_4_1_9 || "Extra Folio Açma",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 70,
                  title: translations.MenuKiralamaExtraSatislar_1_4_1_10 || "Kiralama Extra Satışlar",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 71,
                  title: translations.MenuExtraKiralamaRack_1_4_1_11 || "Extra Kiralama Rack",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 72,
                  title: translations.MenuLateCout_1_4_1_12 || "Late Check Out",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 73,
              title: translations.MenuFaturaIslemleri_1_4_2 || "Fatura İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 74,
                  title: translations.MenuFaturaKontrolSistemi_1_4_2_1 || "Fatura Kontrol Sistemi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 75,
                  title: translations.MenuAcentaKrediFaturasi_1_4_2_2 || "Acenta Kredi Faturası",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 76,
                  title: translations.MenuFolioFaturasi_1_4_2_3 || "Folio Faturası",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 77,
                  title: translations.MenuProformaDetay_1_4_2_4 || "Proforma Detay",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 78,
                  title: translations.MenuFaturaBavel_1_4_2_5 || "Fatura (Bavel)",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 79,
                  title: translations.MenuManuelFatura_1_4_2_6 || "Manuel Fatura",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 80,
                  title: translations.MenuFaturaKontrol_1_4_2_7 || "Fatura Kontrol",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
          ]
        },
        {
          id: 81,
          title: translations.MenuKatHizmetleri_1_5 || "Kat Hizmetleri",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 82,
              title: translations.MenuOdaIslemleri_1_5_1 || "Oda İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 83,
              title: translations.MenuRaporlar_1_5_2 || "Raporlar",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 84,
              title: translations.MenuKayipEsya_1_5_3 || "Kayıp Eşya",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
          ]
        },
        {
          id: 85,
          title: translations.MenuGeceIslemleri_1_6 || "Gece İşlemleri",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 86,
              title: translations.MenuRoomPosting_1_6_1 || "Room Posting",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 87,
              title: translations.MenuGunSonuIslemleri_1_6_2 || "Gün Sonu İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
          ]
        },
        {
          id: 88,
          title: translations.MenuRaporlar_1_7 || "Raporlar",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 89,
              title: translations.MenuYonetimRaporlari_1_7_1 || "Yönetim Raporları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 90,
                  title: translations.MenuManagerReportKlasik_1_7_1_1 || "Manager Report (Klasik)",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 91,
                  title: translations.MenuManagementReport_1_7_1_2 || "Management Report",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 92,
                  title: translations.MenuMainCourante_1_7_1_3 || "MainCourante",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 93,
              title: translations.MenuOnKasaGelirRaporlari_1_7_2 || "Ön Kasa Gelir Raporları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 94,
                  title: translations.MenuGelirRaporlari_1_7_2_1 || "Gelir Raporları",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 95,
              title: translations.MenuFormlar_1_7_3 || "Formlar",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 96,
                  title: translations.MenuGirisGecelemeFormu_1_7_3_1 || "Giriş Geceleme Formu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 97,
                  title: translations.MenuForecastKisiselBilgiler_1_7_3_2 || "Forecast Kişisel Bilgiler",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 98,
                  title: translations.MenuResepsiyonDurumRaporlari_1_7_3_3 || "Resepsiyon Durum Raporları",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 99,
                  title: translations.MenuPosKontrolRaporu_1_7_3_4 || "Pos Kontrol Raporu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 100,
                  title: translations.MenuHesapTablosuOlusturma_1_7_3_5 || "Hesap Tablosu Oluşturma",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 101,
                  title: translations.MenuEskiInHouse_1_7_3_6 || "Eski Inhouse",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
          ]
        },
        {
          id: 102,
          title: translations.MenuTanimlamalar_1_8 || "Tanımlamalar",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 103,
              title: translations.MenuGenelTanimlar_1_8_1 || "Genel Tanımlar",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 104,
              title: translations.MenuKullaniciTanimlama_1_8_2 || "Kullanıcı Tanımlama",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
                        {
              id: 105,
              title: translations.MenuOdaTanimlama_1_8_3 || "Oda Tanımlama",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 106,
              title: translations.MenuSistemParametreleri_1_8_4 || "Sistem Parametreleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 107,
              title: translations.MenuChartOlusturma_1_8_5 || "Chart Oluşturma",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 108,
              title: translations.MenuDevirIslemi_1_8_6 || "Devir İşlemi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 109,
              title: translations.MenuKullaniciLogRaporu_1_8_7 || "Kullanıcı Log Raporu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 110,
              title: translations.MenuDepartmanIndirimleri_1_8_8 || "Departman İndirimleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 111,
              title: translations.MenuArizaliOdaOzetGirisi_1_8_9 || "Arızalı Oda Özet Girişi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 112,
              title: translations.MenuGrupHesapIslemleri_1_8_10 || "Grup Hesap İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 113,
              title: translations.MenuFormlarDizayni_1_8_11 || "Formlar Dizaynı",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 114,
              title: translations.MenuRaporDizaynList_1_8_12 || "Rapor Dizayn List",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 115,
              title: translations.MenuDisardanVeriAlma_1_8_13 || "Dışardan Veri Alma",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 116,
              title: translations.MenuTemaSec_1_8_14 || "Tema Seç",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 117,
              title: translations.MenuDilTanimlamaIslemi_1_8_15 || "Dil Tanımlama İşlemi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 118,
              title: translations.MenuTarihDegistirme_1_8_16 || "Tarih Değiştirme",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 119,
              title: translations.MenuKodTransferleri_1_8_17 || "Kod Transferleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 120,
              title: translations.MenuLateCout_1_8_18 || "Late Check Out",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 121,
              title: translations.MenuSirketBilgileri_1_8_19 || "Şirket Bilgileri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 122,
              title: translations.MenuMailSablonlari_1_8_20 || "Mail Şablonları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 123,
              title: translations.MenuEkParametreler_1_8_21 || "Ek Parametreler",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 124,
              title: translations.MenuKullaniciListesi_1_8_22 || "Kullanıcı Listesi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 125,
              title: translations.MenuKdvTanimlari_1_8_23 || "Kdv Tanımları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 126,
              title: translations.MenuKodFiyatlari_1_8_24 || "Kod Fiyatları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
          ]
        },
      ]
    },
    {
      id: 127,
      title: translations.MenuBackOffice_2 || "BackOffice",
      icon: AiFillHome,
      Component: MenuItemsComponents.FrontComponent,
      permission: "back",
      children: [
        {
          id: 128,
          title: translations.MenuMuhasebe_2_1 || "Muhasebe",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 129,
              title: translations.MenuHesapPlani_2_1_1 || "Hesap Planı",
              icon: AiFillHome,
              Component: MenuItemsComponents.BackHesapPlani,
              children: []
            },
            {
              id: 130,
              title: translations.MenuFisIslemleri_2_1_2 || "Fiş İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 131,
                  title: translations.MenuFisGirisi_2_1_2_1 || "Fiş Girişi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 132,
                  title: translations.MenuFisKontrolListesi_2_1_2_2 || "Fiş Kontrol Listesi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 133,
                  title: translations.MenuTopluFisIslemleri_2_1_2_3 || "Toplu Fiş İşlemleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 134,
                  title: translations.MenuFaturaKontrolRaporu_2_1_2_4 || "Fatura Kontrol Raporu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 135,
                  title: translations.MenuKurFarkiMahsubu_2_1_2_5 || "Kur Farkı Mahsubu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 136,
                  title: translations.MenuHesapTransfer_2_1_2_6 || "Hesap Transfer",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 137,
                  title: translations.MenuFisTransfer_2_1_2_7 || "Fiş Transfer",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 138,
                  title: translations.MenuYansitmaMahsubu_2_1_2_8 || "Yansıtma Mahsubu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 139,
                  title: translations.MenuKapanisMahsubu_2_1_2_9 || "Kapanış Mahsubu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 140,
                  title: translations.MenuIptalFisler_2_1_2_10 || "İptal Fişler",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 141,
                  title: translations.MenuCariIslemleri_2_1_2_11 || "Cari İşlemleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 142,
                  title: translations.MenuKurFarkiFaturasi_2_1_2_12 || "Kur Farkı Faturası",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 143,
              title: translations.MenuFisKontrolListesi_2_1_3 || "Fiş Kontrol Listesi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 144,
              title: translations.MenuHesapIzleme_2_1_4 || "Hesap İzleme",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 145,
              title: translations.MenuMizan_2_1_5 || "Mizan",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 146,
              title: translations.MenuDovizliMizan_2_1_6 || "Dövizli Mizan",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 147,
                  title: translations.MenuDovizliMizan_2_1_6_1 || "Dövizli Mizan",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 148,
                  title: translations.MenuDovizliMizan2_2_1_6_2 || "Dövizli Mizan 2",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 149,
              title: translations.MenuMasrafMerkezi_2_1_7 || "Masraf Merkezi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 150,
              title: translations.MenuYardimciDefter_2_1_8 || "Yardımcı Defter",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 151,
              title: translations.MenuYaslandirmaliMizan_2_1_9 || "Yaslandırmalı Mizan",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 152,
              title: translations.MenuVadeliHesapKontrolu_2_1_10 || "Vadeli Hesap Kontrolü",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 153,
              title: translations.MenuBaBsFormlari_2_1_11 || "Ba - Bs Formları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 154,
              title: translations.MenuMaliTablolar_2_1_12 || "Mali Tablolar",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 155,
                  title: translations.MenuYevmiyeDefteri_2_1_12_1 || "Yevmiye Defteri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 156,
                  title: translations.MenuDefteriKebir_2_1_12_2 || "Defter-i Kebir",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 157,
                  title: translations.MenuBilanco_2_1_12_3 || "Bilanço",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 158,
                  title: translations.MenuGelirTablosu_2_1_12_4 || "Gelir Tablosu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 159,
                  title: translations.MenuFaaliyetRaporu_2_1_12_5 || "Faaliyet Raporu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 160,
                  title: translations.MenuKdvBeyannamesi_2_1_12_6 || "KDV Beyannamesi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 161,
                  title: translations.MenuMerkezBankasiSrvts_2_1_12_7 || "Merkez Bankası SRVTS",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 162,
                  title: translations.MenuEMutabakat_2_1_12_8 || "E-Mutabakat",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 163,
              title: translations.MenuGelirler_2_1_13 || "Gelirler",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 164,
                  title: translations.MenuGelirTransfer_2_1_13_1 || "Gelir Transfer",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 165,
                  title: translations.MenuFaturaTransfer_2_1_13_2 || "Fatura Transfer",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 166,
                  title: translations.MenuFaturaMahsup3_2_1_13_3 || "Fatura Mahsup 3",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 167,
                  title: translations.MenuProtel_2_1_13_4 || "Protel",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 168,
                  title: translations.MenuAnalizRaporlari_2_1_13_5 || "Analiz Raporları",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 169,
                  title: translations.MenuAvansVirmanMahsubu_2_1_13_6 || "Avans Virman Mahsubu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 170,
                  title: translations.MenuKomisyonGeliri_2_1_13_7 || "Komisyon Geliri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 171,
                  title: translations.MenuHesaptanFatura_2_1_13_8 || "Hesaptan Fatura",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 172,
                  title: translations.MenuGruplamaTopluMutabakat_2_1_13_9 || "Gruplama Toplu Mutabakat",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 173,
              title: translations.MenuMuhasebeParametreleri_2_1_14 || "Muhasebe Parametreleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
          ]
        },
        {
          id: 174,
          title: translations.MenuKasaBanka_2_2 || "Kasa - Banka",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 175,
              title: translations.MenuKasaIslemleri_2_2_1 || "Kasa İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 176,
                  title: translations.MenuKasaFisleri_2_2_1_1 || "Kasa Fişleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 177,
                  title: translations.MenuKasaRaporu_2_2_1_2 || "Kasa Raporu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 178,
                  title: translations.MenuGunlukKasaRaporu_2_2_1_3 || "Günlük Kasa Raporu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 179,
              title: translations.MenuBankaIslemleri_2_2_2 || "Banka İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 180,
                  title: translations.MenuBankaFisleri_2_2_2_1 || "Banka Fişleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 181,
                  title: translations.MenuTopluBankaFisleri_2_2_2_2 || "Toplu Banka Fişleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 182,
                  title: translations.MenuBankaRaporu_2_2_2_3 || "Banka Raporu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 183,
                  title: translations.MenuBankaBakiyem_2_2_2_4 || "Banka Bakiyem",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 184,
              title: translations.MenuNakitAkim_2_2_3 || "Nakit Akım",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 185,
                  title: translations.MenuButceGirisleri_2_2_3_1 || "Bütçe Girişleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 186,
                  title: translations.MenuNakitAkimTablo_2_2_3_2 || "Nakit Akım Tablo",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 187,
                  title: translations.MenuNakitAkimButcesi_2_2_3_3 || "Nakit Akım Bütçesi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
          ]
        },
        {
          id: 188,
          title: translations.MenuCekSenet_2_3 || "Çek Senet",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 189,
              title: translations.MenuCekSenetGirisi_2_3_1 || "Çek Senet Girişi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 190,
              title: translations.MenuCekSenetListesi_2_3_2 || "Çek Senet Listesi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 191,
              title: translations.MenuCekSenetRapor_2_3_3 || "Çek Senet Rapor",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
          ]
        },
        {
          id: 192,
          title: translations.MenuButceIslemleri_2_4 || "Bütçe İşlemleri",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 193,
              title: translations.MenuAylikButceRaporu_2_4_1 || "Aylık Bütçe Raporu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 194,
              title: translations.MenuYillikButceRaporu_2_4_2 || "Yıllık Bütçe Raporu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 195,
              title: translations.MenuGrupluButceRaporu_2_4_3 || "Gruplu Bütçe Raporu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 196,
              title: translations.MenuButceKodlari_2_4_4 || "Bütçe Kodları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 197,
              title: translations.MenuButceFaaliyetRaporlari_2_4_5 || "Bütçe - Faaliyet Raporları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
          ]
        },
        {
          id: 198,
          title: translations.MenuStokKontrol_2_5 || "Stok Kontrol",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 199,
              title: translations.MenuStokIslemleri_2_5_1 || "Stok İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 200,
                  title: translations.MenuStokKodlari_2_5_1_1 || "Stok Kodları",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 201,
                  title: translations.MenuAlisFaturasi_2_5_1_2 || "Alış Faturası",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 202,
                  title: translations.MenuSatisFaturasi_2_5_1_3 || "Satış Faturası",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 203,
                  title: translations.MenuAmbarTalep_2_5_1_4 || "Ambar Talep",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 204,
                  title: translations.MenuIadeFaturasi_2_5_1_5 || "İade Faturası",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 205,
                  title: translations.MenuTransferFisi_2_5_1_6 || "Transfer Fişi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: [
                    {
                      id: 206,
                      title: translations.MenuDepartmanTransfer_2_5_1_6_1 || "Departman Transfer",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                    {
                      id: 207,
                      title: translations.MenuAnaDepoTransfer_2_5_1_6_2 || "Ana Depo Transfer",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                  ]
                },
                {
                  id: 208,
                  title: translations.MenuDepartmanIade_2_5_1_7 || "Departman İade",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 209,
                  title: translations.MenuDevirFisleri_2_5_1_8 || "Devir Fişleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: [
                    {
                      id: 210,
                      title: translations.MenuAnaDepoDevir_2_5_1_8_1 || "Ana Depo Devir",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                    {
                      id: 211,
                      title: translations.MenuDepartmanDevir_2_5_1_8_2 || "Departman Devir",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                  ]
                },
                {
                  id: 212,
                  title: translations.MenuHasarFisi_2_5_1_9 || "Hasar Fişi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 213,
                  title: translations.MenuTopluStokFisleri_2_5_1_10 || "Toplu Stok Fişleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 214,
                  title: translations.MenuStokKarti_2_5_1_11 || "Stok Kartı",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 215,
              title: translations.MenuStokRaporlari_2_5_2 || "Stok Raporları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 216,
                  title: translations.MenuGirisRaporlari_2_5_2_1 || "Giriş Raporları",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 217,
                  title: translations.MenuCikisRaporlari_2_5_2_2 || "Çıkış Raporları",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 218,
                  title: translations.MenuEnvanterRaporlari_2_5_2_3 || "Envanter Raporları",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 219,
                  title: translations.MenuSayimIslemleri_2_5_2_4 || "Sayım İşlemleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: [
                    {
                      id: 220,
                      title: translations.MenuSayimIslemleri_2_5_2_4_1 || "Sayım İşlemleri",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                    {
                      id: 221,
                      title: translations.MenuBarkodluSayim_2_5_2_4_2 || "Barkodlu Sayım",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                    {
                      id: 222,
                      title: translations.MenuReceteSayim_2_5_2_4_3 || "Reçete Sayım",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                  ]
                },
                {
                  id: 223,
                  title: translations.MenuDigerRaporlar_2_5_2_5 || "Diğer Raporlar",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: [
                    {
                      id: 224,
                      title: translations.MenuTransferRaporlari_2_5_2_5_1 || "Transfer Raporları",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                    {
                      id: 225,
                      title: translations.MenuMinMaxKontrolu_2_5_2_5_2 || "Min-Max Kontrolü",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                    {
                      id: 226,
                      title: translations.MenuOrtalamaFiyatlar_2_5_2_5_3 || "Ortalama Fiyatlar",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                    {
                      id: 227,
                      title: translations.MenuFaturaKontrolRaporu_2_5_2_5_4 || "Fatura Kontrol Raporu",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                    {
                      id: 228,
                      title: translations.MenuHareketsizStoklar_2_5_2_5_5 || "Hareketsiz Stoklar",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                    {
                      id: 229,
                      title: translations.MenuTotalStokRaporu_2_5_2_5_6 || "Total Stok Raporu",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                    {
                      id: 230,
                      title: translations.MenuIptalFisler_2_5_2_5_7 || "İptal Fişler",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                    {
                      id: 231,
                      title: translations.MenuPivotRapor_2_5_2_5_8 || "Pivot Rapor",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                  ]
                },
                {
                  id: 232,
                  title: translations.MenuMalKabulFormu_2_5_2_6 || "Mal Kabul Formu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 233,
                  title: translations.MenuAySonu_2_5_2_7 || "Ay Sonu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 234,
              title: translations.MenuStokParametreleri_2_5_3 || "Stok Paremetreleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
          ]
        },
        {
          id: 235,
          title: translations.MenuCostKontrol_2_6 || "Cost Kontrol",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 236,
              title: translations.MenuReceteIslemleri_2_6_1 || "Reçete İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 237,
                  title: translations.MenuReceteKodlari_2_6_1_1 || "Reçete Kodları",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 238,
                  title: translations.MenuSatisFisi_2_6_1_2 || "Satış Fişi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 239,
                  title: translations.MenuOdenmezIslemleri_2_6_1_3 || "Ödenmez İşlemleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 240,
                  title: translations.MenuAktifRecete_2_6_1_4 || "Aktif Reçete",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 241,
                  title: translations.MenuUretim_2_6_1_5 || "Üretim",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 242,
              title: translations.MenuReceteRaporlari_2_6_2 || "Reçete Raporları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 243,
                  title: translations.MenuReceteSatisRaporu_2_6_2_1 || "Reçete Satış Raporu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 244,
                  title: translations.MenuMalzemeSatisRaporu_2_6_2_2 || "Malzeme Satış Raporu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 245,
                  title: translations.MenuReceteSatisDagilimi_2_6_2_3 || "Reçete Satış Dağılımı",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 246,
                  title: translations.MenuReceteSatisCizelgesi_2_6_2_4 || "Reçete Satış Çizelgesi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 247,
                  title: translations.MenuMalzemeSatisCizelgesi_2_6_2_5 || "Malzeme Satış Çizelgesi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 248,
              title: translations.MenuCostRaporlari_2_6_3 || "Cost Raporları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 249,
                  title: translations.MenuDepartmanEnvanter_2_6_3_1 || "Departman Envanter",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 250,
                  title: translations.MenuDepartmanCostRaporlari_2_6_3_2 || "Departman Cost Raporları",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 251,
                  title: translations.MenuTuketimRaporu_2_6_3_3 || "Tüketim Raporu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 252,
                  title: translations.MenuYillikTuketimRaporlari_2_6_3_4 || "Yıllık Tüketim Raporları",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 253,
                  title: translations.MenuKisiBasiMaliyet_2_6_3_5 || "Kişi Başı Maliyet",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 254,
                  title: translations.MenuYillikKarsilastirma_2_6_3_6 || "Yıllık Karşılaştırma",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 255,
              title: translations.MenuPos_2_6_4 || "Pos",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 256,
                  title: translations.MenuSatisIndirimleri_2_6_4_1 || "Satış İndirimleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 257,
                  title: translations.MenuPansiyonReceteleri_2_6_4_2 || "Pansiyon Reçeteleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
          ]
        },
        {
          id: 258,
          title: translations.MenuDemirbas_2_7 || "Demirbaş",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 259,
              title: translations.MenuDemirbasIslemleri_2_7_1 || "Demirbaş İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 260,
                  title: translations.MenuDemirbasKartlari_2_7_1_1 || "Demirbaş Kartları",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 261,
                  title: translations.MenuDemirbasAlisFaturasi_2_7_1_2 || "Demirbaş Alış Faturası",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 262,
                  title: translations.MenuDemirbasSatisFaturasi_2_7_1_3 || "Demirbaş Satış Faturası",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 263,
                  title: translations.MenuDemirbasIadeFaturasi_2_7_1_4 || "Demirbaş İade Faturası",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 264,
                  title: translations.MenuDemirbasDuzeltmeKaydi_2_7_1_5 || "Demirbaş Düzeltme Kaydı",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 265,
              title: translations.MenuDemirbasRaporlari_2_7_2 || "Demirbaş Raporları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 266,
                  title: translations.MenuDemirbasListesi_2_7_2_1 || "Demirbaş Listesi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 267,
                  title: translations.MenuDemirbasEnvanterRaporu_2_7_2_2 || "Demirbaş Envanter Raporu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 268,
                  title: translations.MenuDemirbasKarti_2_7_2_3 || "Demirbaş Kartı",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 269,
                  title: translations.MenuAmortismanDefteri_2_7_2_4 || "Amortisman Defteri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 270,
              title: translations.MenuDemirbasParametreleri_2_7_3 || "Demirbaş Parametreleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 271,
                  title: translations.MenuDonemSonu_2_7_3_1 || "Dönem Sonu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 272,
                  title: translations.MenuDemirbasParametreleri_2_7_3_2 || "Demirbaş Parametreleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
          ]
        },
        {
          id: 273,
          title: translations.MenuSatinAlma_2_8 || "Satınalma",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 274,
              title: translations.MenuTalepFormu_2_8_1 || "Talep Formu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 275,
              title: translations.MenuDepoHavuzu_2_8_2 || "Depo Havuzu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 276,
              title: translations.MenuSaticiFiyatGirisi_2_8_3 || "Satıcı Fiyat Girişi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 277,
              title: translations.MenuTalepOnaylama_2_8_4 || "Talep Onaylama",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 278,
              title: translations.MenuSiparisSaticiSecimi_2_8_5 || "Sipariş Satıcı Seçimi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 279,
              title: translations.MenuSiparisOlusturma_2_8_6 || "Sipariş Oluşturma",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 280,
              title: translations.MenuAnlasmaGirisi_2_8_7 || "Anlaşma Girişi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 281,
              title: translations.MenuTeklifRaporu_2_8_8 || "Teklif Raporu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 282,
              title: translations.MenuSiparisKontrolRaporu_2_8_9 || "Sipariş Kontrol Raporu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 283,
              title: translations.MenuSatinalmaPaket_2_8_10 || "Satınalma Paket",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
          ]
        },
        {
          id: 284,
          title: translations.MenuBarkod_2_9 || "Barkod",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 285,
              title: translations.MenuBarkodIslemleri_2_9_1 || "Barkod İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 286,
                  title: translations.MenuUrunGirisi_2_9_1_1 || "Ürün Girişi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 287,
                  title: translations.MenuBarkodKontrol_2_9_1_2 || "Barkod Kontrol",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 288,
                  title: translations.MenuAlisFaturasi_2_9_1_3 || "Alış Faturası",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 289,
                  title: translations.MenuAmbarTalep_2_9_1_4 || "Ambar Talep",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 290,
                  title: translations.MenuDepartmanTransfer_2_9_1_5 || "Departman Transfer",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 291,
                  title: translations.MenuBosIade_2_9_1_6 || "Boş İade",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 292,
                  title: translations.MenuSayim_2_9_1_7 || "Sayım",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 293,
              title: translations.MenuBarkodRaporlari_2_9_2 || "Barkod Raporları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 294,
                  title: translations.MenuStokRaporu_2_9_2_1 || "Stok Raporu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 295,
                  title: translations.MenuBarkodYazdir_2_9_2_2 || "Barkod Yazdır",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 296,
                  title: translations.MenuBarkodTakip_2_9_2_3 || "Barkod Takip",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
          ]
        },
        {
          id: 297,
          title: translations.MenuKonsolide_2_10 || "Konsolide",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 298,
              title: translations.MenuKonsolideRaporlar_2_10_1 || "Konsolide Raporlar",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 299,
                  title: translations.MenuMizan_2_10_1_1 || "Mizan",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 300,
                  title: translations.MenuYaslandirmaliMizan_2_10_1_2 || "Yaslandırmalı Mizan",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 301,
                  title: translations.MenuHesapIzleme_2_10_1_3 || "Hesap İzleme",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 302,
                  title: translations.MenuTuketimAnaliz_2_10_1_4 || "Tüketim Analiz",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 303,
                  title: translations.MenuRiskAnalizTablosu_2_10_1_5 || "Risk Analiz Tablosu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 304,
              title: translations.MenuLogoAktarim_2_10_2 || "Logo Aktarım",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
          ]
        },
        {
          id: 305,
          title: translations.MenuGenelTanimlar_2_11 || "Genel Tanımlar",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 306,
              title: translations.MenuSirketBilgileri_2_11_1 || "Şirket Bilgileri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 307,
              title: translations.MenuKullaniciKodlari_2_11_2 || "Kullanıcı Kodları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 308,
              title: translations.MenuKullaniciLoglari_2_11_3 || "Kullanıcı Logları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 309,
              title: translations.MenuKullaniciRaporDizayni_2_11_4 || "Kullanıcı Rapor Dizaynı",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 310,
              title: translations.MenuSistemAyarlari_2_11_5 || "Sistem Ayarları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 311,
              title: translations.MenuKurGirisi_2_11_6 || "Kur Girişi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 312,
              title: translations.MenuMuhasebeEntegre_2_11_7 || "Muhasebe Entegre",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 313,
              title: translations.MenuDatabaseIslemleri_2_11_8 || "Database İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 314,
              title: translations.MenuDinamikRaporlar_2_11_9 || "Dinamik Raporlar",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
          ]
        },
      ]
    },
    {
      id: 315,
      title: translations.MenuInsanKaynaklari_3 || "İnsan Kaynakları",
      icon: AiFillHome,
      Component: MenuItemsComponents.FrontComponent,
      children: [
        {
          id: 316,
          title: translations.MenuPersonelIslemleri_3_1 || "Personel İşlemleri",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 317,
              title: translations.MenuPersonelBilgiGirisi_3_1_1 || "Personel Bilgi Girişi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 318,
              title: translations.MenuPersonelRaporlari_3_1_2 || "Personel Raporları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 319,
              title: translations.MenuIstatistikRaporlari_3_1_3 || "İstatistik Raporları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 320,
              title: translations.MenuPersonelShiftGirisleri_3_1_4 || "Shift Girişleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 321,
              title: translations.MenuIzinDuzenleme_3_1_5 || "İzin Düzenleme",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 322,
              title: translations.MenuImzaFoyu_3_1_6 || "İmza Foyu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 323,
                  title: translations.MenuImzaFoyuGunluk_3_1_6_1 || "İmza Foyu Günlük",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 324,
                  title: translations.MenuImzaFoyuAylik_3_1_6_2 || "İmza Foyu Aylık",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 325,
              title: translations.MenuBasvuruVePersonelSecimi_3_1_7 || "Başvuru Ve Personel Seçimi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 326,
                  title: translations.MenuBasvuruTakip_3_1_7_1 || "Başvuru Takip",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 327,
                  title: translations.MenuAyinVeYilinPersonelSecimi_3_1_7_2 || "Ayın Ve Yılın Personel Seçimi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 328,
              title: translations.MenuMaasArttirimi_3_1_8 || "Maaş Arttırımı",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 329,
              title: translations.MenuPersonelVeMemnuniyetAnketi_3_1_9 || "Personel Ve Memnunuyet Anketi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 330,
                  title: translations.MenuAnketOlusturma_3_1_9_1 || "Anket Oluşturma",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 331,
                  title: translations.MenuAnketListele_3_1_9_2 || "Anket Listele",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 332,
              title: translations.MenuRaporUretec_3_1_10 || "Rapor Üreteç",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
          ]
        },
        {
          id: 333,
          title: translations.MenuBordroIslemleri_3_2 || "Bordro İşlemleri",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 334,
              title: translations.MenuPuantajGirisi_3_2_1 || "Puantaj Girişi",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 335,
              title: translations.MenuPuantajGirisiManuel_3_2_2 || "Puantaj Girişi Manuel",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 336,
              title: translations.MenuBordroRaporlari_3_2_3 || "Bordro Raporları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 337,
              title: translations.MenuBildirgeIslemleriVeMuhtasar_3_2_4 || "Bildirge İşlemleri Ve Muhtasar",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 338,
                  title: translations.MenuBildirgeIslemleri_3_2_4_1 || "Bildirge İşlemleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 339,
                  title: translations.MenuBildirgeMuhtasarBeyanname_3_2_4_2 || "Bildirge - Muhtasar Beyanname",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 340,
              title: translations.MenuBireyselEmeklilik_3_2_5 || "Bireysel Emeklilik",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 341,
              title: translations.MenuUcretBordrosu_3_2_6 || "Ücret Bordrosu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 342,
                  title: translations.MenuHesapPusulasi_3_2_6_1 || "Hesap Pusulası",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 343,
                  title: translations.MenuYillikPusula_3_2_6_2 || "Yıllık Pusula",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 344,
              title: translations.MenuUcretBordrusuMailGonderme_3_2_7 || "Ücret Bordrosu Mail Gönderme",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 345,
              title: translations.MenuYasalRaporlar_3_2_8 || "Yasal Raporlar",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 346,
              title: translations.MenuKidemIhbar_3_2_9 || "Kıdem İhbar",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 347,
                  title: translations.MenuKidemIhbarHesaplaAuto_3_2_9_1 || "Kıdem İhbar Hesapla Auto",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 348,
                  title: translations.MenuKidemIhbarHesaplaManuel_3_2_9_2 || "Kıdem İhbar Hesapla Manuel",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
          ]
        },
        {
          id: 349,
          title: translations.MenuEgitimYonetim_3_3 || "Eğitim Yönetim",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 350,
              title: translations.MenuGenelEgitimTanimlama_3_3_1 || "Genel Eğitim Tanımlama",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 351,
              title: translations.MenuEgitimKurumuTanimlama_3_3_2 || "Eğitim Kurumu Tanımlama",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 352,
              title: translations.MenuPersonelEgitimTanimlama_3_3_3 || "Personel Eğitim Tanımlama",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 353,
              title: translations.MenuEgitimYonetimiRaporu_3_3_4 || "Eğitim Yönetimi Raporu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 354,
              title: translations.MenuEgitimSertifikasi_3_3_5 || "Eğitim Sertifikası",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
          ]
        },
        {
          id: 355,
          title: translations.MenuPdksOtomasyonu_3_4 || "PDKS Otomasyonu",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 356,
              title: translations.MenuShift_3_4_1 || "Shift",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 357,
                  title: translations.MenuShiftGirisi_3_4_1_1 || "Shift Girişi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 358,
                  title: translations.MenuShiftRaporlar_3_4_1_2 || "Shift Raporlar",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 359,
              title: translations.MenuGirisCikisIslemler_3_4_2 || "Giriş Çıkış İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 360,
                  title: translations.MenuGirisCikisIslemi_3_4_2_1 || "Giriş Çıkış İşlemi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 361,
                  title: translations.MenuCalismaListesiSaatleri_3_4_2_2 || "Çalışma Listesi Saatleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 362,
              title: translations.MenuRaporlar_3_4_3 || "Raporlar",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 363,
              title: translations.MenuPuantorRaporu_3_4_4 || "Puantör Raporu",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 364,
              title: translations.MenuPersonelIslemleri_3_4_5 || "Personel İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 365,
                  title: translations.MenuPdksPersonelVeriIsleme_3_4_5_1 || "PDKS Personel Veri İşleme",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 366,
                  title: translations.MenuPdksGirisCikisKayitAktarimi_3_4_5_2 || "PDKS Giriş Çıkış Kayıt Aktarımı",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 367,
                  title: translations.MenuPdksCihazKlonlama_3_4_5_3 || "PDKS Cihaz Klonlama",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 368,
                  title: translations.MenuTermalGirisCikisKayitAktarimi_3_4_5_4 || "Termal Giriş Çıkış Kayıt Aktarımı",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 369,
                  title: translations.MenuTermalPersonelListesi_3_4_5_5 || "Termal Personel Listesi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 370,
              title: translations.MenuTanimlamalar_3_4_6 || "Tanımlamalar",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 371,
                  title: translations.MenuPdksCihazTanimlama_3_4_6_1 || "PDKS Cihaz Tanımlama",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 372,
                  title: translations.MenuVardiyaTanimlama_3_4_6_2 || "Vardiya Tanımlama",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 373,
                  title: translations.MenuSabitParametreler_3_4_6_3 || "Sabit Parametreler",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                                {
                  id: 374,
                  title: translations.MenuTakvimTanimlama_3_4_6_4 || "Takvim Tanımlama",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 375,
                  title: translations.MenuEgitimVideosu_3_4_6_5 || "Eğitim Videosu",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 376,
                  title: translations.MenuTermalCihazTanimlama_3_4_6_6 || "Termal Cihaz Tanımlama",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                                {
                  id: 377,
                  title: translations.MenuTermalMysqlTanimlama_3_4_6_7 || "Termal MySQL Tanımlama",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
          ]
        },
        {
          id: 378,
          title: translations.MenuTanimlamalar_3_5 || "Tanımlamalar",
          icon: AiFillHome,
          Component: MenuItemsComponents.FrontComponent,
          children: [
            {
              id: 379,
              title: translations.MenuTanimlamalar_3_5_1 || "Tanımlamalar",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 380,
                  title: translations.MenuKodlarTanimlama_3_5_1_1 || "Kodlar Tanımlama",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 381,
                  title: translations.MenuIsGrubuParametreleri_3_5_1_2 || "İş Grubu Parametreleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: [
                    {
                      id: 382,
                      title: translations.MenuParametreTanimlama_3_5_1_2_1 || "Parametre Tanımlama",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                    {
                      id: 383,
                      title: translations.MenuParametreTransfer_3_5_1_2_2 || "Parametre Transfer",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                  ]
                },
                {
                  id: 384,
                  title: translations.MenuMahsupParametreleri_3_5_1_3 || "Mahsup Parametreleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: [
                    {
                      id: 385,
                      title: translations.MenuDepartmanMahsup_3_5_1_3_1 || "Departman Mahsup",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                    {
                      id: 386,
                      title: translations.MenuGorevMahsup_3_5_1_3_2 || "Görev Mahsup",
                      icon: AiFillHome,
                      Component: MenuItemsComponents.FrontComponent,
                      children: []
                    },
                  ]
                },
                {
                  id: 387,
                  title: translations.MenuKvkkAlanTanimlama_3_5_1_4 || "KVKK Alan Tanımlama",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 388,
                  title: translations.MenuSabitParametreler_3_5_1_5 || "Sabit Parametreler",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 389,
                  title: translations.MenuPdksGirisleri_3_5_1_6 || "PDKS Girişleri",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 390,
              title: translations.MenuCalismaAyiVeDonemKitleme_3_5_2 || "Çalışma Ayı Ve Dönem Kitleme",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 391,
                  title: translations.MenuCalismaAyiDegistir_3_5_2_1 || "Çalışma Ayı Değiştir",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 392,
                  title: translations.MenuDonemKitleme_3_5_2_2 || "Dönem Kitleme",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
            {
              id: 393,
              title: translations.MenuSirketTanimlama_3_5_3 || "Şirket Tanımlama",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 394,
              title: translations.MenuKullaniciKodlari_3_5_4 || "Kullanıcı Kodları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 395,
              title: translations.MenuKullaniciLoglari_3_5_5 || "Kullanıcı Logları",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 396,
              title: translations.MenuTransferIslemleri_3_5_6 || "Transfer İşlemleri",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: []
            },
            {
              id: 397,
              title: translations.MenuRaporDizayn_3_5_7 || "Rapor Dizayn",
              icon: AiFillHome,
              Component: MenuItemsComponents.FrontComponent,
              children: [
                {
                  id: 398,
                  title: translations.MenuRaporDizaynListesi_3_5_7_1 || "Rapor Dizayn Listesi",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 399,
                  title: translations.MenuRaporDizayn_3_5_7_2 || "Rapor Dizayn",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
                {
                  id: 400,
                  title: translations.MenuRaporUretec_3_5_7_3 || "Rapor Üreteç",
                  icon: AiFillHome,
                  Component: MenuItemsComponents.FrontComponent,
                  children: []
                },
              ]
            },
          ]
        },
      ]
    },
    {
      id: 998,
      title: translations.MenuFB_4 || "Yiyecek ve İçecek",
      icon: AiFillHome,
      Component: MenuItemsComponents.FrontComponent,
      children: []
    },
    {
      id: 997,
      title: translations.MenuInterfaces_5 || "Interfaces",
      icon: AiFillHome,
      Component: MenuItemsComponents.FrontComponent,
      children: []
    },
    {
      id: 996,
      title: translations.MenuMisafirIliskileri_6 || "Misaifir İlişkileri",
      icon: AiFillHome,
      Component: MenuItemsComponents.FrontComponent,
      children: []
    },
    {
      id: 995,
      title: translations.MenuKalite_7 || "Kalite",
      icon: AiFillHome,
      Component: MenuItemsComponents.FrontComponent,
      children: []
    },
    {
      id: 994,
      title: translations.MenuSadakatSistemi_8 || "Sadakat Sistemi",
      icon: AiFillHome,
      Component: MenuItemsComponents.FrontComponent,
      permission: "loyalty",
      children: [
        {
          id: 999,
          title: translations.MenuPuanlamaAyarlariVeTanimlamalar_8_1 || "Misafir İşlemleri",
          icon: AiFillHome,
          Component: MenuItemsComponents.MisafirIslemleri,
          children: []
        },
        {
          id: 1001,
          title: translations.MenuFirsatDuzenleme_8_2 || "Tanımlamalar",
          icon: AiFillHome,
          Component: MenuItemsComponents.LoyaltyTanimlamalar,
          children: []
        },
        {
          id: 1002,
          title: translations.MenuFirsatDuzenleme_8_3 || "Gelişmiş Puanlama Seçenekleri",
          icon: AiFillHome,
          Component: MenuItemsComponents.GelismisPuanlamaAyari,
          children: []
        },
      ]
    },
    {
      id: 993,
      title: translations.MenuIsTakip_9 || "İş Takibi",
      icon: AiFillHome,
      Component: MenuItemsComponents.FrontComponent,
      children: []
    },
    // Diğer menü öğeleri...
  ];
  menuItems = menuItems.filter(item => item.permission && generalPermission[item.permission] === true);

  return menuItems;
}

export default useMenuItems;
