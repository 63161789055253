// Modal.tsx
import React, { useEffect, useState } from 'react';
import './ModalStyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  size: 'small' | 'medium' | 'large' | 'extra-large' | 'full-screen';
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, size, children, header, footer }) => {
  const [shouldRender, setShouldRender] = useState(isOpen);
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
      setAnimationClass('open');
    } else {
      setAnimationClass('close');
      setTimeout(() => setShouldRender(false), 300); // 300ms animasyon süresi
    }
  }, [isOpen]);

  if (!shouldRender) return null;

  
    return (
      <>
        <div className="overlay1" onClick={onClose} />
        <div  className={`modal1 overflow-hidden  m-0 p-0  ${size} ${animationClass}`}>
          {header && (
            <div  className="modal-header1  d-flex justify-content-between">
              <div className=""></div>
              <div className="header-content1 r-letter-spacing-1 ">{header}</div>
              <FontAwesomeIcon icon={faClose} className="close-icon fs-5"  onClick={onClose} />
            </div>
          )}
           <div id="style-13" className="modal-conte scrollbar overflow-auto">{children}</div> {/* modal-content yerine modal-conte yazıldı düzeltilicek  */}
          {footer && <div className="modal-footer1">{footer}</div>}
        </div>
      </>
    );
  };
  

export default Modal;
