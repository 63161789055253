import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { GridColumnProps } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../Kendo/ColumnMenu';
import { Button } from 'react-bootstrap';
import RmosGrid from '../../Components/DinamikRmosGrid/RmosGrid';

const initialColumns: GridColumnProps[] = [
    { id: 'Id', field: 'Id', title: 'ID', columnMenu: ColumnMenu },
    { id: 'Kodu', field: 'Kodu', title: 'Kod', columnMenu: ColumnMenu },
    { id: 'Adi', field: 'Adi', title: 'Adı', filter: 'numeric', columnMenu: ColumnMenu },
    { id: 'OdaSayisi', field: 'Oda Sayisi', title: 'Oda Sayısı', filter: 'boolean', columnMenu: ColumnMenu },
    { id: 'TabloSayisi', field: 'Tablo Sayisi', title: 'Tablo Sayısı', filter: 'boolean', columnMenu: ColumnMenu },
];
const FrontDeneme2 = () => {
    const apiUrl = 'https://frontapi.rmosweb.com/api/Acenta/Getir_KodNoAuth';
    const requestBody = {
        db_Id: 9,
        Kod: 'ALL?',
    };
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    const gridKey = 'FrontDeneme2';

    return (
        <div>
            <h1>RmosGrid Örneği</h1>
            <RmosGrid
                apiUrl={apiUrl}
                requestBody={requestBody}
                gridKey={gridKey}
                initialColumns={initialColumns}
            />
        </div>
    );
};

export default FrontDeneme2;