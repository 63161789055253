import React, { useState, useEffect } from 'react';
import './ContextMenu.scss';

const useContextMenu = (menuItems: { label: string; icon?: React.ReactNode; onClick: () => void; }[]) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setPosition({ x: event.pageX, y: event.pageY });
    setMenuVisible(true);
  };

  const handleClick = () => {
    setMenuVisible(false);
  };

  // Sayfanın herhangi bir yerine tıklanıldığında menüyü kapat
  useEffect(() => {
    const handlePageClick = (event: MouseEvent) => {
      // Menü görünür durumdayken ve tıklama olayı menünün dışında gerçekleştiğinde menüyü kapat
      if (menuVisible) {
        handleClick();
      }
    };

    // Olay dinleyicileri ekleniyor
    document.addEventListener('click', handlePageClick);

    // Cleanup fonksiyonu. Component unmount olduğunda olay dinleyicileri kaldırılıyor
    return () => {
      document.removeEventListener('click', handlePageClick);
    };
  }, [menuVisible]); // Bağımlılık olarak menuVisible'ı ekledik, böylece menü durumu değiştiğinde useEffect tekrar çalışır

  const renderContextMenu = () => {
    if (!menuVisible) return null;

    return (
      <ul className="context-menu" style={{ top: position.y, left: position.x }}>
        {menuItems.map((item, index) => (
          <li key={index} onClick={() => { item.onClick(); setMenuVisible(false); }}>
            {item.icon && <span className="context-menu-icon">{item.icon}</span>}
            {item.label}
          </li>
        ))}
      </ul>
    );
  };

  return { handleContextMenu, handleClick, renderContextMenu, menuVisible, setMenuVisible };
};

export default useContextMenu;
