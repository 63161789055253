import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';

interface DbIdContextType {
  dbId: string;
  setDbId: (id: string) => void;
}

// Context creation
const DbIdContext = createContext<DbIdContextType | undefined>(undefined);

// Provider component
export const OnburoDbIdProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [dbId, setDbId] = useState(localStorage.getItem('selectedDbId') || '');

  useEffect(() => {
    const handleDbIdChange = () => {
      const newDbId = localStorage.getItem('selectedDbId') || '';
      setDbId(newDbId);
      console.log('DbId has changed:', newDbId);
    };

    // Listen to changes in localStorage
    window.addEventListener('onBuroGuidChanged', handleDbIdChange);

    return () => {
      window.removeEventListener('onBuroGuidChanged', handleDbIdChange);
    };
  }, []);

  return (
    <DbIdContext.Provider value={{ dbId, setDbId }}>
      {children}
    </DbIdContext.Provider>
  );
};

export const useDbId = () => {
  const context = useContext(DbIdContext);
  if (context === undefined) {
    throw new Error('useDbId must be used within a DbIdProvider');
  }
  return context;
};
