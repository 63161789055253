import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
  Checkbox,
  Input,
  NumericTextBox,
  TextArea,
  TextBox,
} from "@progress/kendo-react-inputs";
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Tab,
  Tabs,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEye,
  faPrint,
  faRefresh,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios, { AxiosResponse } from "axios";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import Modal from "../../../../Modal/Modal/Modal";
import GeneralButton from "../../../../../Components/Buttons/FloatActionButton/GeneralButton";
import IcBaslik from "../../../../../Components/Basliklar/IcBaslik";
import { Spinner } from "reactstrap";
// import CheckedComboBox from "../../../../../Components/Girisler/CheckedComboBox";
import debounce from "lodash.debounce";
import UstMenuBar from "../../../../../Components/Basliklar/UstMenuBar";
import FloatingLabelInput from "../../../../../Components/Girisler/FloatingLabelInput";

const initialDataState = {
  take: 10,
  skip: 0,
  group: [],
  filter: undefined,
};

interface SearchTerm {
  ad: string;
  soyad: string;
}

interface Record {
  Ad: string;
  Soyad: string;
  DogumTarihi: Date | null;
  Yas: string;
  No: string;
  Id: string;
}

const WalkIn: React.FC = () => {
  const [gunlukAylikValue, setGunlukAylikValue] = useState("G");
  const [dovizSekliValue, setDovizSekliValue] = useState("DA");
  const [kisiSayisiValue, setKisiSayisiValue] = useState(1);
  const [muslistEHValue, setMuslistEHValue] = useState("E");
  const [yetValue, setYetValue] = useState(0);
  const [cocValue, setCocValue] = useState(0);
  const [freeValue, setFreeValue] = useState(0);
  const [selectedDogumTarihi, setSelectedDogumTarihi] = useState([
    { DogumTarihi: null, Id: 0 },
  ]);
  const [selectedRIH, setSelectedRIH] = useState("");

  const handleRIHChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRIH(event.target.value);
  };
  const [voucherValue, setVoucherValue] = useState("rezervasyon");

  const activeInputIndex = useRef<number | null>(null);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const initialSearchTerm: SearchTerm = { ad: "", soyad: "" };
  const initialRecord: Record = {
    Ad: "",
    Soyad: "",
    DogumTarihi: null,
    Yas: "",
    No: "",
    Id: "",
  };

  const [icerdekilerData, setIcerdekilerData] = useState<any[]>([]);
  const [isIcerdekilerLoading, setIcerdekilerLoading] = useState(false);
  const [icerdekilerDataState, setIcerdekilerDataState] =
    useState(initialDataState);

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isSearchLoading, setSearchLoading] = useState(false);
  const [searchDataState, setSearchDataState] = useState(initialDataState);
  const [searchTerms, setSearchTerms] = useState<any>(
    Array(6).fill({ ...initialSearchTerm })
  );
  const [selectedRecords, setSelectedRecords] = useState<Record[]>(
    Array(6).fill({ ...initialRecord })
  );

  // const [data, setData] = React.useState<any>(WalkInData);
  const [dataState, setDataState] = React.useState<any>(initialDataState);
  const [isLoading, setLoading] = useState(false);
  const [selectedOdaNo, setSelectedOdaNo] = useState<string>("");
  const [odaNoInput, setOdaNoInput] = useState<string>("");
  const [girisTarihi, setGirisTarihi] = useState<Date | null>(null);
  const [geceleme, setGeceleme] = useState<number>(1);
  const [cikisTarihi, setCikisTarihi] = useState<Date | null>(null);

  useEffect(() => {
    console.log("searchterm", searchTerms);
  }, [setSearchTerms]);

  const onGridDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };
  const handleFilterChange = (
    setFilteredData: any,
    data: any[],
    event: any
  ) => {
    if (!event.filter.value) {
      setFilteredData(data);
      return;
    }
    const filter = event.filter.value.toLowerCase();
    setFilteredData(
      data.filter((item) => item.Ad.toLowerCase().includes(filter))
    );
  };

  const handleComboBoxChange = (
    setter: any,
    setFilteredData: any,
    data: any[],
    event: any
  ) => {
    setter(event.value);
    handleFilterChange(setFilteredData, data, {
      filter: { value: event.value },
    });
  };

  interface Person {
    ad: string;
    soyad: string;
    DogumTarihi: string;
    Yas: string;
    No: string;
    Previl_id: any;
  }

  const handleWalkInTamamla = async () => {
    const selectedValues = {
      konaklama: konaklama.find((item) => item.Ad === konaklamaValue),
      konum: konum.find((item) => item.Ad === konumValue),
      acenta: acenta.find((item) => item.Adi === acentaValue),
      ulkeKodu: ulkeKodu.find((item) => item.Ad === ulkeKoduValue),
      kurKodu: kurKodu.find((item) => item.Ad === kurKoduValue),
      satisSekli: satisSekli.find((item) => item.Ad === satisSekliValue),
      vipKodu: vipKodu.find((item) => item.Ad === vipKoduValue),
      pazarKodu: pazarKodu.find((item) => item.Ad === pazarKoduValue),
      musteriTipi: musteriTipi.find((item) => item.Ad === musteriTipiValue),
      odemeSekli: odemeSekli.find((item) => item.Ad === odemeSekliValue),
    };

    const formatDate = (date: Date | null) => {
      if (!date) return "";
      const year = date.getFullYear();
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      return `${year}-${month}-${day}`;
    };

    const persons = searchTerms
      .map(
        (term: SearchTerm, index: number): Person => ({
          ad: term.ad,
          soyad: term.soyad,
          DogumTarihi: formatDate(selectedRecords[index]?.DogumTarihi || null),
          Yas: selectedRecords[index]?.Yas || "",
          No: selectedRecords[index]?.No || "",
          Previl_id: selectedRecords[index]?.Id || 0,
        })
      )
      .filter(
        (person: Person) =>
          person.ad ||
          person.soyad ||
          person.DogumTarihi ||
          person.Yas ||
          person.No ||
          person.Previl_id
      );

    const dbId = localStorage.getItem("selectedDbId");
    let masterId = null;

    try {
      for (let i = 0; i < persons.length; i++) {
        const person = persons[i];
        const requestBody = {
          db_Id: dbId ? parseInt(dbId) : 9,
          Id: 0,
          Voucher: voucherValue,
          Master_detay: i === 0 ? "M" : "D",
          Fiat_bas_eh: "",
          R_I_H: selectedRIH === "rezervasyon" ? "R" : "I",
          Oda_sayisi: i === 0 ? 1 : 0,
          Fiat_om: "",
          Master_id: i === 0 ? 0 : masterId,
          Buyuk: i === 0 ? yetValue : 0,
          Kucuk: i === 0 ? cocValue : 0,
          Free: i === 0 ? freeValue : 0,
          kucuk2: 0,
          Toplam_kisi: i === 0 ? kisiSayisiValue : 0,
          Yas_tanimi: person.Yas || "",
          Hitap: "",
          Adi_1: person.ad || "",
          Adi_2: person.soyad || "",
          Macenta: selectedValues.acenta?.Kodu || "",
          Istenen: selectedValues.konum?.Kod || "",
          Verilen: selectedValues.konum?.Kod || "",
          Mus_tipi: selectedValues.musteriTipi?.Kod || "",
          Konaklama: selectedValues.konaklama?.Kod || "",
          Ulke: selectedValues.ulkeKodu?.Kod || "",
          Odeme: selectedValues.odemeSekli?.Kod || "",
          Pazar: selectedValues.pazarKodu?.Kod || "",
          Giris_sabit_eh: "",
          Tbl_kodu: "",
          Tbl_kodu2: "",
          Tbl_id: 0,
          Tblhr_id: 0,
          Previl_id: person.Previl_id,
          Doviz_as: "",
          Gunluk_aylik: gunlukAylikValue,
          Kur_kodu: selectedValues.kurKodu?.Kod || "",
          Kur_uygulanan: dovizAlis,
          Geceleme: geceleme,
          Alis_tarihi: formatDate(girisTarihi),
          Sistem_tarihi: formatDate(girisTarihi),
          Giris_tarihi: formatDate(girisTarihi),
          Cikis_tarihi: formatDate(cikisTarihi),
          Iptal_tarihi: new Date().toISOString(),
          Degisim_tarihi: new Date().toISOString(),
          Kapora_tarihi: new Date().toISOString(),
          Giris_saati: "",
          Cikis_saati: "",
          C_W: "C",
          Alan: "",
          Giren_id: 0,
          Cocuk_yas1: 0,
          Cocuk_yas2: 0,
          Cocuk_yas3: 0,
          Cocuk_yas4: 0,
          Cocuk_yas5: 0,
          Ucus_no: "",
          Giris_tutar: 0,
          Giris_ind_tutar: 0,
          Giris_net_tutar: 0,
          Toplam_tutar: parseFloat(firstInputValue) || 0,
          Kapora: 0,
          Ind_tutar: 0,
          Ind_grubu: "",
          Ozel_ind_kodu: "",
          Ozel_ind_yt: "",
          Ozel_ind_yuzde: 0,
          Ozel_ind_kodu2: "",
          Ozel_ind_yt2: "",
          Ozel_ind_yuzde2: 0,
          Nor_indirim: 0,
          Ext_indirim: 0,
          Net_tutar: 0,
          Fatura_eh: "",
          Fatura_no: "",
          Muslst_eh: muslistEHValue,
          Birles_oda_10: 0,
          rci_10: true,
          Noshow_10: 0,
          Breakdown_yt: "",
          Inh_toplam: 0,
          Inh_oda: 0,
          Inh_kahvalti: 0,
          Inh_oyle: 0,
          Inh_aksam: 0,
          Inh_icecek: 0,
          Inh_yiyecek: 0,
          Inh_extra: 0,
          Inh_tltoplam: 0,
          Inh_tloda: 0,
          Inh_tlkahvalti: 0,
          Inh_tloyle: 0,
          Inh_tlaksam: 0,
          Inh_tlicecek: 0,
          Inh_tlyiyecek: 0,
          Inh_tlextra: 0,
          Toplam_dv_borc: 0,
          Toplam_dv_alac: 0,
          Toplam_tl_borc: 0,
          Toplam_tl_alac: 0,
          Kartno: "",
          Blokaj: odaNoInput,
          Odano: odaNoInput,
          Birles_oda: "",
          Posting_oda: "",
          Cin_giren_id: 0,
          Kapat_eh: "",
          Kayitno: "",
          Posting_eh: "",
          Vip_kodu: selectedValues.vipKodu?.Kod || "",
          Source: "",
          Chanel: "",
          Degisim_kodu: "",
          Blokeli: 0,
          Room_chg_eh: "",
          Uzatma_eh: "",
          Early_cout_eh: "",
          Grup_nor: "",
          Grup_id: 0,
          Grup_kodu: "",
          Gruphrk_id: 0,
          Co_city_eh: "",
          Co_fat_id: 0,
          Co_fathrk_id: 0,
          Gunfis: 0,
          Co_fatno: 0,
          Yil_kodu: 0,
          Otel_kodu: "",
          Sirket: 0,
          Dogum_tarihi: person.DogumTarihi || "",
          Polis_eh: "",
          Ikram_kodu: "",
          Yapan: "",
          Sistem_giris_saati: "",
          Sistem_cikis_saati: "",
          Yeniden_hesap: 0,
          Yeniden_tbl: "",
          Yeniden_kur: 0,
          Yeniden_tltutar: 0,
          Fat_extra_eh: "",
          Fatura_bolum: 0,
          Fatura_no2: 0,
          Fatura_no3: 0,
          Diplomat_10: 0,
          Uye_kodu: "",
          Uye_depind_kodu: "",
          Pax_cout_eh: "",
          Macenta_tbl: "",
          Satis_sekli: "",
          Proforma_sabitle_10: 0,
          Macenta_id: 0,
          Kkk_no: "",
          Sonrakiktar_10: "",
          Tfolio: "",
          Opsiyon_tarihi: new Date().toISOString(),
          Doviz_Sekli: dovizSekliValue,
          Ekleme: "",
          Ekleme_tutar: 0,
          Ekleme_tutar_tl: 0,
          Depozito: 0,
          kk_Sontarih: "",
          kk_sww2: "",
          limit: 0,
          limit_uyari_eh: "",
          Durum_abc: "",
          Durum_abc_zaman: new Date().toISOString(),
          Rehber: "",
          Plaka: "",
          Yas_Uygulanmasin_10: 0,
          Ozel_posting: "",
          Tel: "",
          Iptal_userid: 0,
          Bebek_yatak: 0,
          Pmmaster_no: 0,
          Gizli_ozel_10: 0,
          Disarda_10: 0,
          Rez_sistemsaati: "",
          Gelis_sayisi: 0,
          Proforma_id: 0,
          Tbl_kodu3: "",
          Tevkifat_var_10: 0,
          Ekdosya_10: true,
          Posting_folio: 0,
          Online_10: 0,
          Yatak_tipi: "",
          Mail_gitti_10: 0,
          Anket_doldurdu_10: 0,
          Mesaj_gitti_10: 0,
          Verilen_eski: "",
          Cocuk_0_2: 0,
          Cocuk_3_6: 0,
          Cocuk_7_10: 0,
          Cocuk_11_14: 0,
          Mhrk_Id: 0,
          Aracno: "",
          Aracno2: "",
          Walkin_10: 0,
          Kiralama_tutar: 0,
          Gelen_id: "",
          Dairetip: "",
          Ind_yapma: "",
          Fatura_kesilmesin_10: 0,
          Fatmakbuz_Serino2: "",
          Fatmakbuz_Serino3: "",
          Net_gunluktl: 0,
          Net_tl: 0,
          Late_cout_10: 0,
          Late_carpan: 0,
          Limit_bakiye_10: 0,
          Mail: "",
          Blokaj_kilit_10: 0,
          Bilet_tarih: new Date().toISOString(),
          Akbs_islendi_10: 0,
          Havlu_bebek_arabavs: "",
          Sistem_giris: new Date().toISOString(),
          Sistem_cikis: new Date().toISOString(),
          Swap_10: 0,
          Swap_Odano: "",
          Tahsilat_kodu: "",
          Kimlik_gor_10: 0,
          Master_id2: "",
          Id2: "",
          Borc_tl: 0,
          EBorc_tl: 0,
          Borc_doviz: 0,
          EBorc_doviz: 0,
          Borc_def: 0,
          EBorc_def: 0,
          Yukleme_no: 0,
          Oda_sirasi: 0,
          Kontrol_10: 0,
          Resim_id: 0,
          Onlineversion: "",
          Tip: 0,
          Manuel_10: 0,
          Room_Sanal_10: 0,
          Breakdown_manuel_10: 0,
          Acenta_paket: "",
          Konver123: 0,
          Sira: 0,
          Tr_masterid: 0,
          Tr_sirket: 0,
          Tr_tarih: new Date().toISOString(),
          Indirim_isim: "",
          Kucuk_1: 0,
          Kucuk_0: 0,
          Isimler: "",
          Cocuk_yas6: 0,
          Cocuk_yas7: 0,
          Yakinlik: "",
          Ocr_id: 0,
          Evlilik_10: 0,
          Pansiyon_ozelkod: "",
          Iptal_123: 0,
          Fat_kendi: "",
          Fat_crmid: 0,
          Split_10: 0,
          Split_master_10: 0,
          Gelmedi_10: 0,
          Proforma_kontrol_10: 0,
          Evrak_tarandi_10: 0,
          Cout_kontrol_10: 0,
          Carpan_thy: 0,
          Carpan_10: 0,
          Odeme_tarihi: new Date().toISOString(),
          Odeme_dovkodu: "",
          Odeme_serino: "",
          Odeme_tutar: 0,
          Odeme_mahsuptutar: 0,
          RmosGuestx_userid: 0,
          Noshow_yt: "",
          Noshow_yuzde: 0,
          Noshow_Basilacak: 0,
          Vestel_10: 0,
          rez_Kartno11: "",
          rez_Kartno12: "",
          rez_Kartno13: "",
        };

        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        interface ApiResponse {
          isSucceded: boolean;
          value: any; // Replace 'any' with the actual type of 'value'
        }

        const response: AxiosResponse<ApiResponse> = await axios.post(
          "https://frontapi.rmosweb.com/api/Rez/Ekle",
          requestBody,
          { headers }
        );

        console.log("API Response:", response.data);

        if (response.data.isSucceded && i === 0) {
          masterId = response.data.value;

          // İlk kişinin Master_id alanını güncelle
          const updateRequestBody = {
            db_Id: dbId ? parseInt(dbId) : 9,
            Id: response.data.value,
            Master_id: response.data.value,
          };

          await axios.post(
            "https://frontapi.rmosweb.com/api/Rez/UpdateMaster",
            updateRequestBody,
            { headers }
          );
        }
      }
      alert("Giriş başarılı");
    } catch (error) {
      console.error("API Error:", error);
      alert("Giriş başarısız");
    }
  };

  const [dovizAlis, setDovizAlis] = useState("");
  const [firstInputValue, setFirstInputValue] = useState("");
  const [secondInputValue, setSecondInputValue] = useState("");
  const [toplamTutar, setToplamTutar] = useState<string>("");

  const onFirstInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      const value = event.currentTarget.value;
      setFirstInputValue(value);
      setToplamTutar(value);

      const numericValue = parseFloat(value);
      const numericDovizAlis = dovizAlis !== null ? dovizAlis : 1;

      if (!isNaN(numericValue) && typeof numericDovizAlis === "number") {
        setSecondInputValue((numericValue * numericDovizAlis).toFixed(2));
      } else {
        setSecondInputValue("");
      }
    }
  };

  const onKurKoduChange = async (event: any) => {
    handleComboBoxChange(setKurKoduValue, setKurKoduFiltered, kurKodu, event);

    if (event.value) {
      const selectedKurKodu = kurKodu.find(
        (item) => item.Ad === event.value
      )?.Kod;
      if (selectedKurKodu) {
        console.log("Seçilen Kur Kodu:", selectedKurKodu);
        try {
          const dbId = localStorage.getItem("selectedDbId");
          const response = await axios.post(
            "https://frontapi.rmosweb.com/api/Kurlar/Getir_Kod",
            {
              db_Id: dbId,
              Cesit: "M",
              Tarih: "2023-05-24",
              Kodu: selectedKurKodu,
              Tip: 5,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.data.isSucceded) {
            const dovizAlisValue = response.data.value[0]?.doviz_Alis;
            if (dovizAlisValue !== undefined) {
              setDovizAlis(dovizAlisValue);
            }
          }
        } catch (error) {
          console.error("API isteği hatası:", error);
        }
      }
    }
  };

  const handleSearchRowDoubleClick = (event: any) => {
    const record = event.dataItem;
    const birthdate = record["Dogum Tarihi"]
      ? new Date(record["Dogum Tarihi"])
      : null;
    const age = birthdate ? calculateAge(birthdate) : "";

    console.log(
      `Double clicked. Active input index at double click: ${activeInputIndex.current}`
    );

    const newSelectedRecords = [...selectedRecords];
    if (activeInputIndex.current !== null) {
      newSelectedRecords[activeInputIndex.current] = {
        ...record,
        DogumTarihi: birthdate,
        Yas: age,
      };
      setSelectedRecords(newSelectedRecords);

      const newSearchTerms = [...searchTerms];
      newSearchTerms[activeInputIndex.current] = {
        ad: record.Ad,
        soyad: record.Soyad,
      };
      setSearchTerms(newSearchTerms);

      // Focus on the corresponding input
      if (inputRefs.current[activeInputIndex.current]) {
        inputRefs.current[activeInputIndex.current]?.focus();
        console.log(`Focused on input index: ${activeInputIndex.current}`);
      } else {
        console.error(
          `No input ref found for index: ${activeInputIndex.current}`
        );
      }
    } else {
      console.error("No active input index found.");
    }
  };

  const fillInputsFromDataGrid = (data: any, index: number) => {
    const birthdate = data["Dogum Tarihi"]
      ? new Date(data["Dogum Tarihi"])
      : null;
    const age = birthdate ? calculateAge(birthdate) : "";

    console.log(`Filling inputs from datagrid for index: ${index}`);

    const newSelectedRecords = [...selectedRecords];
    newSelectedRecords[index] = {
      ...data,
      DogumTarihi: birthdate,
      Yas: age,
    };
    setSelectedRecords(newSelectedRecords);

    const newSearchTerms = [...searchTerms];
    newSearchTerms[index] = {
      ad: data.Ad,
      soyad: data.Soyad,
    };
    setSearchTerms(newSearchTerms);

    // Focus on the corresponding input
    if (inputRefs.current[index]) {
      inputRefs.current[index]?.focus();
      console.log(`Focused on input index: ${index}`);
    } else {
      console.error(`No input ref found for index: ${index}`);
    }
  };

  const handleInputFocus = (index: number) => {
    activeInputIndex.current = index;
    console.log(`Currently focused input index: ${index}`);
  };

  const calculateAge = (birthdate: Date) => {
    const today = new Date();
    const age = today.getFullYear() - birthdate.getFullYear();
    const monthDiff = today.getMonth() - birthdate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthdate.getDate())
    ) {
      return age - 1;
    }
    return age;
  };

  const handleSearchChange = debounce(async (index: number) => {
    const { ad, soyad } = searchTerms[index];

    if (ad.length < 3 && soyad.length < 3) return;

    const dbId = localStorage.getItem("selectedDbId");
    const requestBody = {
      db_Id: dbId,
      Ad: ad,
      Soyad: soyad,
      tip: 5,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    setSearchLoading(true);

    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Previl/Getir_Kod",
        requestBody,
        { headers }
      );
      if (response.data.isSucceded) {
        setSearchResults(response.data.value);
      } else {
        console.error("Veri çekme hatası:", response.data.message);
      }
    } catch (error) {
      console.error("API çağrısı hatası:", error);
    } finally {
      setSearchLoading(false);
    }
  }, 500);

  const handleAcentaFilterChange = (event: any) => {
    if (!event.filter.value) {
      setAcentaFiltered(acenta);
      return;
    }
    const filter = event.filter.value.toLowerCase();
    setAcentaFiltered(
      acenta.filter((item) => item.Adi.toLowerCase().includes(filter))
    );
  };

  const handleAcentaComboBoxChange = (event: any) => {
    setAcentaValue(event.value);
    handleAcentaFilterChange({ filter: { value: event.value } });
  };

  // const processedData = process(data, dataState);

  const [acenta, setAcenta] = useState<any[]>([]);
  const [acentaFiltered, setAcentaFiltered] = useState<any[]>([]);
  const [acentaValue, setAcentaValue] = useState("");

  const [konaklama, setKonaklama] = useState<any[]>([]);
  const [konaklamaFiltered, setKonaklamaFiltered] = useState<any[]>([]);
  const [konaklamaValue, setKonaklamaValue] = useState("");

  const [konum, setKonum] = useState<any[]>([]);
  const [konumFiltered, setKonumFiltered] = useState<any[]>([]);
  const [konumValue, setKonumValue] = useState("");

  const [ulkeKodu, setUlkeKodu] = useState<any[]>([]);
  const [ulkeKoduFiltered, setUlkeKoduFiltered] = useState<any[]>([]);
  const [ulkeKoduValue, setUlkeKoduValue] = useState("");

  const [kurKodu, setKurKodu] = useState<any[]>([]);
  const [kurKoduFiltered, setKurKoduFiltered] = useState<any[]>([]);
  const [kurKoduValue, setKurKoduValue] = useState("");

  const [satisSekli, setSatisSekli] = useState<any[]>([]);
  const [satisSekliFiltered, setSatisSekliFiltered] = useState<any[]>([]);
  const [satisSekliValue, setSatisSekliValue] = useState("");

  const [vipKodu, setVipKodu] = useState<any[]>([]);
  const [vipKoduFiltered, setVipKoduFiltered] = useState<any[]>([]);
  const [vipKoduValue, setVipKoduValue] = useState("");

  const [pazarKodu, setPazarKodu] = useState<any[]>([]);
  const [pazarKoduFiltered, setPazarKoduFiltered] = useState<any[]>([]);
  const [pazarKoduValue, setPazarKoduValue] = useState("");

  const [musteriTipi, setMusteriTipi] = useState<any[]>([]);
  const [musteriTipiFiltered, setMusteriTipiFiltered] = useState<any[]>([]);
  const [musteriTipiValue, setMusteriTipiValue] = useState("");

  const [odemeSekli, setOdemeSekli] = useState<any[]>([]);
  const [odemeSekliFiltered, setOdemeSekliFiltered] = useState<any[]>([]);
  const [odemeSekliValue, setOdemeSekliValue] = useState("");

  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!isModalOpen);

  const fetchDonBuroCalismaTarihi = async () => {
    const dbId = localStorage.getItem("selectedDbId");
    const requestBody = {
      db_Id: dbId ? parseInt(dbId) : 9,
      Kod: 1,
      tip: 1,
    };

    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Fishrk/Getir_Kod",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.isSucceded) {
        const curtar = new Date(response.data.value.Curtar);
        setGirisTarihi(curtar);
        setCikisTarihi(
          new Date(curtar.getTime() + geceleme * 24 * 60 * 60 * 1000)
        );
      } else {
        console.error("Veri çekme hatası:", response.data.message);
      }
    } catch (error) {
      console.error("API isteği hatası:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchAcenta();
    fetchIcerdekiler();
    fetchDonBuroCalismaTarihi();
    setSelectedRIH("checkin");
  }, []);

  useEffect(() => {
    if (girisTarihi) {
      const newCikisTarihi = new Date(
        girisTarihi.getTime() + geceleme * 24 * 60 * 60 * 1000
      );
      setCikisTarihi(newCikisTarihi);
    }
  }, [geceleme, girisTarihi]);
  const handleOnTarafGecelemeChange = (e: any) => {
    const value = e.value;
    setGeceleme(value);
    if (girisTarihi) {
      const newCikisTarihi = new Date(
        girisTarihi.getTime() + value * 24 * 60 * 60 * 1000
      );
      setCikisTarihi(newCikisTarihi);
    }
  };

  const handleOnTarafCikisTarihiChange = (e: any) => {
    const newCikisTarihi = e.value;
    setCikisTarihi(newCikisTarihi);
    if (girisTarihi && newCikisTarihi) {
      const timeDiff = newCikisTarihi.getTime() - girisTarihi.getTime();
      const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      setGeceleme(dayDiff);
    }
  };

  const handleOnTarafGirisTarihiChange = (e: any) => {
    const newGirisTarihi = e.value;
    setGirisTarihi(newGirisTarihi);
    if (newGirisTarihi && cikisTarihi) {
      const timeDiff = cikisTarihi.getTime() - newGirisTarihi.getTime();
      const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      setGeceleme(dayDiff);
    }
  };

  const handleGecelemeChange = (e: any) => {
    const value = e.value;
    setGeceleme(value);
  };

  const fetchData = async () => {
    const dbid = localStorage.getItem("selectedDbId");
    const requestBody = {
      db_Id: dbid,
      Sinif: "39,05,04,02,10,84,06,25,09,10",
      Kod: "",
      Tip: 888,
    };
    const headers = {
      "Content-Type": "application/json",
      // Token'i localStorage'den al ve Bearer token olarak ekle
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Kodlar/Getir_Kod",
        requestBody,
        { headers }
      );
      if (response.data.isSucceded) {
        const value = response.data.value;
        setKonaklama(value.filter((item: any) => item.Sinif === "39"));
        setKonaklamaFiltered(value.filter((item: any) => item.Sinif === "39"));

        setKonum(value.filter((item: any) => item.Sinif === "05"));
        setKonumFiltered(value.filter((item: any) => item.Sinif === "05"));

        setUlkeKodu(value.filter((item: any) => item.Sinif === "04"));
        setUlkeKoduFiltered(value.filter((item: any) => item.Sinif === "04"));

        setKurKodu(value.filter((item: any) => item.Sinif === "02"));
        setKurKoduFiltered(value.filter((item: any) => item.Sinif === "02"));

        setSatisSekli(value.filter((item: any) => item.Sinif === "84"));
        setSatisSekliFiltered(value.filter((item: any) => item.Sinif === "84"));

        setVipKodu(value.filter((item: any) => item.Sinif === "06"));
        setVipKoduFiltered(value.filter((item: any) => item.Sinif === "06"));

        setPazarKodu(value.filter((item: any) => item.Sinif === "25"));
        setPazarKoduFiltered(value.filter((item: any) => item.Sinif === "25"));

        setMusteriTipi(value.filter((item: any) => item.Sinif === "09"));
        setMusteriTipiFiltered(
          value.filter((item: any) => item.Sinif === "09")
        );

        setOdemeSekli(value.filter((item: any) => item.Sinif === "10"));
        setOdemeSekliFiltered(value.filter((item: any) => item.Sinif === "10"));
      }
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    }
  };

  const fetchAcenta = async () => {
    const dbid = localStorage.getItem("selectedDbId");
    const requestBody = {
      db_Id: dbid,
      Kod: "ALL",
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Acenta/Getir_Kod",
        requestBody,
        { headers }
      );
      if (response.data.isSucceded) {
        const value = response.data.value;
        // console.log("Acenta verileri:", value); // Hata ayıklama için log
        const filteredValue = value.filter(
          (item: { Adi: string }) => item.Adi != null && item.Adi !== undefined
        );
        setAcenta(filteredValue);
        setAcentaFiltered(filteredValue);
      }
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    }
  };

  const fetchIcerdekiler = async () => {
    const dbId = localStorage.getItem("selectedDbId");
    const requestBody = {
      db_Id: dbId,
      xtip: 1,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    setIcerdekilerLoading(true);

    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Procedure/StpRez_Bul2",
        requestBody,
        { headers }
      );
      if (response.data.isSucceded) {
        setIcerdekilerData(response.data.value);
      } else {
        console.error("Veri çekme hatası:", response.data.message);
      }
    } catch (error) {
      console.error("API çağrısı hatası:", error);
    } finally {
      setIcerdekilerLoading(false);
    }
  };

  const onIcerdekilerGridDataStateChange = (e: any) => {
    setIcerdekilerDataState(e.dataState);
  };

  const handleDoubleClick = (event: any) => {
    const selectedDataItem = event.dataItem;
    const odaNo = selectedDataItem.Oda;
    setSelectedOdaNo(odaNo);
    setOdaNoInput(odaNo);
    setModalOpen(false);
    // if (
    //   window.confirm(
    //     `${odaNo} numaralı oda blokaja ekleniyor. Onaylıyor musunuz?`
    //   )
    // ) {

    // }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row"></div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 m-0 p-0">
            <WalkInOdalarModal
              openModal={toggleModal}
              isOpen={isModalOpen}
              onDoubleClick={handleDoubleClick}
              CikisTarihi={cikisTarihi} // Çıkış tarihini prop olarak gönderiyoruz
            />
            <Tabs
              defaultActiveKey="WalkInGirisIslemleri"
              id="uncontrolled-tab-example"
            >
              <Tab
                eventKey="WalkInGirisIslemleri"
                title="Walk-In Giriş İşlemleri"
              >
                <div className="container-fluid m-0 p-0">
                  <div className="row">
                    <div className="col-md-12 d-flex align-items-center">
                      <div className="col-md-12 p-0">
                        <UstMenuBar align="left">
                          <div className="d-flex align-items-center">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="option"
                                id="rezervasyon"
                                value="rezervasyon"
                                checked={selectedRIH === "rezervasyon"}
                                onChange={handleRIHChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rezervasyon"
                              >
                                Rezervasyon
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="option"
                                id="checkin"
                                value="checkin"
                                checked={selectedRIH === "checkin"}
                                onChange={handleRIHChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="checkin"
                              >
                                Walk-In Check-In
                              </label>
                            </div>
                          </div>
                          <GeneralButton
                            text="Yazdır"
                            height="25px"
                            icon={faPrint}
                            gradientStart="#E65100"
                            gradientEnd="#FB8C00"
                            hoverGradientStart="#EF6C00"
                            hoverGradientEnd="#FFA726"
                            onClick={() => {
                              // yazdırma işlemi
                              console.log("Yazdır butonuna tıklandı");
                            }}
                          />
                          <GeneralButton
                            text="Onayla"
                            height="25px"
                            icon={faCheckCircle}
                            gradientStart="#1B5E20"
                            gradientEnd="#388E3C"
                            hoverGradientStart="#2E7D32"
                            hoverGradientEnd="#43A047"
                            onClick={handleWalkInTamamla}
                          />
                        </UstMenuBar>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container-fluid my-2">
                  <div className="row">
                    <div className="col-md-12 m-0 p-0">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-3 m-0 p-0">
                            {/* <IcBaslik text="Ana Kayıtlar" /> */}
                            <Card>
                              <Card.Header>
                                <Card.Title>Filtreler</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <div className="d-flex align-items-center justify-content-Start mt-1">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Giriş Tarihi
                                  </span>
                                  <DatePicker
                                    className="w-60"
                                    value={girisTarihi}
                                    format="dd/MM/yyyy"
                                    onChange={handleOnTarafGirisTarihiChange}
                                    disabled={
                                      selectedRIH === "rezervasyon"
                                        ? false
                                        : true
                                    }
                                  />
                                </div>
                                <div className="d-flex align-items-center justify-content-Start mt-1">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Geceleme
                                  </span>
                                  <NumericTextBox
                                    className="w-60"
                                    value={geceleme}
                                    defaultValue={1}
                                    onChange={handleOnTarafGecelemeChange}
                                  />
                                </div>
                                <div className="d-flex align-items-center justify-content-Start mt-1">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Çıkış Tarihi
                                  </span>
                                  <DatePicker
                                    className="w-60"
                                    value={cikisTarihi}
                                    format="dd/MM/yyyy"
                                    onChange={handleOnTarafCikisTarihiChange}
                                  />
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Acenta
                                  </div>
                                  <div className="w-60 ">
                                    <ComboBox
                                      data={acentaFiltered.map(
                                        (item) => item.Adi
                                      )}
                                      value={acentaValue}
                                      filterable={true}
                                      onFilterChange={handleAcentaFilterChange}
                                      onChange={handleAcentaComboBoxChange}
                                      // placeholder="Acenta Seçimi"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start mt-1">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Kişi Sayısı
                                  </span>
                                  <NumericTextBox
                                    className="w-60"
                                    onChange={(e) => {
                                      if (typeof e.value === "number") {
                                        setKisiSayisiValue(e.value);
                                      }
                                    }}
                                  ></NumericTextBox>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start mt-1">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Yet. / Çoc. / Free{" "}
                                  </span>
                                  <NumericTextBox
                                    className="w-20 ms-1"
                                    defaultValue={0}
                                    // value={0}
                                    onChange={(e) => {
                                      if (typeof e.value === "number") {
                                        setYetValue(e.value);
                                      }
                                    }}
                                  ></NumericTextBox>
                                  <NumericTextBox
                                    className="w-20 ms-1"
                                    defaultValue={0}
                                    // value={0}
                                    onChange={(e) => {
                                      if (typeof e.value === "number") {
                                        setCocValue(e.value);
                                      }
                                    }}
                                  ></NumericTextBox>
                                  <NumericTextBox
                                    className="w-20 ms-1"
                                    defaultValue={0}
                                    // value={0}
                                    onChange={(e) => {
                                      if (typeof e.value === "number") {
                                        setFreeValue(e.value);
                                      }
                                    }}
                                  ></NumericTextBox>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Konaklama
                                  </div>
                                  <div className="w-60 ">
                                    <ComboBox
                                      data={konaklamaFiltered.map(
                                        (item) => item.Ad
                                      )}
                                      value={konaklamaValue}
                                      filterable={true}
                                      // placeholder="Konaklama Tipi Seçimi"
                                      onFilterChange={(e) =>
                                        handleFilterChange(
                                          setKonaklamaFiltered,
                                          konaklama,
                                          e
                                        )
                                      }
                                      onChange={(e) =>
                                        handleComboBoxChange(
                                          setKonaklamaValue,
                                          setKonaklamaFiltered,
                                          konaklama,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start mt-1">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Oda No
                                  </span>
                                  <Input
                                    className="w-40"
                                    type="text"
                                    // placeholder="Oda No"
                                    value={odaNoInput}
                                    onChange={(e) => {
                                      if (typeof e.target.value === "string") {
                                        setOdaNoInput(e.target.value);
                                      }
                                    }}
                                  />
                                  <div
                                    className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md w-20 ms-1"
                                    onClick={toggleModal}
                                  >
                                    <FontAwesomeIcon icon={faSearch} /> Bul
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Konum
                                  </div>
                                  <div className="w-60 ">
                                    <ComboBox
                                      data={konumFiltered.map(
                                        (item) => item.Ad
                                      )}
                                      value={konumValue}
                                      // placeholder="Konum Seçimi"
                                      filterable={true}
                                      onFilterChange={(e) =>
                                        handleFilterChange(
                                          setKonumFiltered,
                                          konum,
                                          e
                                        )
                                      }
                                      onChange={(e) =>
                                        handleComboBoxChange(
                                          setKonumValue,
                                          setKonumFiltered,
                                          konum,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Ülke Kodu
                                  </div>
                                  <div className="w-60 ">
                                    <ComboBox
                                      data={ulkeKoduFiltered.map(
                                        (item) => item.Ad
                                      )}
                                      value={ulkeKoduValue}
                                      filterable={true}
                                      // placeholder="Ülke Seçimi"
                                      onFilterChange={(e) =>
                                        handleFilterChange(
                                          setUlkeKoduFiltered,
                                          ulkeKodu,
                                          e
                                        )
                                      }
                                      onChange={(e) =>
                                        handleComboBoxChange(
                                          setUlkeKoduValue,
                                          setUlkeKoduFiltered,
                                          ulkeKodu,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Kur Kodu
                                  </div>
                                  <div className="w-60 ">
                                    <ComboBox
                                      data={kurKoduFiltered.map(
                                        (item) => item.Ad
                                      )}
                                      value={kurKoduValue}
                                      filterable={true}
                                      // placeholder="Kur Kodu Seçimi"
                                      onFilterChange={(e) =>
                                        handleFilterChange(
                                          setKurKoduFiltered,
                                          kurKodu,
                                          e
                                        )
                                      }
                                      onChange={onKurKoduChange}
                                    />
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Manuel / Oto
                                  </div>
                                  <div className="w-60 ">
                                    {/* <DropDownList
                                      data={["M ", "O"]}
                                      defaultValue="M"
                                    /> */}
                                    <ComboBox
                                      data={["M", "O"]}
                                      defaultValue={"M"}
                                    />
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Tablo Kodu
                                  </div>
                                  <div className="w-60 ">
                                    {/* <DropDownList
                                      data={["Tablo Kodu", "BB"]}
                                      defaultValue="Tablo Kodu"
                                    /> */}
                                    <ComboBox
                                      data={["Tablo Kodu", "BB"]}
                                      defaultValue={"Tablo Kodu"}
                                    />
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Döviz Şekli
                                  </div>
                                  <div className="w-60 ">
                                    {/* <DropDownList
                                      data={["DA", "DS", "EA", "ES"]}
                                      defaultValue="DA"
                                      onChange={(e) =>
                                        setDovizSekliValue(e.target.value)
                                      }
                                    /> */}
                                    <ComboBox
                                      data={["DA", "DS", "EA", "ES"]}
                                      defaultValue={"DA"}
                                      onChange={(e) =>
                                        setDovizSekliValue(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                          <div className="col-md-3">
                            {/* <div
                              className="p-2" // React'te `class` yerine `className` kullanılır.
                              style={{
                                backgroundColor: "#255588", // CamelCase kullanımı
                                color: "white",
                                fontWeight: "bold", // CamelCase kullanımı
                                border: "1px solid #255588",
                              }}
                            >
                              Fiyatlar
                            </div> */}
                            {/* <IcBaslik text="Fiyatlar" /> */}
                            <Card>
                              <CardHeader>
                                <Card.Title>Fiyatlar</Card.Title>
                              </CardHeader>
                              <CardBody>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Ödeme Şekli
                                  </div>
                                  <div className="w-60 ">
                                    <ComboBox
                                      data={odemeSekliFiltered.map(
                                        (item) => item.Ad
                                      )}
                                      value={odemeSekliValue}
                                      filterable={true}
                                      // placeholder="Ödeme Şekli Seçimi"
                                      onFilterChange={(e) =>
                                        handleFilterChange(
                                          setOdemeSekliFiltered,
                                          odemeSekli,
                                          e
                                        )
                                      }
                                      onChange={(e) =>
                                        handleComboBoxChange(
                                          setOdemeSekliValue,
                                          setOdemeSekliFiltered,
                                          odemeSekli,
                                          e
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Kur (G/A)
                                  </div>
                                  <div className="w-30 ms-1">
                                    {/* <DropDownList
                                      data={["G", "A", "K"]}
                                      defaultValue="G"
                                      onChange={(e) =>
                                        setGunlukAylikValue(e.target.value)
                                      }
                                    /> */}
                                    <ComboBox
                                      data={["G", "A", "K"]}
                                      defaultValue={"G"}
                                      onChange={(e) =>
                                        setGunlukAylikValue(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                      value={dovizAlis}
                                      readOnly
                                    ></Input>
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Toplam Fiyat
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                      value={firstInputValue}
                                      onChange={(e) => {
                                        if (
                                          typeof e.target.value === "string"
                                        ) {
                                          setFirstInputValue(e.target.value);
                                        }
                                      }}
                                      onKeyDown={onFirstInputKeyDown}
                                    ></Input>
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                      value={secondInputValue}
                                      readOnly
                                    ></Input>
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Oda
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Kahvaltı
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Öğlen
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Akşam
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    İçecek
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Yiyecek
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Ek Yatak
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                    ></Input>
                                  </div>
                                </div>
                                <div className="w-100 d-flex mt-1">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Toplam Tutar
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                      value={toplamTutar}
                                      readOnly
                                      style={{
                                        backgroundColor: "#FFCC99",
                                      }}
                                    ></Input>
                                  </div>
                                  <div className="w-30 ms-1">
                                    <Input
                                      type="text"
                                      placeholder="00000"
                                      style={{
                                        backgroundColor: "#FF6A6A",
                                      }}
                                    ></Input>
                                  </div>
                                </div>
                                <div className="div w-100 mt-1">
                                  <TextArea />
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                          <div className="col-md-6 pr-0 m-0 p-0">
                            {/* <div
                              className="p-2" // React'te `class` yerine `className` kullanılır.
                              style={{
                                backgroundColor: "#255588", // CamelCase kullanımı
                                color: "white",
                                fontWeight: "bold", // CamelCase kullanımı
                                border: "1px solid #255588",
                              }}
                            >
                              İsimler
                            </div> */}
                            {/* <IcBaslik text="İsimler" /> */}
                            <Card>
                              <Card.Header>
                                <Card.Title>İsimler</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                {searchTerms.map((term: any, index: any) => (
                                  <div
                                    className="w-100 d-flex mt-1"
                                    key={index}
                                  >
                                    <div className="w-15">
                                      <DropDownList
                                        data={["Mrs", "Ms"]}
                                        defaultValue="Mrs"
                                      />
                                    </div>
                                    <div className="w-20 ms-1">
                                      <Input
                                        type="text"
                                        placeholder="Adı"
                                        value={term.ad}
                                        onChange={(e) => {
                                          const newSearchTerms =
                                            searchTerms.map(
                                              (item: any, i: any) =>
                                                i === index
                                                  ? {
                                                      ...item,
                                                      ad: e.target
                                                        .value as string,
                                                    }
                                                  : item
                                            );
                                          setSearchTerms(newSearchTerms);
                                        }}
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === "Enter" ||
                                            e.key === "Tab"
                                          ) {
                                            handleSearchChange(index);
                                          }
                                        }}
                                        onFocus={() => handleInputFocus(index)}
                                        ref={(el) =>
                                          (inputRefs.current[index] = el)
                                        }
                                      />
                                    </div>
                                    <div className="w-20 ms-1">
                                      <Input
                                        type="text"
                                        placeholder="Soyadı"
                                        value={term.soyad}
                                        onChange={(e) => {
                                          const newSearchTerms =
                                            searchTerms.map(
                                              (item: any, i: any) =>
                                                i === index
                                                  ? {
                                                      ...item,
                                                      soyad: e.target
                                                        .value as string,
                                                    }
                                                  : item
                                            );
                                          setSearchTerms(newSearchTerms);
                                        }}
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === "Enter" ||
                                            e.key === "Tab"
                                          ) {
                                            handleSearchChange(index);
                                          }
                                        }}
                                        onFocus={() => handleInputFocus(index)}
                                        ref={(el) =>
                                          (inputRefs.current[index] = el)
                                        }
                                      />
                                    </div>
                                    <DatePicker
                                      className="w-25 ms-1"
                                      value={
                                        selectedRecords[index]?.DogumTarihi ||
                                        null
                                      }
                                      format="yyyy-MM-dd"
                                      onChange={(e) => {
                                        const newSelectedRecords =
                                          selectedRecords.map(
                                            (item: any, i: any) =>
                                              i === index
                                                ? {
                                                    ...item,
                                                    DogumTarihi: e.value,
                                                  }
                                                : item
                                          );
                                        setSelectedRecords(newSelectedRecords);
                                      }}
                                    />
                                    <div className="w-10 ms-1">
                                      <Input
                                        type="text"
                                        placeholder="Yaş"
                                        value={
                                          selectedRecords[index]?.Yas || ""
                                        }
                                      />
                                    </div>
                                    <div className="w-10 ms-1">
                                      <Input
                                        type="text"
                                        placeholder="Müşteri No"
                                        value={selectedRecords[index]?.Id || ""}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </Card.Body>
                            </Card>
                            {/* <div
                              className="p-2 mt-1" // React'te `class` yerine `className` kullanılır.
                              style={{
                                backgroundColor: "#255588", // CamelCase kullanımı
                                color: "white",
                                fontWeight: "bold", // CamelCase kullanımı
                                border: "1px solid #255588",
                              }}
                            >
                              Detay
                            </div> */}
                            {/* <IcBaslik text="Detay" /> */}
                            <div className="div mt-1">
                              <Card>
                                <Card.Header>
                                  <Card.Title>Detay</Card.Title>
                                </Card.Header>
                                <CardBody>
                                  <div className="container-fluid p-0 m-0">
                                    <div className="row p-0 m-0">
                                      <div className="col-md-12 p-0 m-0">
                                        <div className="container-fluid p-0 m-0">
                                          <div className="row p-0 m-0">
                                            <div className="col md-5 p-0 m-0">
                                              <div className="w-100 d-flex mt-1">
                                                <div className="w-40 d-flex justify-content-start align-items-center">
                                                  Satış Şekli
                                                </div>
                                                <div className="w-60 ">
                                                  <ComboBox
                                                    data={satisSekliFiltered.map(
                                                      (item) => item.Ad
                                                    )}
                                                    value={satisSekliValue}
                                                    // placeholder="S. Şekli"
                                                    filterable={true}
                                                    onFilterChange={(e) =>
                                                      handleFilterChange(
                                                        setSatisSekliFiltered,
                                                        satisSekli,
                                                        e
                                                      )
                                                    }
                                                    onChange={(e) =>
                                                      handleComboBoxChange(
                                                        setSatisSekliValue,
                                                        setSatisSekliFiltered,
                                                        satisSekli,
                                                        e
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center justify-content-Start mt-1">
                                                <span className="w-40 d-flex justify-content-start align-items-center">
                                                  Rehber
                                                </span>
                                                <Input
                                                  className="w-60"
                                                  type="text"
                                                  // placeholder="Rehber"
                                                  // onChange={(e) => {
                                                  //   if (typeof e.target.value === 'string') {
                                                  //     setVoucherValue(e.target.value);
                                                  //   }
                                                  // }}
                                                ></Input>
                                              </div>
                                              <div className="w-100 d-flex mt-1">
                                                <div className="w-40 d-flex justify-content-start align-items-center">
                                                  VIP Kodu
                                                </div>
                                                <div className="w-60 ">
                                                  <ComboBox
                                                    data={vipKoduFiltered.map(
                                                      (item) => item.Ad
                                                    )}
                                                    value={vipKoduValue}
                                                    // placeholder="VIP Kod"
                                                    filterable={true}
                                                    onFilterChange={(e) =>
                                                      handleFilterChange(
                                                        setVipKoduFiltered,
                                                        vipKodu,
                                                        e
                                                      )
                                                    }
                                                    onChange={(e) =>
                                                      handleComboBoxChange(
                                                        setVipKoduValue,
                                                        setVipKoduFiltered,
                                                        vipKodu,
                                                        e
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="w-100 d-flex mt-1">
                                                <div className="w-40 d-flex justify-content-start align-items-center">
                                                  Pazar Kodu
                                                </div>
                                                <div className="w-60 ">
                                                  <ComboBox
                                                    data={pazarKoduFiltered.map(
                                                      (item) => item.Ad
                                                    )}
                                                    value={pazarKoduValue}
                                                    filterable={true}
                                                    // placeholder="Pazar Kodu"
                                                    onFilterChange={(e) =>
                                                      handleFilterChange(
                                                        setPazarKoduFiltered,
                                                        pazarKodu,
                                                        e
                                                      )
                                                    }
                                                    onChange={(e) =>
                                                      handleComboBoxChange(
                                                        setPazarKoduValue,
                                                        setPazarKoduFiltered,
                                                        pazarKodu,
                                                        e
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center justify-content-Start mt-1">
                                                <span className="w-40 d-flex justify-content-start align-items-center">
                                                  Voucher
                                                </span>
                                                <Input 
                                                  className="w-60"
                                                  type="text"
                                                  // placeholder="Voucher"
                                                  onChange={(e) => {
                                                    if (
                                                      typeof e.target.value ===
                                                      "string"
                                                    ) {
                                                      setVoucherValue(
                                                        e.target.value
                                                      );
                                                    }
                                                  }}
                                                ></Input>
                                              </div>
                                              <div className="w-100 d-flex mt-1">
                                                <div className="w-40 d-flex justify-content-start align-items-center">
                                                  Müşteri Tipi
                                                </div>
                                                <div className="w-60 ">
                                                  <ComboBox
                                                    data={musteriTipiFiltered.map(
                                                      (item) => item.Ad
                                                    )}
                                                    value={musteriTipiValue}
                                                    filterable={true}
                                                    // placeholder="Müşteri Tipi"
                                                    onFilterChange={(e) =>
                                                      handleFilterChange(
                                                        setMusteriTipiFiltered,
                                                        musteriTipi,
                                                        e
                                                      )
                                                    }
                                                    onChange={(e) =>
                                                      handleComboBoxChange(
                                                        setMusteriTipiValue,
                                                        setMusteriTipiFiltered,
                                                        musteriTipi,
                                                        e
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col md-2">
                                            <div className="w-100 d-flex mt-1">
                                                <div className="w-40 d-flex justify-content-start align-items-center">
                                                  Bebek Yatak
                                                </div>
                                                <div className="w-60 ">
                                                  <ComboBox
                                                    data={[0, 1, 2, 3, 4]}
                                                    // placeholder="Bebek Yatak"
                                                    defaultValue={0}
                                                  />
                                                </div>
                                              </div>
                                              <div className="w-100 d-flex mt-1">
                                                <div className="w-40 d-flex justify-content-start align-items-center">
                                                  0 - 2
                                                </div>
                                                <div className="w-60 ">
                                                  {/* <DropDownList
                                                    data={["0 - 2", "BB"]}
                                                    defaultValue="0 - 2"
                                                  /> */}
                                                  <ComboBox
                                                    data={[0, 1, 2, 3, 4]}
                                                    // placeholder="0 - 2 "
                                                    defaultValue={0}
                                                  />
                                                </div>
                                              </div>
                                              <div className="w-100 d-flex mt-1">
                                                <div className="w-40 d-flex justify-content-start align-items-center">
                                                  3 - 6
                                                </div>
                                                <div className="w-60 ">
                                                  <ComboBox
                                                    data={[0, 1, 2, 3, 4]}
                                                    // placeholder="3 - 6"
                                                    defaultValue={0}
                                                  />
                                                </div>
                                              </div>
                                              <div className="w-100 d-flex mt-1">
                                                <div className="w-40 d-flex justify-content-start align-items-center">
                                                  7 - 10
                                                </div>
                                                <div className="w-60 ">
                                                  <ComboBox
                                                    data={[0, 1, 2, 3, 4]}
                                                    // placeholder="7 - 10"
                                                    defaultValue={0}
                                                  />
                                                </div>
                                              </div>
                                              <div className="w-100 d-flex mt-1">
                                                <div className="w-40 d-flex justify-content-start align-items-center">
                                                  11 - 14
                                                </div>
                                                <div className="w-60 ">
                                                  <ComboBox
                                                    data={[0, 1, 2, 3, 4]}
                                                    // placeholder="11 - 14"
                                                    defaultValue={0}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col md-5 p-0">
                                              <div className="d-flex align-items-center justify-content-Start mt-1">
                                                <span className="w-40 d-flex justify-content-start align-items-center">
                                                  Plaka
                                                </span>
                                                <Input
                                                  className="w-60"
                                                  type="text"
                                                  // placeholder="Plaka"
                                                ></Input>
                                              </div>
                                              <div className="d-flex align-items-center justify-content-Start mt-1">
                                                <span className="w-40 d-flex justify-content-start align-items-center">
                                                  Rez. Yapan
                                                </span>
                                                <Input
                                                  className="w-60"
                                                  type="text"
                                                  // placeholder="Rez. Yapan"
                                                ></Input>
                                              </div>
                                              <div className="d-flex align-items-center justify-content-Start mt-1">
                                                <span className="w-40 d-flex justify-content-start align-items-center">
                                                  İrtibat Tel.
                                                </span>
                                                <Input
                                                  className="w-60"
                                                  type="text"
                                                  // placeholder="İrtibat Tel."
                                                ></Input>
                                              </div>
                                              <div className="w-100 d-flex mt-1">
                                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                                  Mus. Lis. E/H
                                                </div>
                                                <div className="w-60 ">
                                                  {/* <DropDownList
                                                    data={["E", "H"]}
                                                    defaultValue="E"
                                                    onChange={(e) =>
                                                      setMuslistEHValue(
                                                        e.target.value
                                                      )
                                                    }
                                                  /> */}
                                                  <ComboBox
                                                    data={["E", "H"]}
                                                    defaultValue={"E"}
                                                    onChange={(e) =>
                                                      setMuslistEHValue(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="w-100 d-flex mt-1">
                                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                                  Polis E/H
                                                </div>
                                                <div className="w-60 ">
                                                  {/* <DropDownList
                                                    data={["E", "H"]}
                                                    defaultValue="E"
                                                  /> */}
                                                  <ComboBox
                                                    data={["E", "H"]}
                                                    defaultValue={"E"}
                                                    // onChange={(e) =>
                                                    //   setMuslistEHValue(
                                                    //     e.target.value
                                                    //   )
                                                    // }
                                                  />
                                                </div>
                                              </div>
                                              <div className="w-100 d-flex mt-1">
                                                <div className=" w-40 d-flex justify-content-start align-items-center">
                                                  Source / Ch
                                                </div>
                                                <div className="w-30 ms-1">
                                                  {/* <DropDownList
                                                    data={["E", "H"]}
                                                    defaultValue="E"
                                                  /> */}
                                                  <ComboBox />
                                                </div>
                                                <div className="w-30 ms-1">
                                                  {/* <DropDownList
                                                    data={["E", "H"]}
                                                    defaultValue="E"
                                                  /> */}
                                                  <ComboBox />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-fluid mt-1 p-0 m-0">
                        {/* <div
                            className="p-2" // React'te `class` yerine `className` kullanılır.
                            style={{
                              backgroundColor: "#255588", // CamelCase kullanımı
                              color: "white",
                              fontWeight: "bold", // CamelCase kullanımı
                              border: "1px solid #255588",
                            }}
                          >
                            Yardımlar
                          </div> */}
                        <div className="div mt-1">
                          {/* <IcBaslik text="Yardımlar" /> */}
                          <Card>
                            <CardHeader>
                              <Card.Title>Yardımlar</Card.Title>
                            </CardHeader>
                            <CardBody>
                              <div className="row m-0 p-0">
                                <div className="col-md-2 m-0 p-0">
                                  <div className="text-start">
                                    <Checkbox
                                      label={
                                        "Kimlik Bilgileri Formu Girişten Sonra Gelsin"
                                      }
                                      color="red"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-10 p-0">
                                  <div className="mt-1">
                                    {isSearchLoading ? (
                                      <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: "250px" }}
                                      >
                                        <Spinner
                                          style={{
                                            width: "3rem",
                                            height: "3rem",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <Grid
                                        data={searchResults}
                                        pageable={true}
                                        pageSize={searchDataState.take}
                                        total={searchResults.length}
                                        groupable={false}
                                        filterable={false}
                                        sortable={true}
                                        onDataStateChange={
                                          onGridDataStateChange
                                        }
                                        {...searchDataState}
                                        reorderable={true}
                                        resizable={true}
                                        onRowDoubleClick={
                                          handleSearchRowDoubleClick
                                        }
                                        style={{ height: "250px" }}
                                      >
                                        <Column field="Ad" title="Adı" />
                                        <Column field="Soyad" title="Soyadı" />
                                        <Column
                                          field="Dogum Tarihi"
                                          title="Doğum Tarihi"
                                        />
                                        <Column field="Tc" title="TC" />
                                        <Column
                                          field="Adresilce"
                                          title="Adres İlçe"
                                        />
                                        <Column
                                          field="Adresil"
                                          title="Adres İl"
                                        />
                                        <Column field="Id" title="Id" />
                                        <Column field="Gelis" title="Geliş" />
                                      </Grid>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Icerdekiler" title="İçerdekiler (Inhouse Walk-In)">
                {isIcerdekilerLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "750px" }}
                  >
                    <Spinner style={{ width: "3rem", height: "3rem" }} />
                  </div>
                ) : (
                  <Grid
                    data={icerdekilerData}
                    pageable={true}
                    pageSize={icerdekilerDataState.take}
                    total={icerdekilerData.length}
                    groupable={false}
                    filterable={false}
                    sortable={true}
                    onDataStateChange={onIcerdekilerGridDataStateChange}
                    {...icerdekilerDataState}
                    reorderable={true}
                    resizable={true}
                    style={{ height: "750px" }}
                  >
                    <Column field="Id" title="ID" />
                    <Column field="Odano" title="Oda No" />
                    <Column field="Adı" title="Adı" />
                    <Column field="Soyadı" title="Soyadı" />
                    <Column field="Oda Sayısı" title="Oda Sayısı" />
                    <Column field="Yetişkin" title="Yetişkin" />
                    <Column field="Çocuk" title="Çocuk" />
                    <Column field="Free" title="Free" />
                    <Column field="Konaklama" title="Konaklama" />
                    <Column field="Odeme" title="Ödeme" />
                    <Column field="Acenta Adı" title="Acenta Adı" />
                    <Column field="Giriş Tarihi" title="Giriş Tarihi" />
                    <Column field="Geceleme" title="Geceleme" />
                    <Column field="Çıkış Tarihi" title="Çıkış Tarihi" />
                    <Column field="Istenen" title="İstenen" />
                    <Column field="Inh.Toplam" title="Toplam" />
                    <Column field="Kur" title="Kur" />
                    <Column field="Inh.TL.Toplam" title="TL Toplam" />
                  </Grid>
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalkIn;

interface AktiviteProps {
  openModal: () => void;
  data?: any[];
  isOpen: boolean;
  
  onDoubleClick: (event: any) => void;
  CikisTarihi?: Date | null;
}

const WalkInOdalarModal: React.FC<AktiviteProps> = ({
  openModal,
  data,
  isOpen,
  onDoubleClick,
  CikisTarihi,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState<any>(data || {});
  const [baslangicTarihi, setBaslangicTarihi] = useState<Date | null>(null);
  const [bitisTarihi, setBitisTarihi] = useState<Date | null>(null);
  const [geceleme, setGeceleme] = useState<number | null>(null);
  const [gridData, setGridData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
    setFormData({});
  };

  const handleRowDoubleClick = (event: any) => {
    onDoubleClick(event);
    handleModalClose(); // Modalı kapat
  };
  useEffect(() => {
    if (isOpen && CikisTarihi) {
      setBitisTarihi(CikisTarihi); // Modal açıldığında data propunu kullanarak bitiş tarihini ayarla
    }
  }, [isOpen, CikisTarihi]);

  const fetchDonBuroCalismaTarihi = async () => {
    const dbId = localStorage.getItem("selectedDbId");
    const requestBody = {
      db_Id: dbId ? parseInt(dbId) : 9,
      Kod: 1,
      tip: 1,
    };

    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Fishrk/Getir_Kod",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.isSucceded) {
        const curtar = new Date(response.data.value.Curtar);
        setBaslangicTarihi(curtar);
      } else {
        console.error("Veri çekme hatası:", response.data.message);
      }
    } catch (error) {
      console.error("API isteği hatası:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      openModal();
      setFormData(data || {}); // Veri geldiğinde form data güncellenir
      fetchDonBuroCalismaTarihi();
      setModalOpen(true);
    }
  }, [isOpen, data, openModal]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleGoster = async () => {
    const dbid = localStorage.getItem("selectedDbId");
    setLoading(true);
    const requestBody = {
      db_Id: dbid,
      xRoomhrk_Sirket: 0,
      xRoomhrk_Bas_tar: baslangicTarihi
        ? baslangicTarihi.toISOString().split("T")[0]
        : null,
      xRoomhrk_Bit_tar: bitisTarihi
        ? bitisTarihi.toISOString().split("T")[0]
        : null,
      xRoomhrk_Konum: null,
      xRez_Geceleme: geceleme,
      xTum_Tek: 1,
      xtip: 2,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Procedure/StpRoomhrk_Bul",
        requestBody,
        { headers }
      );
      if (response.data.isSucceded) {
        setGridData(response.data.value);
      } else {
        console.error("Veri alınamadı:", response.data.message);
      }
    } catch (error) {
      console.error("API çağrısı hatası:", error);
    } finally {
      setLoading(false);
    }
  };

  const calculateGeceleme = (startDate: Date | null, endDate: Date | null) => {
    if (startDate && endDate) {
      const timeDiff = endDate.getTime() - startDate.getTime();
      const dayDiff = timeDiff / (1000 * 3600 * 24);
      return dayDiff;
    }
    return 1;
  };
  useEffect(() => {
    if (baslangicTarihi && bitisTarihi) {
      const calculatedGeceleme = calculateGeceleme(
        baslangicTarihi,
        bitisTarihi
      );
      setGeceleme(calculatedGeceleme);
    }
  }, [baslangicTarihi, bitisTarihi]);

  const cellRender = (tdElement: any, cellProps: any) => {
    if (cellProps.field === "Hk Durum") {
      const color = cellProps.dataItem["Rengi"];
      return (
        <td
          style={{
            backgroundColor: color,
            color: "black",
            fontWeight: "bold",
          }}
        >
          {cellProps.dataItem[cellProps.field]}
        </td>
      );
    }
    return tdElement;
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleModalClose}
      size="extra-large"
      header={<div>Boş Odalar</div>}
      footer={
        <>
          <GeneralButton
            text="Vazgeç"
            icon={faTimesCircle}
            gradientStart="#B71C1C"
            gradientEnd="#E53935"
            hoverGradientStart="#C62828"
            hoverGradientEnd="#F44336"
            onClick={() => {
              setModalOpen(false);
            }}
          />
        </>
      }
    >
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              {/* <IcBaslik text="Tarihler" /> */}
              <Card>
                <CardHeader>
                  <CardTitle>Tarihler</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="d-flex align-items-center justify-content-Start mt-1">
                          <span className="w-40 d-flex justify-content-start align-items-center">
                            Başlangıç Tarihi
                          </span>
                          <DatePicker
                            className="w-60"
                            value={baslangicTarihi}
                            onChange={(date) => setBaslangicTarihi(date.value)}
                            format="dd/MM/yyyy"
                            placeholder={""}
                            disabled={true}
                          />
                        </div>
                        <div className="d-flex align-items-center justify-content-Start mt-1">
                          <span className="w-40 d-flex justify-content-start align-items-center">
                            Bitiş Tarihi
                          </span>
                          <DatePicker
                            className="w-60"
                            defaultValue={bitisTarihi}
                            onChange={(date) => setBitisTarihi(date.value)}
                            format="dd/MM/yyyy"
                            placeholder=""
                          />
                        </div>
                        <div className="d-flex align-items-center justify-content-Start mt-1">
                          <span className="w-40 d-flex justify-content-start align-items-center">
                            Geceleme
                          </span>
                          <NumericTextBox
                            className="w-60"
                            value={geceleme}
                            onChange={(e) => setGeceleme(e.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 m-0 p-0">
                        {/* <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md mt-1">
                      <FontAwesomeIcon icon={faRefresh} />
                      Göster
                    </div> */}

                        <div className="div  mt-1 w-75">
                          <GeneralButton
                            icon={faEye}
                            text="Göster"
                            gradientStart="#1B5E20"
                            gradientEnd="#388E3C"
                            hoverGradientStart="#2E7D32"
                            hoverGradientEnd="#43A047"
                            onClick={handleGoster}
                            height="25px"
                          />
                        </div>

                        <div></div>
                        {/* <div className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md mt-1">
                      <FontAwesomeIcon icon={faRefresh} />
                      Aktar
                    </div> */}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-md-2">
              {/* <IcBaslik text="Konumlar" /> */}
              <Card>
                <CardHeader>
                  <CardTitle>Konumlar</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="w-100 d-flex mt-1">
                    <div className=" w-40 d-flex justify-content-start align-items-center">
                      Konum
                    </div>
                    <div className="w-60 ">
                      <MultiSelect data={["Acenta", "BB"]} />
                    </div>
                  </div>
                  <div className="w-100 d-flex mt-1">
                    <div className=" w-40 d-flex justify-content-start align-items-center">
                      Daire Tipi
                    </div>
                    <div className="w-60 ">
                      <MultiSelect data={["Acenta", "BB"]} />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-md-3">
              {/* <IcBaslik text="Tipler" /> */}
              <Card>
                <CardHeader>
                  <CardTitle>Tipler</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="w-100 d-flex mt-1">
                    <div className=" w-40 d-flex justify-content-start align-items-center">
                      Metre Kare (m2)
                    </div>
                    <div className="w-60 ">
                      <MultiSelect data={["Acenta", "BB"]} />
                    </div>
                  </div>
                  <div className="w-100 d-flex mt-1">
                    <div className=" w-40 d-flex justify-content-start align-items-center">
                      Dönemler
                    </div>
                    <div className="w-60 ">
                      <MultiSelect data={["Acenta", "BB"]} />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-md-2">
              {/* <IcBaslik text="Tipler" /> */}
              <Card>
                <CardHeader>
                  <CardTitle>Tipler</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="w-100 d-flex mt-1">
                    <div className=" w-40 d-flex justify-content-start align-items-center">
                      Aidat
                    </div>
                    <Input
                      className="w-60"
                      type="text"
                      placeholder="Aidat"
                    ></Input>
                  </div>
                  <div className="w-100 d-flex mt-1">
                    <div className=" w-40 d-flex justify-content-start align-items-center">
                      Ödenen
                    </div>
                    <Input
                      className="w-60"
                      type="text"
                      placeholder="Ödenen"
                    ></Input>
                  </div>
                  <div className="w-100 d-flex mt-1">
                    <div className=" w-40 d-flex justify-content-start align-items-center">
                      Kalan
                    </div>
                    <Input
                      className="w-60"
                      type="text"
                      placeholder="Kalan"
                    ></Input>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-9 d-flex flex-column">
              {/* <IcBaslik text="Boş Odalar Listesi" /> */}
              <Card>
                <CardHeader>
                  <CardTitle>Boş Odalar Listesi</CardTitle>
                </CardHeader>
                <CardBody>
                  {isLoading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "450px" }}
                    >
                      <Spinner style={{ width: "3rem", height: "3rem" }} />
                    </div>
                  ) : (
                    <div className="mt-1">
                      <Grid
                        data={gridData}
                        pageable={true}
                        groupable={false}
                        filterable={false}
                        sortable={true}
                        reorderable={true}
                        resizable={true}
                        style={{ height: "450px" }}
                        cellRender={cellRender}
                        rowHeight={40} // Satırların yüksekliğini artırır
                        // rowStyles={{ borderBottom: "1px solid #ddd" }} // Satırların arasına çizgi ekler
                        onRowDoubleClick={handleRowDoubleClick}
                      >
                        <Column field="Oda" title="Oda" />
                        <Column field="Konum" title="Konum" />
                        <Column field="Yatak" title="Yatak" />
                        <Column field="Hk Durum" title="Hk Durum" />
                        <Column field="Bos Gün" title="Devre Gün" />
                        <Column field="Katapart" title="Kat/Blok" />
                        <Column field="Room Remarks" title="Oda Notları" />
                        {/* <Column field="Rengi" title="Rengi" /> */}
                      </Grid>
                    </div>
                  )}
                </CardBody>
              </Card>
            </div>
            <div className="col-md-3 d-flex flex-column">
              {/* <IcBaslik text="Oda Detayı" /> */}
              <Card>
                <CardHeader>
                  <CardTitle>Oda Detayı</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="mt-1">
                    <Grid
                      data={gridData}
                      pageable={true}
                      groupable={false}
                      filterable={true}
                      sortable={true}
                      reorderable={true}
                      resizable={true}
                      style={{ height: "450px" }}
                    >
                      <Column field="Oda" title="Oda" />
                      <Column field="Konum" title="Konum" />
                      <Column field="Yatak" title="Yatak" />
                      <Column field="Hk Durum" title="Hk Durum" />
                      <Column field="Bos Gün" title="Boş Gün" />
                      <Column field="Katapart" title="Katapart" />
                      <Column field="Room Remarks" title="Room Remarks" />
                      <Column field="Rengi" title="Rengi" />
                    </Grid>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};
