import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCode,
  faCoffee,
  faDownload,
  faExclamationTriangle,
  faGauge,
  faList,
  faPrint,
  faRefresh,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Grid,
  GridColumn as Column,
  GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import Modal from "../../../../../Modal/Modal/Modal";
import {
  Checkbox,
  Input,
  NumericTextBox,
  RadioButton,
} from "@progress/kendo-react-inputs";
import { Card, Tab, Tabs } from "react-bootstrap";
import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  DropDownList,
} from "@progress/kendo-react-dropdowns";

import axios from "axios";
import {
  KontratGirisleriType,
  KontratGirisleriData,
  yeniNesne,
} from "./KontratGirisleriType";
import { useDbId } from "../../../../../../Context/LocalStrogeContext/OnburoDbContext";
import { RiCompass4Line } from "react-icons/ri";

const initialDataState = {
  take: 31,
  skip: 0,
  group: [],
  filter: null,
};

const KontratGirisleri: React.FC = () => {
  const [data, setData] = React.useState<any>([]);
  const [dataState, setDataState] = React.useState<any>(initialDataState);
  const [formData, setFormData] = useState<any>({}); // Form verilerini tutacak state
  const onGridDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };
  const [xpasif, setXpasif] = useState<boolean>(false); // Checkbox state
  const [isModalOpen, setModalOpen] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const KontratData = async () => {
    // Local storage'dan token değerini al
    const token = localStorage.getItem("token");
    const requestBody = {
      db_Id: 9,
      xAc_Sirket: 0,
      xAc_Kodu: "null",
      xTbl_Kodu: null,
      xtip: 1,
      xpasif: xpasif ? 1 : 0, // Checkbox değerine göre xpasif'i ayarla
      tblind_Id: 0,
      ac_Acenta_sirket_10: 0,
    };
    const headers = {
      "Content-Type": "application/json",
      // Token'i localStorage'den al ve Bearer token olarak ekle
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    try {
      const response = await axios.post(
        `https://frontapi.rmosweb.com/api/Procedure/StpAcenta_Bul`,
        requestBody,
        { headers }
      );

      setData(response.data.value);
      const dataLength = response.data.value.length; // Veri dizisinin uzunluğunu al
      setTotalCount(dataLength); // Toplam veri sayısını state'e ata
      console.log("API'den gelen veri:", response.data.value);
    } catch (error) {
      // İstek sırasında bir hata oluşursa, hatayı console'a yazdır
      console.error("API request failed:", error);
      throw error;
    }
  };
  useEffect(() => {
    KontratData();
  }, []);
  useEffect(() => {
    KontratData();
  }, [xpasif]); // xpasif değiştiğinde yeniden API isteği yap
  const processedData = process(data, dataState);

  const dataWithRatios = data.map((item: any) => ({
    ...item,
    // Ortalama: (
    //   (item["Bavel_ekle_yuzde"] || 0) / (item["Online_odeme_yuzde"] || 1)
    // ).toFixed(2),
  }));

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
    setFormData({}); // Tablodaki veriyi sıfırla
  }; // Ortalamaları hesapla
  // const toplamBavelEkleYuzde = data.reduce(
  //   (sum: number, item: { Bavel_ekle_yuzde?: number }) =>
  //     sum + (item["Bavel_ekle_yuzde"] || 0),
  //   0
  // );

  // const toplamOnlineOdemeYuzde = data.reduce(
  //   (sum: number, item: { Online_odeme_yuzde?: number }) =>
  //     sum + (item["Online_odeme_yuzde"] || 0),
  //   0
  // );
  // const bavelEkleYuzdeOrtalama = toplamBavelEkleYuzde / toplamOnlineOdemeYuzde;

  const columns = useMemo(() => {
    if (data.length === 0) {
      return [];
    }

    const keys = Object.keys(dataWithRatios[0]);
    const maxContentLengths = keys.map((key) => {
      const maxDataLength = Math.max(
        ...data.map((item: any) =>
          item[key] ? item[key].toString().length : 0
        )
      );
      const columnHeaderLength = key.length;
      return Math.max(maxDataLength, columnHeaderLength);
    });

    return keys.map((key, index) => {
      const baseWidth = 50;
      const characterWidth = data.every(
        (item: any) => !isNaN(Number(item[key]))
      )
        ? 7
        : 10;
      const padding = 20;
      const maxLength = maxContentLengths[index];
      const idealWidth = Math.min(
        Math.max(baseWidth, maxLength * characterWidth + padding),
        300
      );

      return (
        <Column
          field={key}
          title={key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}
          key={key}
          width={idealWidth}
          footerCell={() => {
            if (key === "Id") {
              return (
                <td
                  colSpan={keys.length}
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Toplam Veri Sayısı: {totalCount}
                </td>
              );
            }
            return <td></td>;
            //   if (key === "Bavel_ekle_yuzde")
            //     return <td>{toplamBavelEkleYuzde.toFixed(2)}</td>;
            //   if (key === "Online_odeme_yuzde")
            //     return <td>{toplamOnlineOdemeYuzde.toFixed(2)}</td>;
            //   if (key === "Ortalama")
            //     return <td>{bavelEkleYuzdeOrtalama.toFixed(2)}</td>;
            //   return <td></td>;
          }}
        />
      );
    });
  }, [data]); //toplamBavelEkleYuzde, toplamOnlineOdemeYuzde]);
  const handleRowDoubleClick = (rowData: any) => {
    console.log("Çift tıklanan satırın Ortalama dışındaki verileri:", rowData);
    if (
      rowData.Kodu === "" ||
      rowData.Kodu === undefined ||
      rowData.Kodu === null
    ) {
      alert("Acenta Kodu boş olamaz");
      return;
    }
    setFormData(rowData); // Ortalama hariç verileri formData state'ine aktar
    setModalOpen(true); // Modalı aç
  };

  // const rowRender = (trElement: any, props: any) => {
  //   const dataItem = props.dataItem;
  //   if (dataItem.Pasif) {
  //     const newProps = {
  //       style: {
  //         background: "linear-gradient(to right, rgba(211, 211, 211, 0.5), rgba(169, 169, 169, 0.5))", // Gri tonlarında transparan gradient arka plan
  //         color: "red", // Yazı rengi beyaz
  //         // fontWeight: "bold", // Yazı kalın
  //         // textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)", // Yazı gölgesi
  //         borderLeft: "5px solid rgba(128, 128, 128, 0.5)", // Sol kenar çizgisi transparan koyu gri
  //         borderRight: "5px solid rgba(128, 128, 128, 0.5)", // Sağ kenar çizgisi transparan koyu gri
  //       }
  //     };
  //     return React.cloneElement(trElement, newProps, trElement.props.children);
  //   }
  //   return trElement;
  // };
  const rowRender = (trElement: any, props: any) => {
    const dataItem = props.dataItem;
    if (dataItem.Pasif) {
      const newProps = {
        style: {
          background:
            "linear-gradient(to right, rgba(211, 211, 211, 0.5), rgba(169, 169, 169, 0.5))", // Gri tonlarında transparan gradient arka plan
          color: "red", // Yazı rengi beyaz
          // fontWeight: "bold", // Yazı kalın
          // textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)", // Yazı gölgesi
          borderLeft: "5px solid rgba(128, 128, 128, 0.5)", // Sol kenar çizgisi transparan koyu gri
          borderRight: "5px solid rgba(128, 128, 128, 0.5)", // Sağ kenar çizgisi transparan koyu gri
        },
      };

      return React.cloneElement(trElement, newProps, trElement.props.children);
    }
    return trElement;
  };

  return (
    <>
      <div className="pt-4">
        <div className="container-fluid">
          <KontratGirisi
            openModal={toggleModal}
            data={formData}
            isOpen={isModalOpen}
            KontratData={KontratData}
          />
          <div className="row gap-2">
            <div className="col-md-12 bg-white shadow-lg rounded p-3">
              <div className="d-flex flex-row gap-2">
                {[
                  {
                    icon: faRefresh,
                    text: "Yenile",
                    gradient: "linear-gradient(45deg, #0D47A1, #1976D2)",
                    hoverGradient: "linear-gradient(45deg, #1565C0, #1E88E5)",
                  },
                  {
                    icon: faAdd,
                    text: "Acenta Ekle Düzelt",
                    gradient: "linear-gradient(45deg, #1B5E20, #388E3C)",
                    hoverGradient: "linear-gradient(45deg, #2E7D32, #43A047)",
                  },
                  {
                    icon: faPrint,
                    text: "Yazdır",
                    gradient: "linear-gradient(45deg, #B71C1C, #E53935)",
                    hoverGradient: "linear-gradient(45deg, #C62828, #F44336)",
                  },
                  {
                    icon: faCode,
                    text: "Varsayılan Kodlar",
                    gradient: "linear-gradient(45deg, #E65100, #FB8C00)",
                    hoverGradient: "linear-gradient(45deg, #EF6C00, #FFA726)",
                  },
                  {
                    icon: faList,
                    text: "E-Mükellef",
                    gradient: "linear-gradient(45deg, #4A148C, #8E24AA)",
                    hoverGradient: "linear-gradient(45deg, #6A1B9A, #AB47BC)",
                  },
                  {
                    icon: faGauge,
                    text: "Yaş Opsiyonu",
                    gradient: "linear-gradient(45deg, #006064, #00ACC1)",
                    hoverGradient: "linear-gradient(45deg, #00838F, #00BCD4)",
                  },
                ].map((button, index) => (
                  <div key={index}>
                    <button
                      style={{
                        backgroundImage: button.gradient,
                        color: "white",
                        borderRadius: "4px",
                        border: "none",
                        padding: "6px 12px",
                        cursor: "pointer",
                        margin: "4px",
                        boxShadow: "4px 4px 12px rgba(0, 0, 0, 0.2)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        transition: "background-image 0.3s, box-shadow 0.3s",
                      }}
                      onMouseOver={(e) => {
                        const target = e.currentTarget as HTMLButtonElement;
                        target.style.backgroundImage = button.hoverGradient;
                        target.style.boxShadow =
                          "4px 4px 16px rgba(0, 0, 0, 0.3)";
                        const icon = target.querySelector(
                          ".icon-span"
                        ) as HTMLSpanElement;
                        if (icon) {
                          icon.style.animation = "spin 0.5s linear";
                        }
                      }}
                      onMouseOut={(e) => {
                        const target = e.currentTarget as HTMLButtonElement;
                        target.style.backgroundImage = button.gradient;
                        target.style.boxShadow =
                          "4px 4px 12px rgba(0, 0, 0, 0.2)";
                        const icon = target.querySelector(
                          ".icon-span"
                        ) as HTMLSpanElement;
                        if (icon) {
                          icon.style.animation = "none";
                        }
                      }}
                      onClick={
                        button.text === "Acenta Ekle Düzelt"
                          ? toggleModal
                          : undefined
                      }
                    >
                      {button.text}
                      <span
                        className="icon-span"
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                          borderRadius: "50%",
                          width: "24px",
                          height: "24px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <FontAwesomeIcon icon={button.icon} />
                      </span>
                    </button>
                  </div>
                ))}
                <div className="d-flex align-items-center">
                  <Checkbox
                    label="Pasifler de Gelsin"
                    checked={xpasif}
                    onChange={(e) => setXpasif(e.value)}
                  />
                </div>
              </div>
              <style>{`
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `}</style>
            </div>

            <div className="col-md-12 shadow-lg bg-white mt-3 rounded  ">
              <div className="p-2 my-3 ">
                <Grid
                  data={process(dataWithRatios, dataState)}
                  pageable={true}
                  pageSize={dataState.take}
                  total={data.length}
                  groupable={false}
                  filterable={true}
                  sortable={true}
                  onDataStateChange={onGridDataStateChange}
                  {...dataState}
                  reorderable={true}
                  resizable={true}
                  // style={{ height: "700px" }}

                  style={{
                    height: "650px",
                    // border: "2px solid rgba(0, 0, 255, 0.3)", // Şeffaf mavi çerçeve
                    // borderRadius: "5px", // Köşeleri yuvarla
                    // padding: "10px", // İçeriğe biraz boşluk ekle
                  }}
                  onRowDoubleClick={(e) => handleRowDoubleClick(e.dataItem)}
                  rowRender={rowRender} // Satır render fonksiyonu
                >
                  {columns}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default KontratGirisleri;

interface KontratGirisiProps {
  openModal: () => void; // openModal sadece bir fonksiyon olarak tanımlanmış
  data?: KontratGirisleriType[];
  isOpen: boolean; // Modalın açık olup olmadığı bilgisini dışarıdan alıyoruz
  KontratData: () => void;
}

const KontratGirisi: React.FC<KontratGirisiProps> = ({
  openModal,
  data,
  isOpen,
  KontratData,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState<any>(data || {});
  const [key, setKey] = useState("GenelBilgiler");
  const [satisNoktalari, setSatisNoktalari] = useState<any[]>([]);
  const [filteredSatisNoktalari, setFilteredSatisNoktalari] = useState<any[]>(
    []
  );

  const [kurKodlari, setKurKodlari] = useState<any[]>([]);
  const [filteredKurKodlari, setFilteredKurKodlari] = useState<any[]>([]);

  const [ulkeKodlari, setUlkeKodlari] = useState<any[]>([]);
  const [filteredUlkeKodlari, setFilteredUlkeKodlari] = useState<any[]>([]);

  const [musteriTipleri, setMusteriTipleri] = useState<any[]>([]);
  const [filteredMusteriTipleri, setFilteredMusteriTipleri] = useState<any[]>(
    []
  );

  const [odemeKodlari, setOdemeKodlari] = useState<any[]>([]);
  const [filteredOdemeKodlari, setFilteredOdemeKodlari] = useState<any[]>([]);

  const [acenteGrupKodlari, setAcenteGrupKodlari] = useState<any[]>([]);
  const [filteredAcenteGrupKodlari, setFilteredAcenteGrupKodlari] = useState<
    any[]
  >([]);

  const [pazarKodlari, setPazarKodlari] = useState<any[]>([]);
  const [filteredPazarKodlari, setFilteredPazarKodlari] = useState<any[]>([]);

  const [pansiyonKodlari, setPansiyonKodlari] = useState<any[]>([]);
  const [filteredPansiyonKodlari, setFilteredPansiyonKodlari] = useState<any[]>(
    []
  );
  const [data1, setData1] = useState<any[]>([]);
  const [kontratEkleData, setKontratEkleData] =
    useState<KontratGirisleriType>(KontratGirisleriData);
  const [acentlar, setAcentlar] = useState<any[]>([]);
  const [filteredAcentalar, setFilteredAcentalar] = useState<any[]>([]);
  const [filteredSource, setFilteredSource] = useState<any[]>([]);
  const [sourceData, setSourceData] = useState<any[]>([]);
  const [ilKodlari, setIlKodlari] = useState<any[]>([]);
  const [isInvalid, setIsInvalid] = useState<any>({
    Kodu: false,
    Adi: false,
    pazar: false,
    Grubu: false,
    MusteriTipi: false,
    KurKodu: false,
    Ulke: false,
    Pansiyon: false,
    Odeme: false,
  });
  const Acentalar = async () => {
    // Local storage'dan token değerini al
    const token = localStorage.getItem("token");
    const requestBody = {
      db_Id: 9,
      Kod: "ALL?",
    };
    const headers = {
      "Content-Type": "application/json",
      // Token'i localStorage'den al ve Bearer token olarak ekle
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    try {
      const response = await axios.post(
        `https://frontapi.rmosweb.com/api/Acenta/Getir_KodNoAuth`,
        requestBody,
        { headers }
      );
      const fa: any[] = response.data.value
        .filter(
          (acent: any) =>
            typeof acent.Kodu === "string" &&
            acent.Kodu !== null &&
            acent.Kodu !== undefined
        ) // Ensure Kodu is a string and not null or undefined
        .map((acent: any) => ({
          Id: acent.Id,
          Kod: acent.Kodu,
          Ad: acent.Adi,
        }));
      console.log("Acentalar:", fa);
      setAcentlar(fa);
      setFilteredAcentalar(fa);
    } catch (error) {
      // İstek sırasında bir hata oluşursa, hatayı console'a yazdır
      console.error("API request failed:", error);
      throw error;
    }
  };

  const AcentaSil = async () => {
    // formData.Id değerini al
    const id = formData.Id;

    // formData.Id değeri 0'a eşit değilse API isteği atılacak
    if (id > 0) {
      const requestBody = {
        db_Id: 9,
        Id: id,
      };
      const headers = {
        "Content-Type": "application/json",
        // Token'i localStorage'den al ve Bearer token olarak ekle
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      try {
        const response = await axios.post(
          `https://frontapi.rmosweb.com/api/Acenta/Sil`,
          requestBody,
          { headers }
        );
        alert("Acenta başarıyla silindi.");
      } catch (error) {
        // İstek sırasında bir hata oluşursa, hatayı console'a yazdır
        console.error("API request failed:", error);
        throw error;
      }
    } else {
      // formData.Id değeri 0'a eşitse API isteği atılmayacak
      alert("Acenta seçili olmadığı için silme işlemi yapılamaz.");
    }
  };
  const Source = async () => {
    setSourceData([]);
    const requestBody = {
      db_id: 9,
      Kod: "",
      Sinif: "15",
      Tip: 1,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    try {
      const response = await axios.post(
        `https://frontapi.rmosweb.com/api/Kodlar/Getir_Kod`,
        requestBody,
        { headers }
      );
      setSourceData(response.data.value);
    } catch (error) {
      console.error("API request failed:", error);
      throw error;
    }
  };
  const Iller = async () => {
    setIlKodlari([]);
    const requestBody = {
      db_id: 9,
      Kod: "",
      Sinif: "60",
      Tip: 1,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    try {
      const response = await axios.post(
        `https://frontapi.rmosweb.com/api/Kodlar/Getir_Kod`,
        requestBody,
        { headers }
      );
      setIlKodlari(response.data.value);
    } catch (error) {
      console.error("API request failed:", error);
      throw error;
    }
  };
  const Siniflar = async () => {
    // Local storage'dan token değerini al
    const requestBody = {
      db_Id: 9,
      Sinif: "",
      Kod: "",
      Tip: 999,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    try {
      const response = await axios.post(
        `https://frontapi.rmosweb.com/api/Kodlar/Getir_Kod`,
        requestBody,
        { headers }
      );
      setModalOpen(false); // Modalı kapat

      const dataFromApi = response.data.value;

      // Set state with fetched data
      setData1(dataFromApi);

      // Clear existing classifications and update each state with fresh data
      setSatisNoktalari([]);
      setKurKodlari([]);
      setUlkeKodlari([]);
      setMusteriTipleri([]);
      setOdemeKodlari([]);
      setAcenteGrupKodlari([]);
      setPazarKodlari([]);
      setPansiyonKodlari([]);
      setFilteredSatisNoktalari([]);
      setFilteredKurKodlari([]);
      setFilteredUlkeKodlari([]);
      setFilteredMusteriTipleri([]);
      setFilteredOdemeKodlari([]);
      setFilteredAcenteGrupKodlari([]);
      setFilteredPazarKodlari([]);
      setFilteredPansiyonKodlari([]);

      // Classify the data
      dataFromApi.forEach((item: any) => {
        switch (item.Sinif) {
          case "01":
            setSatisNoktalari((prevState) => [...prevState, item]);
            setFilteredSatisNoktalari((prevState) => [...prevState, item]);
            break;
          case "02":
            setKurKodlari((prevState) => [...prevState, item]);
            setFilteredKurKodlari((prevState) => [...prevState, item]);
            break;
          case "04":
            setUlkeKodlari((prevState) => [...prevState, item]);
            setFilteredUlkeKodlari((prevState) => [...prevState, item]);
            break;
          case "09":
            setMusteriTipleri((prevState) => [...prevState, item]);
            setFilteredMusteriTipleri((prevState) => [...prevState, item]);
            break;
          case "10":
            setOdemeKodlari((prevState) => [...prevState, item]);
            setFilteredOdemeKodlari((prevState) => [...prevState, item]);
            break;
          case "21":
            setAcenteGrupKodlari((prevState) => [...prevState, item]);
            setFilteredAcenteGrupKodlari((prevState) => [...prevState, item]);
            break;
          case "25":
            setPazarKodlari((prevState) => [...prevState, item]);
            setFilteredPazarKodlari((prevState) => [...prevState, item]);
            break;
          case "39":
            setPansiyonKodlari((prevState) => [...prevState, item]);
            setFilteredPansiyonKodlari((prevState) => [...prevState, item]);
            break;
          default:
            break;
        }
      });
    } catch (error) {
      // Hata mesajını logla
      console.error("API request failed:", error);
      throw error;
    }
  };

  const KontratEkle = async () => {
    console.log("kontrat ekle gönderilen data", kontratEkleData);
    const updatedKontratEkleData = { ...kontratEkleData, db_Id: 9 };
    console.log("kontrat ekle dbid data", updatedKontratEkleData);

    // Kodu ve Adi alanlarının hem var olduğunu hem de boş olmadığını kontrol et
    if (!updatedKontratEkleData.Kodu || updatedKontratEkleData.Kodu === "") {
      alert("Acenta Kodu boş bırakılamaz veya eksik.");
      setIsInvalid((prevState: any) => ({
        ...prevState,
        Kodu: true,
      })); // Kodu inputunun border rengini kırmızı yap
      return;
    }
    if (!updatedKontratEkleData.Adi || updatedKontratEkleData.Adi === "") {
      alert("Acenta Adı boş bırakılamaz veya eksik.");
      setIsInvalid((prevState: any) => ({
        ...prevState,
        Adi: true,
      })); // Adi inputunun border rengini kırmızı yap
      return;
    }
    if (
      !updatedKontratEkleData.Pazarkodu ||
      updatedKontratEkleData.Pazarkodu === ""
    ) {
      alert("Pazar alanı boş bırakılamaz.");
      setIsInvalid((prevState: any) => ({
        ...prevState,
        pazar: true,
      })); // Kodu inputunun border rengini kırmızı yap
      return;
    }
    if (!updatedKontratEkleData.Grubu || updatedKontratEkleData.Grubu === "") {
      alert("Grup alanı boş bırakılamaz.");
      setIsInvalid((prevState: any) => ({
        ...prevState,
        Grubu: true,
      })); // Kodu inputunun border rengini kırmızı yap
      return;
    }
    if (
      !updatedKontratEkleData.Musteritipi ||
      updatedKontratEkleData.Musteritipi === ""
    ) {
      alert("Müşteri Tipi alanı boş bırakılamaz.");
      setIsInvalid((prevState: any) => ({
        ...prevState,
        MusteriTipi: true,
      })); // Kodu inputunun border rengini kırmızı yap
      return;
    }
    if (
      !updatedKontratEkleData.Kurkodu ||
      updatedKontratEkleData.Kurkodu === ""
    ) {
      alert("Kur Kodu alanı boş bırakılamaz.");
      setIsInvalid((prevState: any) => ({
        ...prevState,
        KurKodu: true,
      })); // Kodu inputunun border rengini kırmızı yap
      return;
    }
    if (
      !updatedKontratEkleData.Ulkekodu ||
      updatedKontratEkleData.Ulkekodu === ""
    ) {
      alert("Ülke alanı boş bırakılamaz.");
      setIsInvalid((prevState: any) => ({
        ...prevState,
        Ulke: true,
      })); // Kodu inputunun border rengini kırmızı yap
      return;
    }
    if (
      !updatedKontratEkleData.Pansiyon ||
      updatedKontratEkleData.Pansiyon === ""
    ) {
      alert("Pansiyon alanı boş bırakılamaz.");
      setIsInvalid((prevState: any) => ({
        ...prevState,
        Pansiyon: true,
      })); // Kodu inputunun border rengini kırmızı yap
      return;
    }
    if (
      !updatedKontratEkleData.Odemesekli ||
      updatedKontratEkleData.Odemesekli === ""
    ) {
      alert("Ödeme Kodu alanı boş bırakılamaz.");
      setIsInvalid((prevState: any) => ({
        ...prevState,
        Odeme: true,
      })); // Kodu inputunun border rengini kırmızı yap
      return;
    }
    if (
      updatedKontratEkleData === null ||
      updatedKontratEkleData === undefined
    ) {
      alert("Acenta bilgileri boş bırakılamaz.");
      return;
    }

    const headers = {
      "Content-Type": "application/json",
      // Token'i localStorage'den al ve Bearer token olarak ekle
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    try {
      const response = await axios.post(
        "https://frontapi.rmosweb.com/api/Acenta/Ekle",
        updatedKontratEkleData,
        { headers }
      );
      alert(
        "Acenta Ekleme Başarılı. API'den dönen veri: " +
          JSON.stringify(response.data)
      );
      setKontratEkleData(KontratGirisleriData);
      alert("Acenta Ekleme Başarılı");
      setModalOpen(false); // Modalı kapat
      KontratData();
    } catch (error) {
      console.error("Hata:", error);
    }
  };

  const handleChangeKontratEkle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  useEffect(() => {
    Siniflar();
    Acentalar();
    Source();
    Iller();
  }, []);

  useEffect(() => {
    //setKontratEkleData datasını içini temizlemek istiyorum
    const handleModalOpen = () => {
      openModal();
      setModalOpen(true);
    };

    if (isOpen) {
      // `data` varsa ve nesne ise, bu nesneyi bir dizi içine koy
      if (data) {
        // data'yı diziye çevirip ayarla
        const dataArray = Array.isArray(data) ? data : [data];
        // Dizinin ilk elemanını alarak kontratEkleData'ya ekle
        setKontratEkleData(dataArray[0]); // Tek bir öğe olarak geçir
        handleModalOpen();
      }
      handleModalOpen();
    }
  }, [isOpen, data, openModal]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleFilterChangeSource = (event: ComboBoxFilterChangeEvent) => {
    const value = event.filter.value.toString().toLowerCase(); // FilterDescriptor'dan değeri alıp dizeye çeviriyoruz
    const filtered = acentlar.filter((item) =>
      item.Adi.toLowerCase().includes(value)
    );
    setFilteredSource(filtered);
  };
  const categories = ["pazar1", "pazar2", "pazar3", "pazar4"];
  const eFatura = [
    { Ad: "E-Fatura", Kod: "E" },
    { Ad: "E-Arşiv", Kod: "A" },
    { Ad: "Kağıt Ft", Kod: "K" },
    { Ad: "Diğer", Kod: "H" },
  ];
  const eFaturaSenaryo = [
    { Ad: "Ticari Fatura", Kod: "F" },
    { Ad: "Temel Fatura", Kod: "İ" },
    { Ad: "Kağıt Fatura", Kod: "FI" },
  ];
  const dovizSekli = [
    { Ad: "Doviz Alış", Kod: "DA" },
    { Ad: "Doviz Satış", Kod: "DS" },
    { Ad: "Efektif Alış", Kod: "EA" },
    { Ad: "Efektif Satış", Kod: "ES" },
  ];
  const bilgidoldur = () => {
    setKontratEkleData(yeniNesne);
  };

  useEffect(() => {
    console.log("acenta grup kodları", acenteGrupKodlari);
  }, [acenteGrupKodlari]);

  const handleFilter = (initialData: any, setFilteredData: any) => (e: any) => {
    const filterText = e.filter.value.toLowerCase();
    if (filterText === "") {
      setFilteredData(initialData);
    } else {
      const filteredData = initialData.filter((item: any) =>
        item.Ad.toLowerCase().includes(filterText)
      );
      setFilteredData(filteredData);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
          setKontratEkleData(KontratGirisleriData);
        }}
        size="full-screen"
        header={<div>Acenta Kartı</div>}
        footer={
          <>
            <button
              className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
              onClick={AcentaSil}
            >
              Sil
            </button>
            <button
              className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ms-2"
              onClick={() => setModalOpen(false)}
            >
              Close
            </button>
            <button
              className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ms-2"
              onClick={KontratEkle}
            >
              kontrat ekle{" "}
            </button>
            //default doldur butonu
            <button
              className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md ms-2"
              onClick={bilgidoldur}
            >
              doldur
            </button>
          </>
        }
      >
        {/* <input
            type="text"
            name="someProperty"
            value={formData.someProperty || ''}
            onChange={handleChange}
          /> */}
        <>
          <div className="container-fluid py-3">
            <div className="row">
              <div className="col-md-12 row">
                <div className="col-md-5">
                  <div className="d-flex align-items-center justify-content-Start">
                    <FontAwesomeIcon icon={faUser} className="fs-5" />
                    <span className="ms-2">Acenta Kodu</span>
                    <Input
                      className="ms-2 w-75"
                      type="text"
                      placeholder="Acenta Kodu"
                      value={kontratEkleData.Kodu}
                      style={{ borderColor: isInvalid.Kodu ? "red" : "" }}
                      onChange={(e) => {
                        const newValue = e.target.value as string;
                        setKontratEkleData((prevData) => ({
                          ...prevData,
                          Kodu: newValue,
                        }));
                        setIsInvalid((prevState: any) => ({
                          ...prevState,
                          Kodu: false,
                        }));
                      }}
                    ></Input>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="d-flex align-items-center justify-content-Start">
                    <FontAwesomeIcon icon={faUser} className="fs-5" />
                    <span className="ms-2">Acenta Adı</span>
                    <Input
                      className="ms-2 w-75"
                      type="text"
                      placeholder="Acenta Adı"
                      value={kontratEkleData.Adi}
                      style={{ borderColor: isInvalid.Adi ? "red" : "" }}
                      onChange={(e) => {
                        const newValue = e.target.value as string;
                        setKontratEkleData((prevData) => ({
                          ...prevData,
                          Adi: newValue,
                        }));
                        setIsInvalid((prevState: any) => ({
                          ...prevState,
                          Adi: false,
                        }));
                      }}
                    ></Input>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex  mt-2 align-items-center justify-content-Start">
                    <RadioButton
                      name="AcentaTipi"
                      value={0}
                      label="Acenta"
                      checked={kontratEkleData.Acenta_sirket_10 === 0}
                      onChange={(e) => {
                        setKontratEkleData((prevData) => ({
                          ...prevData,
                          Acenta_sirket_10: 0, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                        }));
                      }}
                    />
                    <RadioButton
                      className="ms-3"
                      name="AcentaTipi"
                      value={1}
                      label="Firma"
                      checked={kontratEkleData.Acenta_sirket_10 === 1}
                      onChange={(e) => {
                        setKontratEkleData((prevData) => ({
                          ...prevData,
                          Acenta_sirket_10: 1, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k!)}
                  className="mb-3"
                >
                  <Tab eventKey="GenelBilgiler" title="Genel Bilgiler">
                    <div>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-3 gap-2 d-flex flex-column">
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Pazar
                              </div>
                              <div className="w-60 ">
                                <ComboBox
                                  data={filteredPazarKodlari} // Ad alanını listele
                                  textField="Ad" // Görüntülenen alan
                                  dataItemKey="Kod" // Use `dataItemKey` instead of `valueField`
                                  filterable
                                  onFilterChange={handleFilter(
                                    pazarKodlari,
                                    setFilteredPazarKodlari
                                  )}
                                  style={{
                                    borderColor: isInvalid.pazar ? "red" : "",
                                    color: isInvalid.pazar ? "red" : "",
                                  }}
                                  value={pazarKodlari.find(
                                    (item) =>
                                      item.Kod === kontratEkleData.Pazarkodu
                                  )}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value.Kod;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Pazarkodu: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                    }));
                                    setIsInvalid((prevState: any) => ({
                                      ...prevState,
                                      pazar: false,
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Grubu
                              </div>
                              <div className="w-60 ">
                                <ComboBox
                                  data={filteredAcenteGrupKodlari}
                                  textField="Ad" // Görüntülenen alan
                                  dataItemKey="Kod" // Use `dataItemKey` instead of `valueField`
                                  filterable
                                  onFilterChange={handleFilter(
                                    acenteGrupKodlari,
                                    setFilteredAcenteGrupKodlari
                                  )}
                                  style={{
                                    borderColor: isInvalid.Grubu ? "red" : "",
                                    color: isInvalid.Grubu ? "red" : "",
                                  }}
                                  value={acenteGrupKodlari.find(
                                    (item) => item.Kod === kontratEkleData.Grubu
                                  )}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value.Kod;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Grubu: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                    }));
                                    setIsInvalid((prevState: any) => ({
                                      ...prevState,
                                      Grubu: false,
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Müşteri Tipi
                              </div>
                              <div className="w-60 ">
                                {/* <DropDownList
                                  data={musteriTipleri} // Ad alanını listele
                                  textField="Ad" // Görüntülenen alan
                                  dataItemKey="Kod" // Use `dataItemKey` instead of `valueField`
                                  style={{
                                    borderColor: isInvalid.MusteriTipi
                                      ? "red"
                                      : "",
                                    color: isInvalid.MusteriTipi ? "red" : "",
                                  }}
                                  value={musteriTipleri.find(
                                    (item) =>
                                      item.Kod === kontratEkleData.Musteritipi
                                  )}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value.Kod;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Musteritipi: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                    }));
                                    setIsInvalid((prevState: any) => ({
                                      ...prevState,
                                      MusteriTipi: false,
                                    }));
                                  }}
                                  defaultItem={{
                                    Ad: "Müşteri Seçiniz",
                                    Kod: "",
                                  }}
                                /> */}
                                <ComboBox
                                  data={filteredMusteriTipleri}
                                  textField="Ad"
                                  dataItemKey="Kod"
                                  filterable
                                  onFilterChange={handleFilter(
                                    musteriTipleri,
                                    setFilteredMusteriTipleri
                                  )}
                                  style={{
                                    borderColor: isInvalid.MusteriTipi
                                      ? "red"
                                      : "",
                                    color: isInvalid.MusteriTipi ? "red" : "",
                                  }}
                                  value={musteriTipleri.find(
                                    (item) =>
                                      item.Kod === kontratEkleData.Musteritipi
                                  )}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value.Kod;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Musteritipi: selectedValue,
                                    }));
                                    setIsInvalid((prevState: any) => ({
                                      ...prevState,
                                      MusteriTipi: false,
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Kur Kodu
                              </div>
                              <div className="w-60 ">
                                <ComboBox
                                  data={filteredKurKodlari} // Ad alanını listele
                                  textField="Ad" // Görüntülenen alan
                                  dataItemKey="Kod" // Use `dataItemKey` instead of `valueField`
                                  filterable
                                  onFilterChange={handleFilter(
                                    kurKodlari,
                                    setFilteredKurKodlari
                                  )}
                                  style={{
                                    borderColor: isInvalid.KurKodu ? "red" : "",
                                    color: isInvalid.KurKodu ? "red" : "",
                                  }}
                                  value={kurKodlari.find(
                                    (item) =>
                                      item.Kod === kontratEkleData.Kurkodu
                                  )}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value.Kod;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Kurkodu: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                    }));
                                    setIsInvalid((prevState: any) => ({
                                      ...prevState,
                                      KurKodu: false,
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Ülke
                              </div>
                              <div className="w-60 ">
                                <ComboBox
                                  data={filteredUlkeKodlari} // Ad alanını listele
                                  textField="Ad" // Görüntülenen alan
                                  dataItemKey="Kod" // Use `dataItemKey` instead of `valueField`
                                  filterable
                                  onFilterChange={handleFilter(
                                    ulkeKodlari,
                                    setFilteredUlkeKodlari
                                  )}
                                  style={{
                                    borderColor: isInvalid.Ulke ? "red" : "",
                                    color: isInvalid.Ulke ? "red" : "",
                                  }}
                                  value={ulkeKodlari.find(
                                    (item) =>
                                      item.Kod === kontratEkleData.Ulkekodu
                                  )}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value.Kod;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Ulkekodu: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                    }));
                                    setIsInvalid((prevState: any) => ({
                                      ...prevState,
                                      Ulke: false,
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Pansiyon
                              </div>
                              <div className="w-60 ">
                                <ComboBox
                                  data={filteredPansiyonKodlari} // Ad alanını listele
                                  textField="Ad" // Görüntülenen alan
                                  dataItemKey="Kod" // Use `dataItemKey` instead of `valueField`
                                  filterable
                                  onFilterChange={handleFilter(
                                    pansiyonKodlari,
                                    setFilteredPansiyonKodlari
                                  )}
                                  style={{
                                    borderColor: isInvalid.Pansiyon
                                      ? "red"
                                      : "",
                                    color: isInvalid.Pansiyon ? "red" : "",
                                  }}
                                  value={pansiyonKodlari.find(
                                    (item) =>
                                      item.Kod === kontratEkleData.Pansiyon
                                  )}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value.Kod;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Pansiyon: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                    }));
                                    setIsInvalid((prevState: any) => ({
                                      ...prevState,
                                      Pansiyon: false,
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Ödeme
                              </div>
                              <div className="w-60 ">
                                <ComboBox
                                  data={filteredOdemeKodlari} // Ad alanını listele
                                  textField="Ad" // Görüntülenen alan
                                  dataItemKey="Kod" // Use `dataItemKey` instead of `valueField`
                                  filterable
                                  onFilterChange={handleFilter(
                                    odemeKodlari,
                                    setFilteredOdemeKodlari
                                  )}
                                  style={{
                                    borderColor: isInvalid.Odeme ? "red" : "",
                                    color: isInvalid.Odeme ? "red" : "",
                                  }}
                                  value={odemeKodlari.find(
                                    (item) =>
                                      item.Kod === kontratEkleData.Odemesekli
                                  )}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value.Kod;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Odemesekli: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                    }));
                                    setIsInvalid((prevState: any) => ({
                                      ...prevState,
                                      Odeme: false,
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className="w-40 d-flex justify-content-start align-items-center">
                                Özel Ind.Extra
                              </div>
                              <div className="w-60">
                                <NumericTextBox
                                  className="w-100"
                                  format="n2"
                                  placeholder="Özel Ind.Extra"
                                  value={kontratEkleData.Ozelindirim}
                                  onChange={(e) => {
                                    const newValue =
                                      e.target.value !== null
                                        ? e.target.value
                                        : 0;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Ozelindirim: newValue,
                                    }));
                                  }}
                                />
                              </div>
                            </div>

                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                e-Fatura
                              </div>
                              <div className="w-60 ">
                                <ComboBox
                                  data={eFatura}
                                  textField="Ad" // Görüntülenen alan
                                  dataItemKey="Kod" // Use `dataItemKey` instead of `valueField`
                                  value={eFatura.find(
                                    (item) =>
                                      item.Kod === kontratEkleData.E_fatura_eh
                                  )}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value.Kod;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      E_fatura_eh: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Fatura Senaryo
                              </div>
                              <div className="w-60 ">
                                <ComboBox
                                  data={eFaturaSenaryo}
                                  textField="Ad" // Görüntülenen alan
                                  dataItemKey="Kod" // Use `dataItemKey` instead of `valueField`
                                  value={eFaturaSenaryo.find(
                                    (item) =>
                                      item.Kod ===
                                      kontratEkleData.E_fatura_senaryo
                                  )}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value.Kod;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      E_fatura_senaryo: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Doviz Şekli
                              </div>
                              <div className="w-60 ">
                                <DropDownList
                                  data={dovizSekli}
                                  textField="Ad" // Görüntülenen alan
                                  dataItemKey="Kod" // Use `dataItemKey` instead of `valueField`
                                  value={dovizSekli.find(
                                    (item) =>
                                      item.Kod === kontratEkleData.Doviz_Sekli
                                  )}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value.Kod;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Doviz_Sekli: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Onl.Ödeme%
                              </div>
                              <div className="w-60  d-flex align-items-center">
                                <NumericTextBox
                                  className="w-100"
                                  format="n2"
                                  placeholder="Onl.Ödeme%"
                                  value={kontratEkleData.Online_odeme_yuzde}
                                  onChange={(e) => {
                                    const newValue =
                                      e.target.value !== null
                                        ? e.target.value
                                        : 0;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Online_odeme_yuzde: newValue,
                                    }));
                                  }}
                                ></NumericTextBox>
                                <span>Tah%</span>
                                <NumericTextBox
                                  className="w-100"
                                  placeholder="Tah%"
                                  format="n2"
                                  value={kontratEkleData.Tahsilat_yuzde}
                                  onChange={(e) => {
                                    const newValue =
                                      e.target.value !== null
                                        ? e.target.value
                                        : 0;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Tahsilat_yuzde: newValue,
                                    }));
                                  }}
                                ></NumericTextBox>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Onl.Öd.Depart
                              </div>
                              <div className="w-60 ">
                                <ComboBox
                                  data={filteredSatisNoktalari}
                                  textField="Ad" // Görüntülenen alan
                                  dataItemKey="Kod" // Use `dataItemKey` instead of `valueField`
                                  filterable
                                  onFilterChange={handleFilter(
                                    satisNoktalari,
                                    setFilteredSatisNoktalari
                                  )}
                                  value={satisNoktalari.find(
                                    (item) =>
                                      item.Kod ===
                                      kontratEkleData.Online_odemedep
                                  )}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value.Kod;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Online_odemedep: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Forecast Grubu
                              </div>
                              <div className="w-60 ">
                                {/* <ComboBox
  data={filteredAcentalar}
  textField="Ad" // Ensure this matches the property name in your data
  dataItemKey="Kod" // Ensure this matches the property name in your data
  filterable
  onFilterChange={handleFilter(acentlar, setFilteredAcentalar)}
  value={satisNoktalari.find(
    (item) => item.Kod === kontratEkleData.Forecast_grubu
  )} // Burada null kontrolü ekliyoruz
  onChange={(e) => {
    const selectedItem = e.target.value;
    if (selectedItem) {
      const selectedValue = selectedItem.Kod;
      setKontratEkleData((prevData) => ({
        ...prevData,
        Forecast_grubu: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
      }));
    }
  }}
/> */}
                                <ComboBox
                                  data={filteredAcentalar} // Ad alanını listele
                                  textField="Ad" // Görüntülenen alan
                                  dataItemKey="Kod" // Use `dataItemKey` instead of `valueField`
                                  filterable
                                  onFilterChange={handleFilter(
                                    acentlar,
                                    setFilteredAcentalar
                                  )}
                                  value={acentlar.find(
                                    (item) =>
                                      item.Kod ===
                                      kontratEkleData.Forecast_grubu
                                  )}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value.Kod;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Forecast_grubu: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Kontenjan Grubu
                              </div>
                              <div className="w-60 ">
                                {/* <ComboBox
                                  data={
                                    filteredAcentalar.length > 0
                                      ? filteredAcentalar
                                      : acentlar
                                  }
                                  textField="Adi"
                                  filterable={true}
                                  onFilterChange={handleFilterChangeSource}
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      const selectedValue = e.target.value.Kodu;
                                      kontratEkleData.Source = selectedValue;
                                    } else {
                                    }
                                  }}
                                /> */}
                                <ComboBox
                                  data={filteredAcentalar}
                                  textField="Ad" // Ensure this matches the property name in your data
                                  dataItemKey="Kod" // Ensure this matches the property name in your data
                                  filterable
                                  onFilterChange={handleFilter(
                                    acentlar,
                                    setFilteredAcentalar
                                  )}
                                  value={acentlar.find(
                                    (item) =>
                                      item.Kod ===
                                      kontratEkleData.Kontenjan_grubu
                                  )} // Burada null kontrolü ekliyoruz
                                  onChange={(e) => {
                                    const selectedItem = e.target.value;
                                    if (selectedItem) {
                                      const selectedValue = selectedItem.Kod;
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Kontenjan_grubu: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                      }));
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Source
                              </div>
                              <div className="w-60 ">
                                {/* <ComboBox
                                  data={
                                    filteredSource.length > 0
                                      ? filteredSource
                                      : sourceData
                                  }
                                  textField="Adi"
                                  filterable={true}
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      const selectedValue = e.target.value.Kodu;
                                      kontratEkleData.Kontenjan_grubu =
                                        selectedValue;
                                    } else {
                                    }
                                  }}
                                /> */}
                                <ComboBox
                                  data={filteredAcentalar}
                                  textField="Ad" // Ensure this matches the property name in your data
                                  dataItemKey="Kod" // Ensure this matches the property name in your data
                                  filterable
                                  onFilterChange={handleFilter(
                                    acentlar,
                                    setFilteredAcentalar
                                  )}
                                  value={satisNoktalari.find(
                                    (item) =>
                                      item.Kod === kontratEkleData.Source
                                  )} // Burada null kontrolü ekliyoruz
                                  onChange={(e) => {
                                    const selectedItem = e.target.value;
                                    if (selectedItem) {
                                      const selectedValue = selectedItem.Kod;
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Source: selectedValue, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                      }));
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Max Çocuk Yaşı Dahil
                              </div>
                              <div className="w-60 ">
                                <NumericTextBox
                                  className="w-100"
                                  placeholder="Max Çocuk Yaşı Dahil"
                                  value={kontratEkleData.Yetiskin_yas}
                                  onChange={(e) => {
                                    const newValue =
                                      e.target.value !== null
                                        ? e.target.value
                                        : 0;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Yetiskin_yas: newValue,
                                    }));
                                  }}
                                ></NumericTextBox>
                              </div>
                            </div>
                          </div>
                          {/* ikinci kısımm */}
                          <div className="col-md-3 gap-2 d-flex flex-column">
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Resmi Adı
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Resmi Adı"
                                  value={kontratEkleData.Resmiad}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Resmiad: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Vergi Dairesi
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Vergi Dairesi"
                                  value={kontratEkleData.Vergidaire}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Vergidaire: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Vergi No
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Vergi No"
                                  value={kontratEkleData.Vergino}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Vergino: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Tel Numarası
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Tel Numarası"
                                  value={kontratEkleData.Tel}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Tel: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Fax No
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Fax No"
                                  value={kontratEkleData.Fax}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Fax: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Gsm
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Gsm"
                                  value={kontratEkleData.Gsm}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Gsm: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                e-Mail
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="e-Mail"
                                  value={kontratEkleData.Rez_mail}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Rez_mail: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Muhasebe Kodu
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Muhasebe Kodu"
                                  value={kontratEkleData.Muhasebe}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Muhasebe: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Bavel Kodu
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Bavel Kodu"
                                  value={kontratEkleData.Bevel}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Bevel: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Banka
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Banka"
                                  value={kontratEkleData.Banka}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Banka: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Şube
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Şube"
                                  value={kontratEkleData.Banka_sube}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Banka_sube: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Hesap
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Hesap"
                                  value={kontratEkleData.Hesap}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Hesap: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                IBAN
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="IBAN"
                                  value={kontratEkleData.Banka_iban}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Banka_iban: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Swift Kod
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Swift Kod"
                                  value={kontratEkleData.Banka_swift_kod}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Banka_swift_kod: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Online Şifre
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Online Şifre"
                                  value={kontratEkleData.Sifresi}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Sifresi: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Vat No
                              </div>
                              <div className="w-60 ">
                                <Input
                                  className="w-100"
                                  type="text"
                                  placeholder="Vat No"
                                  value={kontratEkleData.Vatno}
                                  onChange={(e) => {
                                    const newValue = e.target.value as string;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Vatno: newValue,
                                    }));
                                  }}
                                ></Input>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Acenta Vadesi gün
                              </div>
                              <div className="w-60 ">
                                <NumericTextBox
                                  className="w-100"
                                  placeholder="Acenta Vadesi gün"
                                  value={kontratEkleData.Vade_gun}
                                  onChange={(e) => {
                                    const newValue =
                                      e.target.value !== null
                                        ? e.target.value
                                        : 0;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Vade_gun: newValue,
                                    }));
                                  }}
                                ></NumericTextBox>
                              </div>
                            </div>
                            <div className="w-100 d-flex">
                              <div className=" w-40 d-flex justify-content-start align-items-center">
                                Forecast Iptal Gün Sayısı
                              </div>
                              <div className="w-60 ">
                                <NumericTextBox
                                  className="w-100"
                                  placeholder="Forecast Iptal Gün Sayısı"
                                  value={kontratEkleData.Forecast_iptalgunsay}
                                  onChange={(e) => {
                                    const newValue =
                                      e.target.value !== null
                                        ? e.target.value
                                        : 0;
                                    setKontratEkleData((prevData) => ({
                                      ...prevData,
                                      Forecast_iptalgunsay: newValue,
                                    }));
                                  }}
                                ></NumericTextBox>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 gap-2 d-flex flex-column">
                            <div className="w-100 d-flex gap-2 flex-column">
                              <Card>
                                <Card.Header>
                                  <Card.Title>Filtreler</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                  <div className="d-flex flex-column  justify-content-start">
                                    <div className="text-start">
                                      <Checkbox
                                        defaultChecked={kontratEkleData.Pasif}
                                        onChange={(e) => {
                                          const newValue = e.value as boolean;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Pasif: newValue,
                                          }));
                                        }}
                                        label={"Pasif"}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <Checkbox
                                        defaultChecked={kontratEkleData.Bavel}
                                        label={"Bavel Entegre"}
                                        onChange={(e) => {
                                          const newValue = e.value as boolean;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Bavel: newValue,
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <Checkbox
                                        defaultChecked={
                                          kontratEkleData.Resmiad_kullan_10 ===
                                          1
                                        }
                                        onChange={(e) => {
                                          // Checkbox'ın yeni değerini 1 veya 0 olarak al
                                          const newValue = e.value ? 1 : 0;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Resmiad_kullan_10: newValue,
                                          }));
                                        }}
                                        label={"Bavelde Resmi Adı Kullan"}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <Checkbox
                                        defaultChecked={
                                          kontratEkleData.Bilet_kontrol_10 === 1
                                        }
                                        label={"Bilet Satış Tarihi Zorunlu"}
                                        onChange={(e) => {
                                          const newValue = e.value ? 1 : 0;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Bilet_kontrol_10: newValue,
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <Checkbox
                                        defaultChecked={
                                          kontratEkleData.Kon_kurusyuvarla_10 ===
                                          1
                                        }
                                        label={
                                          "Kont.Hesaplamalarda Kuruş Yuvarla"
                                        }
                                        onChange={(e) => {
                                          const newValue = e.value ? 1 : 0;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Kon_kurusyuvarla_10: newValue,
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <Checkbox
                                        label={
                                          "Bu Acentada Manuel Fiyat Uygulanamaz"
                                        }
                                        defaultChecked={
                                          kontratEkleData.Manuel_yok_10
                                        }
                                        onChange={(e) => {
                                          const newValue = e.value as boolean;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Manuel_yok_10: newValue,
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <Checkbox
                                        label={"Fatura Otomatik"}
                                        defaultChecked={
                                          kontratEkleData.Cout_otofat_10 === 1
                                        }
                                        onChange={(e) => {
                                          const newValue = e.value ? 1 : 0;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Cout_otofat_10: newValue,
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <Checkbox
                                        label={"Web Acenta(CM)"}
                                        defaultChecked={
                                          kontratEkleData.Web_10 === 1
                                        }
                                        onChange={(e) => {
                                          const newValue = e.value ? 1 : 0;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Web_10: newValue,
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <Checkbox
                                        label={"Havayolu Şirketi"}
                                        defaultChecked={
                                          kontratEkleData.Havayolu_10 === 1
                                        }
                                        onChange={(e) => {
                                          const newValue = e.value ? 1 : 0;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Havayolu_10: newValue,
                                          }));
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                              <Card>
                                <Card.Header>
                                  <Card.Title>Devre Mülk</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                  <div className="d-flex flex-column justify-content-start">
                                    <div className="text-start">
                                      <Checkbox
                                        label={"Mülk Sahibi (Devre Mulk)"}
                                        color="red"
                                        defaultChecked={
                                          kontratEkleData.Msh_10 === 1
                                        }
                                        onChange={(e) => {
                                          const newValue = e.value ? 1 : 0;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Msh_10: newValue,
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <Checkbox
                                        label={"Devremulk Fiyatı Getirsin"}
                                        defaultChecked={
                                          kontratEkleData.nta_Rcitbl_10 === 1
                                        }
                                        onChange={(e) => {
                                          const newValue = e.value ? 1 : 0;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            nta_Rcitbl_10: newValue,
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <Checkbox
                                        label={
                                          "Devremulk Fiyatında Pansiyon Ayır"
                                        }
                                        defaultChecked={
                                          kontratEkleData.Devrepans_ayir_10 ===
                                          1
                                        }
                                        onChange={(e) => {
                                          const newValue = e.value ? 1 : 0;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Devrepans_ayir_10: newValue,
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <Checkbox
                                        label={
                                          "Devremulk Fiyatında Extra Satış Ayır(Havuz,spa)"
                                        }
                                        defaultChecked={
                                          kontratEkleData.Devre_extraayir_10 ===
                                          1
                                        }
                                        onChange={(e) => {
                                          const newValue = e.value ? 1 : 0;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Devre_extraayir_10: newValue,
                                          }));
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                              <Card>
                                <Card.Header>
                                  <Card.Title>
                                    Acenta Pansiyon Ayırma Özel
                                  </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                  <div className="d-flex flex-column justify-content-start">
                                    <div className="text-start">
                                      <RadioButton
                                        name="AcentaPansiyonAyirma"
                                        label="yok"
                                        checked={
                                          kontratEkleData.Pansiyon_ayir_10 == 0
                                        }
                                        value={0}
                                        onChange={(e) => {
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Pansiyon_ayir_10: 0, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <RadioButton
                                        name="AcentaPansiyonAyirma"
                                        label="Default Tan Ayır"
                                        checked={
                                          kontratEkleData.Pansiyon_ayir_10 == 1
                                        }
                                        value={1}
                                        onChange={(e) => {
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Pansiyon_ayir_10: 1, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <RadioButton
                                        name="AcentaPansiyonAyirma"
                                        label="Acenta Özel Pansiyon Ayırma Kodu var"
                                        checked={
                                          kontratEkleData.Pansiyon_ayir_10 == 3
                                        }
                                        value={3}
                                        onChange={(e) => {
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Pansiyon_ayir_10: 3, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="w-100 d-flex">
                                      <div className=" w-40 d-flex justify-content-start align-items-center">
                                        Özel Pansiyon Kodu
                                      </div>
                                      <div className="w-60 ">
                                        <DropDownList
                                          data={categories}
                                          defaultValue="pazar"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                              <Card className="border">
                                <Card.Header>
                                  <Card.Title>Accenta ÜSt Grup</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                  <div className="w-100 mt-2 d-flex">
                                    <div className=" w-40 d-flex justify-content-start align-items-center">
                                      Acenta Ust Grup
                                    </div>
                                    <div className="w-60 ">
                                      <DropDownList
                                        data={categories}
                                        defaultValue="pazar"
                                      />
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          </div>

                          <div className="col-md-3 gap-2 d-flex flex-column">
                            <div className="w-100 d-flex gap-2 flex-column">
                              <Card>
                                <Card.Header>
                                  <Card.Title>
                                    C/L Fatura Açıklama Formatı
                                  </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                  <div className="d-flex flex-column justify-content-start">
                                    <div className="text-start">
                                      <RadioButton
                                        name="C/L"
                                        value={0}
                                        label="Default"
                                        checked={
                                          kontratEkleData.Fatura_aciklama_no ==
                                          0
                                        }
                                        onChange={(e) => {
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Fatura_aciklama_no: 0, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <RadioButton
                                        name="C/L"
                                        value={1}
                                        label="Pegas"
                                        checked={
                                          kontratEkleData.Fatura_aciklama_no ==
                                          1
                                        }
                                        onChange={(e) => {
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Fatura_aciklama_no: 1, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <RadioButton
                                        name="C/L"
                                        value={3}
                                        label="Diğer"
                                        checked={
                                          kontratEkleData.Fatura_aciklama_no ==
                                          3
                                        }
                                        onChange={(e) => {
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Fatura_aciklama_no: 3, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                          }));
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                              <Card>
                                <Card.Header>
                                  <Card.Title>Vergi, Fatura Şekli</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                  <div className="d-flex flex-column justify-content-start">
                                    <div className="text-start">
                                      <RadioButton
                                        name="VergiFatura"
                                        value={0}
                                        label="Default(Normal)"
                                        checked={
                                          kontratEkleData.Diplomat_10 == 0
                                        }
                                        onChange={(e) => {
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Diplomat_10: 0, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <RadioButton
                                        name="VergiFatura"
                                        value={1}
                                        label="Diplomat Ateşe"
                                        checked={
                                          kontratEkleData.Diplomat_10 == 1
                                        }
                                        onChange={(e) => {
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Diplomat_10: 1, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <RadioButton
                                        name="VergiFatura"
                                        value={2}
                                        label="Özel"
                                        checked={
                                          kontratEkleData.Diplomat_10 == 2
                                        }
                                        onChange={(e) => {
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Diplomat_10: 2, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                          }));
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                              <Card>
                                <Card.Header>
                                  <Card.Title>
                                    Konaklama Vergi Oranları
                                  </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                  <div className="d-flex flex-column justify-content-start">
                                    <div className="text-start">
                                      <RadioButton
                                        name="KonaklamaVergi"
                                        value={0}
                                        label="Toplama Eklensin"
                                        checked={kontratEkleData.Konver123 == 0}
                                        onChange={(e) => {
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Konver123: 0, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <RadioButton
                                        name="KonaklamaVergi"
                                        value={1}
                                        label="Toplamdan Çıkarılsın"
                                        checked={kontratEkleData.Konver123 == 1}
                                        onChange={(e) => {
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Konver123: 1, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                          }));
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                              <Card>
                                <Card.Header>
                                  <Card.Title>
                                    Default Kullanılacak Fiyat Tipi
                                  </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                  <div className="d-flex flex-column justify-content-start">
                                    <div className="text-start">
                                      <RadioButton
                                        name="Tip1"
                                        value={0}
                                        label="Tip0(Genel)"
                                        checked={kontratEkleData.Tip == 0}
                                        onChange={(e) => {
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Tip: 0, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="text-start">
                                      <RadioButton
                                        name="Tip1"
                                        value={1}
                                        label="Tip1"
                                        checked={kontratEkleData.Tip == 1}
                                        onChange={(e) => {
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Tip: 1, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="w-100 mt-2 d-flex">
                                      <div className=" w-40 d-flex justify-content-start align-items-center">
                                        Acenta Ust Grup
                                      </div>
                                      <div className="w-60 ">
                                        <DropDownList
                                          data={categories}
                                          defaultValue="pazar"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Adres" title="Adres Diğer İşlemler">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="container-fluid">
                            <div className="row">
                              <div className=" gap-2 d-flex flex-column">
                                <div className="d-flex align-items-center justify-content-Start">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Adres 1
                                  </span>
                                  <Input
                                    className="w-60"
                                    type="text"
                                    placeholder="Adres 1"
                                    value={kontratEkleData.Adres1}
                                    onChange={(e) => {
                                      const newValue = e.target.value as string;
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Adres1: newValue,
                                      }));
                                    }}
                                  ></Input>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Adres 2
                                  </span>
                                  <Input
                                    className="w-60"
                                    type="text"
                                    placeholder="Adres 2"
                                    value={kontratEkleData.Adres2}
                                    onChange={(e) => {
                                      const newValue = e.target.value as string;
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Adres2: newValue,
                                      }));
                                    }}
                                  ></Input>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Adres 3
                                  </span>
                                  <Input
                                    className="w-60"
                                    type="text"
                                    placeholder="Adres 2"
                                    value={kontratEkleData.Adres3}
                                    onChange={(e) => {
                                      const newValue = e.target.value as string;
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Adres3: newValue,
                                      }));
                                    }}
                                  ></Input>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Ilce/Il/Ulke
                                  </span>
                                  <Input
                                    className="w-60"
                                    type="text"
                                    placeholder="Ilce/Il/Ulke"
                                    value={kontratEkleData.Ilce}
                                    onChange={(e) => {
                                      const newValue = e.target.value as string;
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Ilce: newValue,
                                      }));
                                    }}
                                  ></Input>
                                </div>
                                <div className="w-100 d-flex">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Il
                                  </div>
                                  <div className="w-60 ">
                                    <DropDownList
                                      data={ilKodlari}
                                      textField="Ad" // Görüntülenen alan
                                      dataItemKey="Kod"
                                      value={ilKodlari.find(
                                        (item) => item.Kod == kontratEkleData.Il
                                      )}
                                      onChange={(e) => {
                                        const selectedValue =
                                          e.target.value.Kod;
                                        setKontratEkleData((prevData) => ({
                                          ...prevData,
                                          Il: selectedValue,
                                        }));
                                      }}
                                      defaultItem={{
                                        Ad: "Il Seçiniz",
                                        Kod: "",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="w-100 d-flex">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Ulke
                                  </div>
                                  <div className="w-60 d-flex">
                                    <DropDownList
                                      data={ulkeKodlari}
                                      defaultItem={{
                                        Ad: "Ulke Seçiniz",
                                        Kod: "",
                                      }}
                                      textField="Ad" // Görüntülenen alan
                                      dataItemKey="Kod"
                                      value={ulkeKodlari.find(
                                        (item) =>
                                          item.Kod ==
                                          kontratEkleData.Adres_ulkekod
                                      )}
                                      onChange={(e) => {
                                        const selectedValue =
                                          e.target.value.Kod;
                                        setKontratEkleData((prevData) => ({
                                          ...prevData,
                                          Adres_ulkekod: selectedValue,
                                        }));
                                      }}
                                    />
                                    <Input
                                      className="w-60"
                                      type="text"
                                      placeholder="UlkeSi"
                                      value={kontratEkleData.Ulkesi}
                                      onChange={(e) => {
                                        const newValue = e.target
                                          .value as string;
                                        setKontratEkleData((prevData) => ({
                                          ...prevData,
                                          Ulkesi: newValue,
                                        }));
                                      }}
                                    ></Input>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-Start">
                                  <span className="w-40 d-flex justify-content-start align-items-center">
                                    Zip Kodu
                                  </span>
                                  <Input
                                    className="w-60"
                                    type="text"
                                    placeholder="Zip Kodu"
                                    value={kontratEkleData.Zip}
                                    onChange={(e) => {
                                      const newValue = e.target.value as string;
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Zip: newValue,
                                      }));
                                    }}
                                  ></Input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <Card>
                            <Card.Header>
                              <Card.Title>
                                Bavel Kur Kodu Nereden Alınsın
                              </Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="d-flex flex-column justify-content-start">
                                <div className="text-start">
                                  <RadioButton
                                    name="BavelKurNe"
                                    value={0}
                                    label="Default Ulke (Genel)"
                                    checked={kontratEkleData.Bavelkur_ne10 == 0}
                                    onChange={(e) => {
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Bavelkur_ne10: 0, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                      }));
                                    }}
                                  />
                                </div>
                                <div className="text-start">
                                  <RadioButton
                                    name="BavelKurNe"
                                    value={1}
                                    label="Adreste Ulke Kodundan"
                                    checked={kontratEkleData.Bavelkur_ne10 == 1}
                                    onChange={(e) => {
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Bavelkur_ne10: 1, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                      }));
                                    }}
                                  />
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="InterfaceOzelIslemler"
                    title="Interface Özel İşlemler"
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-4">
                          <Card>
                            <Card.Header>
                              <Card.Title>
                                Diğer Otomasyonlarla Parametreler
                              </Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="d-flex flex-column justify-content-start">
                                <div className="text-start">
                                  <RadioButton
                                    name="DOP"
                                    value={0}
                                    label="Bavel'e Gönderimde Ilave % ekle"
                                    checked={kontratEkleData.Bavel_ekle_10 == 0}
                                    onChange={(e) => {
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Bavel_ekle_10: 0, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                      }));
                                    }}
                                  />
                                </div>
                                <div className="text-start">
                                  <RadioButton
                                    name="DOP"
                                    value={1}
                                    label="Bavel'e Gönderimde Ilave % Düş(Eksiltme)"
                                    checked={kontratEkleData.Bavel_ekle_10 == 1}
                                    onChange={(e) => {
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Bavel_ekle_10: 1, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                      }));
                                    }}
                                  />
                                </div>
                                <div className="text-start">
                                  <RadioButton
                                    name="DOP"
                                    value={2}
                                    label="Işlemsiz"
                                    checked={kontratEkleData.Bavel_ekle_10 == 2}
                                    onChange={(e) => {
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Bavel_ekle_10: 2, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                      }));
                                    }}
                                  />
                                </div>
                                <div className="w-100 mt-2 d-flex">
                                  <div className=" w-40 d-flex justify-content-start align-items-center">
                                    Yuzde %
                                  </div>
                                  <div className="w-60 ">
                                    <NumericTextBox
                                      className="w-100"
                                      placeholder="Bavel Yuzde"
                                      value={kontratEkleData.Bavel_ekle_yuzde}
                                      onChange={(e) => {
                                        const newValue =
                                          e.target.value !== null
                                            ? e.target.value
                                            : 0;
                                        setKontratEkleData((prevData) => ({
                                          ...prevData,
                                          Bavel_ekle_yuzde: newValue,
                                        }));
                                      }}
                                    ></NumericTextBox>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-md-4">
                          <Card>
                            <Card.Header>
                              <Card.Title>Orta Bağlantı</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="d-flex flex-column justify-content-start">
                                <div className="text-start">
                                  <RadioButton
                                    name="RB"
                                    value={0}
                                    label="Reseliva"
                                    checked={kontratEkleData.Chanel_no == 0}
                                    onChange={(e) => {
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Chanel_no: 0, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                      }));
                                    }}
                                  />
                                </div>
                                <div className="text-start">
                                  <RadioButton
                                    name="RB"
                                    value={1}
                                    label="Booklogic"
                                    checked={kontratEkleData.Chanel_no == 1}
                                    onChange={(e) => {
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Chanel_no: 1, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                      }));
                                    }}
                                  />
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-md-4">
                          <Card>
                            <Card.Header>
                              <Card.Title>
                                OTA Bağlantılarda Ad Soyad Sırası
                              </Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="d-flex flex-column justify-content-start">
                                <div className="text-start">
                                  <RadioButton
                                    name="OTA"
                                    value={0}
                                    label="Ad soyad"
                                    checked={kontratEkleData.Adsoyad_10 == 0}
                                    onChange={(e) => {
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Adsoyad_10: 0, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                      }));
                                    }}
                                  />
                                </div>
                                <div className="text-start">
                                  <RadioButton
                                    name="OTA"
                                    value={1}
                                    label="Soyad Ad"
                                    checked={kontratEkleData.Adsoyad_10 == 1}
                                    onChange={(e) => {
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Adsoyad_10: 1, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                      }));
                                    }}
                                  />
                                </div>
                                <div className="text-start">
                                  <RadioButton
                                    name="OTA"
                                    value={2}
                                    label="Ad Soyad Bitişik"
                                    checked={kontratEkleData.Adsoyad_10 == 2}
                                    onChange={(e) => {
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Adsoyad_10: 2, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                      }));
                                    }}
                                  />
                                </div>
                                <div className="text-start">
                                  <RadioButton
                                    name="OTA"
                                    value={3}
                                    label="Soyad Ad Bitişik"
                                    checked={kontratEkleData.Adsoyad_10 == 3}
                                    onChange={(e) => {
                                      setKontratEkleData((prevData) => ({
                                        ...prevData,
                                        Adsoyad_10: 3, // veya 1 veya 3 olarak değiştirilebilir, hangisi uygunsa
                                      }));
                                    }}
                                  />
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Iletisim" title="Iletişim">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-4">
                          <Card>
                            <Card.Header>
                              <Card.Title>Sorumlu Bilgileri</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Iletişim/Mail
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="text"
                                        placeholder="Iletişim/Mail"
                                        value={kontratEkleData.Sorumlu_mail}
                                        onChange={(e) => {
                                          const newValue = e.target
                                            .value as string;
                                          setKontratEkleData((prevData) => ({
                                            ...prevData,
                                            Sorumlu_mail: newValue,
                                          }));
                                        }}
                                      ></Input>
                                    </div>
                                  </div>
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Ad/Soyad
                                      </span>
                                      <div className="w-60 d-flex">
                                        <Input
                                          className=""
                                          type="text"
                                          placeholder="Ad Soyad"
                                          value={kontratEkleData.Sorumlu}
                                          onChange={(e) => {
                                            const newValue = e.target
                                              .value as string;
                                            setKontratEkleData((prevData) => ({
                                              ...prevData,
                                              Sorumlu: newValue,
                                            }));
                                          }}
                                        ></Input>
                                        <Input
                                          className=""
                                          type="text"
                                          placeholder="Telefon"
                                          value={kontratEkleData.Sorumlu_tel}
                                          onChange={(e) => {
                                            const newValue = e.target
                                              .value as string;
                                            setKontratEkleData((prevData) => ({
                                              ...prevData,
                                              Sorumlu_tel: newValue,
                                            }));
                                          }}
                                        ></Input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                        {/* <div className="col-md-4">
                          <Card>
                            <Card.Header>
                              <Card.Title>Muhasebe Sorumlu Bilgileri</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Iletişim/Mail
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="text"
                                        placeholder="Iletişim/Mail"
                                      ></Input>
                                    </div>
                                  </div>
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Ad/Soyad
                                      </span>
                                      <div className="w-60 d-flex">
                                        <Input
                                          className=""
                                          type="text"
                                          placeholder="Iletişim/Mail"
                                        ></Input>
                                        <Input
                                          className=""
                                          type="text"
                                          placeholder="Iletişim/Mail"
                                        ></Input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-md-4">
                          <Card>
                            <Card.Header>
                              <Card.Title>Rezervasyon Bilgileri</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Iletişim/Mail
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="text"
                                        placeholder="Iletişim/Mail"
                                      ></Input>
                                    </div>
                                  </div>
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Ad/Soyad
                                      </span>
                                      <div className="w-60 d-flex">
                                        <Input
                                          className=""
                                          type="text"
                                          placeholder="Iletişim/Mail"
                                        ></Input>
                                        <Input
                                          className=""
                                          type="text"
                                          placeholder="Iletişim/Mail"
                                        ></Input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-md-4 mt-2">
                          <Card>
                            <Card.Header>
                              <Card.Title>Stop Sale Bilgileri</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Iletişim/Mail
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="text"
                                        placeholder="Iletişim/Mail"
                                      ></Input>
                                    </div>
                                  </div>
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Ad/Soyad
                                      </span>
                                      <div className="w-60 d-flex">
                                        <Input
                                          className=""
                                          type="text"
                                          placeholder="Iletişim/Mail"
                                        ></Input>
                                        <Input
                                          className=""
                                          type="text"
                                          placeholder="Iletişim/Mail"
                                        ></Input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-md-4 mt-2">
                          <Card>
                            <Card.Header>
                              <Card.Title>Satış Departmanı</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Iletişim/Mail
                                      </span>
                                      <Input
                                        className="w-60"
                                        type="text"
                                        placeholder="Iletişim/Mail"
                                      ></Input>
                                    </div>
                                  </div>
                                  <div className=" gap-2 d-flex flex-column">
                                    <div className="d-flex align-items-center justify-content-Start">
                                      <span className="w-40 d-flex justify-content-start align-items-center">
                                        Ad/Soyad
                                      </span>
                                      <div className="w-60 d-flex">
                                        <Input
                                          className=""
                                          type="text"
                                          placeholder="Iletişim/Mail"
                                        ></Input>
                                        <Input
                                          className=""
                                          type="text"
                                          placeholder="Iletişim/Mail"
                                        ></Input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div> */}
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};
