import React, { useCallback, useEffect, useState } from 'react';
import Header from '../Pages/Header/Header';
import Sidebar from '../Pages/Sidebar/Sidebar';
import Content from '../Pages/Content/Content';
import Footer from '../Pages/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import './Dashboard.scss';

const Dashboard: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [selectedMenu, setSelectedMenu] = useState<number | null>(24); //iş bitince null yapılacak
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartY, setDragStartY] = useState(0);
    const [infoButtonY, setInfoButtonY] = useState(10);
    const dragThreshold = 10;
    const [hasDragged, setHasDragged] = useState(false);
    const navigate = useNavigate();

    const toggleInfo = () => {
        setIsInfoOpen(!isInfoOpen);
    };

    useEffect(() => {
        const storedAuth = localStorage.getItem('isAuthenticated');
        if (storedAuth === 'false' && localStorage.getItem('token')) {
            navigate("/Login");
        }
    }, [navigate]);

    useEffect(() => {
        if (!localStorage.getItem("Language")) {
            localStorage.setItem("Language", "tr");
        }
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMenuSelection = (menuId: number) => {
        if (selectedMenu === menuId) {
            setSelectedMenu(null); // Resetting to trigger re-render
            setTimeout(() => {
                setSelectedMenu(menuId); // Setting it back after a delay
            }, 0);
        } else {
            setSelectedMenu(menuId);
        }
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsDragging(true);
        setHasDragged(false);
        setDragStartY(e.clientY);
    };

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            if (isDragging) {
                const moveDistance = Math.abs(e.clientY - dragStartY);
                if (moveDistance > dragThreshold) {
                    setHasDragged(true);
                }
                const newY = infoButtonY + ((e.clientY - dragStartY) / window.innerHeight) * 100;
                setInfoButtonY(Math.max(0, Math.min(100, newY)));
                setDragStartY(e.clientY);
            }
        },
        [isDragging, dragStartY, infoButtonY]
    );

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    useEffect(() => {
        if (isDragging) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
        }

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isDragging, handleMouseMove, handleMouseUp]);

    const handleInfoClick = () => {
        if (!hasDragged) {
            toggleInfo();
        }
    };

    const handleMenuSelect = (menuId: number) => {
        setSelectedMenu(menuId);
    };

    return (
        <div className="App">
            <Header onMenuSelect={handleMenuSelect} />
            <div className="App-body">
                <Sidebar
                    isOpen={isMenuOpen}
                    onMenuSelect={handleMenuSelection}
                    toggleSidebar={toggleMenu}
                />
                <Content isOpen={isMenuOpen} selectedMenu={selectedMenu} />
            </div>
            <div className="footer-container">
                <Footer />
            </div>
        </div>
    );
};

export default Dashboard;
