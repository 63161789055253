import React, { MouseEvent, useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import useContextMenu from "../../Pages/Modal/SagTikMenu/ContextMenu";
import { Form, Dropdown, Button } from "react-bootstrap";
import "./DynamicTable.scss";
interface DataItem {
  [key: string]: any;
}

interface MenuItem {
  label: string;
  icon?: JSX.Element;
  onClick: (item: DataItem) => void;
}

interface Props {
  data: DataItem[];
  menuItems?: MenuItem[];
  onItemSelected?: (item: DataItem | null) => void;
  columnNamesMap: Record<string, string>; // Güncellenen tip
  hiddenColumns?: string[]; // Başlangıçta gizlenecek sütunlar

}
interface CheckboxDropdownProps {
  headers: string[];
  visibleColumns: Record<string, boolean>;
  toggleColumnVisibility: (header: string) => void;
  selectAllColumns: () => void;
  deselectAllColumns: () => void;
}

const DynamicTable: React.FC<Props> = ({
  data,
  menuItems,
  onItemSelected,
  columnNamesMap,
  hiddenColumns,
}) => {
  const [selectedItem, setSelectedItem] = useState<DataItem | null>(null);
  const [totalPages, setTotalPages] = useState(0); //toplam sayfa sayısı
  const [visibleColumns, setVisibleColumns] = useState<{
    [key: string]: boolean;
  }>({});
  //Sütun Sıralamasını Değiştirecek Fonksiyon
  // Başlangıçta, veri setinin ilk öğesinden sütun başlıklarını alıyoruz.
  const [columns, setColumns] = useState<string[]>([]);
  // Sürüklenen sütunun başlangıç indeksini tutacak değişken.
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [filters, setFilters] = useState<Record<string, string>>({});
const [search, setSearch] = useState<boolean>(false);
useEffect(() => {
  if (data.length > 0) {
    const newColumns = Object.keys(data[0]);
    setColumns(newColumns);

    const initialVisibility: Record<string, boolean> = newColumns.reduce((acc: Record<string, boolean>, key: string) => {
      // hiddenColumns prop'u belirtilmişse ve bir sütun bu listedeyse, o sütunu gizle
      acc[key] = !(hiddenColumns && hiddenColumns.includes(key));
      return acc;
    }, {});

    setVisibleColumns(initialVisibility);
  }
}, [data, hiddenColumns]); // hiddenColumns değişikliklerini takip et

  const handleDragStart = (index: number) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (
    event: React.DragEvent<HTMLTableHeaderCellElement>
  ) => {
    event.preventDefault(); // Sürüklenen öğenin bırakılabilir olmasını sağlar.
  };

  const handleDrop = (index: number) => {
    if (draggedIndex === null) return;

    const newColumns = [...columns];
    const [removed] = newColumns.splice(draggedIndex, 1);
    newColumns.splice(index, 0, removed);

    setColumns(newColumns);
    setDraggedIndex(null); // Sürükleme işlemi tamamlandıktan sonra sıfırla.
  };
  //Sütun Sıralamasını Değiştirecek Fonksiyon

  useEffect(() => {
    if (onItemSelected) {
      onItemSelected(selectedItem);
    }
  }, [selectedItem, onItemSelected]);

  const adaptedMenuItems = menuItems?.map((menuItem) => ({
    ...menuItem,
    onClick: () => {
      if (selectedItem) {
        menuItem.onClick(selectedItem);
      }
    },
  }));
  //context menu çalışıcak fonksiyonlar
  const handleRowContextMenu = (event: MouseEvent, item: DataItem) => {
    event.preventDefault();
    setSelectedItem(item);
    handleContextMenu(event);
  };

  const { handleContextMenu, renderContextMenu } = useContextMenu(
    adaptedMenuItems || []
  );

  //context menu çalışıcak fonksiyonlar

  //   tablo stunlarunı filtreleme işlemi
  const toggleColumnVisibility = (columnName: string) => {
    setVisibleColumns((prevColumns) => ({
      ...prevColumns,
      [columnName]: !prevColumns[columnName],
    }));
  };
  const selectAllColumns = () => {
    const allVisible = columns.reduce(
      (acc: Record<string, boolean>, column: string) => {
        acc[column] = true;
        return acc;
      },
      {}
    );
    setVisibleColumns(allVisible);
  };

  const deselectAllColumns = () => {
    const noneVisible = columns.reduce(
      (acc: Record<string, boolean>, column: string) => {
        acc[column] = false;
        return acc;
      },
      {}
    );
    setVisibleColumns(noneVisible);
  };
  //   tablo stunlarunı filtreleme işlemi

  //tablo sayfalandırma işlemleri

  // Filtre metnini güncelleyecek bir fonksiyon
  const updateFilter = (column: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value,
    }));
  };

  // Verileri filtreleyecek bir fonksiyon
  const filteredData = data.filter((item) =>
    columns.every(
      (column) =>
        !filters[column] ||
        item[column]
          .toString()
          .toLowerCase()
          .includes(filters[column].toLowerCase())
    )
  );
  return (
    <>
      <div
        className="container-fluid rounded-top  mt-2"
        style={{ background: "#828B94 " }}
      >
        <div className="row">
          {/*moduler menu*/}
          <div className="col-12 d-flex justify-content-end p-0 m-0">
            <div className="mx-3 mt-2">
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Aramayı Etkinleştir"
                className="text-white"
                style={{ cursor: "pointer" }}
                onChange={() => {
                  setSearch(!search);
                  console.log(search);
                }}
              />
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle
                  className="text-white  b-left-white d-buton-hover "
                  variant=""
                  id="dropdown-basic"
                >
                  Kolon Seç
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="p-2">
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="mr-2"
                      onClick={selectAllColumns}
                    >
                      Hepsini Seç
                    </Button>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={deselectAllColumns}
                    >
                      Hiçbirini Seçme
                    </Button>
                  </div>
                  <div
                    className="d-flex flex-column px-2"
                    style={{ cursor: "pointer" }}
                  >
                    {columns.map((header) => (
                      <Form.Check
                        inline
                        label={header}
                        type="checkbox"
                        id={`checkbox-${header}`}
                        checked={visibleColumns[header]}
                        onChange={() => toggleColumnVisibility(header)}
                        key={header}
                      />
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-0">
            <Table responsive striped bordered hover className="overlow-auto">
              <thead>
                <tr>
                  {columns
                    .filter((column) => visibleColumns[column])
                    .map((column, index) => (
                      <th
                        key={column}
                        draggable
                        onDragStart={() => handleDragStart(index)}
                        onDragOver={handleDragOver}
                        onDrop={() => handleDrop(index)}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center">
                          {columnNamesMap[column] || column}{" "}
                          {/* Burada maskleme yapılıyor */}
                          {search && (
                          <input
                          type="text"
                          placeholder="Ara..."
                          onChange={(e) =>
                            updateFilter(column, e.target.value)
                          }
                          className="form-control form-control-sm r-input-sm"
                        />
                          )
                            }
                          </div>
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>

              <tbody>
                {filteredData.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns
                      .filter((column) => visibleColumns[column])
                      .map((column) => (
                        <td
                          key={column}
                          onContextMenu={(e) => handleRowContextMenu(e, item)}
                          //dışarından gelen veriye göre renklendirme yapılabilir
                          // style={{
                          //   backgroundColor: item.someField ? '#c8e6c9' : '#ffcdd2', // Örneğin, 'someField' değerine göre renk ataması
                          // }}
                        >
                          {item[column]}
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </Table>
            {renderContextMenu()}
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicTable;
