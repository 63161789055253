import React, { useEffect, useState } from "react";
import "./Footer.css";
import Loader from "react-js-loader";
import DropdownDilButton from "../../Components/DropdownDil/TranslateButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faWifi,
  faPhone,
  faHeadset,
  faCloud
} from "@fortawesome/free-solid-svg-icons";
import { HotelComboBox } from "../Header/Header";

function Footer() {
  // State to control database status
  const [dbStatus, setDbStatus] = useState(1); // Assuming default status is 1
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumbertext, setPhoneNumbertext] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [speedTestResult, setSpeedTestResult] = useState({
    downloadSpeed: 0,
    uploadSpeed: 0,
    ping: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  // Determine Loader color and text based on dbStatus
  let loaderColor, statusText;
  if (dbStatus === 1) {
    loaderColor = "#a3b18a"; // Green for active
    statusText = "Veritabanı Bağlantıları";
  } else if (dbStatus === 2) {
    loaderColor = "#e63946"; // Red for inactive
    statusText = "Veritabanı Bağlantıları";
  }

  const performSpeedTest = () => {
    setIsLoading(true);
    fetch("http://localhost:3001/speedtest")
      .then((response) => response.json())
      .then((data) => {
        setSpeedTestResult({
          downloadSpeed: data.downloadSpeed,
          uploadSpeed: data.uploadSpeed,
          ping: data.ping,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Hız testi sırasında bir hata oluştu:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // performSpeedTest();
    // Function to update phone number based on time
    const updatePhoneNumber = () => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();

      // Between 9:00 and 18:30
      if (
        (currentHour >= 9 && currentHour < 18) ||
        (currentHour === 18 && currentMinutes <= 30)
      ) {
        setPhoneNumber("0 (242) 227 87 31");
        setPhoneNumbertext("Merkez Ofis");
      } else {
        // Other times (before 9:00 and after 18:30)
        setPhoneNumber("0 (555) 998 6175");
        setPhoneNumbertext("Nöbetçi");
      }
    };

    // Update phone number on component mount and every minute
    updatePhoneNumber();
    const interval = setInterval(updatePhoneNumber, 60000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="footer h-100">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-sm-2 d-flex h-100 footer-sm justify-content-center">
            
            {/* <Loader type="bubble-ping" bgColor={loaderColor} size={35}  /> */}
            <div className="d-flex w-75 justify-content-center align-items-center">
              <p className="p-0 m-0 w-100"> <HotelComboBox /></p>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon
                icon={faCloud}
                color="#a3b18a"
                style={{ marginRight: "5px" }}
              />
              <span>Yedekleme Sistemi</span>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center align-items-center">
              <DropdownDilButton />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center align-items-center">
              {isLoading ? (
                <div onClick={performSpeedTest} style={{ cursor: "pointer" }}>
                  Hız testi yapılıyor...
                </div>
              ) : (
                <div className="">
                  <div className="d-flex ">
                    <div className="m-1 ">
                      <FontAwesomeIcon icon={faArrowDown} color="#a3b18a" />{" "}
                      {speedTestResult.downloadSpeed < 100
                        ? speedTestResult.downloadSpeed.toFixed(1)
                        : speedTestResult.downloadSpeed.toFixed(0)}{" "}
                      Mbps
                    </div>
                    <div className="m-1">
                      <FontAwesomeIcon icon={faArrowUp} color="#e63946" />{" "}
                      {speedTestResult.uploadSpeed < 100
                        ? speedTestResult.uploadSpeed.toFixed(1)
                        : speedTestResult.uploadSpeed.toFixed(0)}{" "}
                      Mbps
                    </div>
                    <div className="m-1">
                      <FontAwesomeIcon icon={faWifi} color="#a3b18a" />{" "}
                      {speedTestResult.ping < 100
                        ? speedTestResult.ping.toFixed(1)
                        : speedTestResult.ping.toFixed(0)}{" "}
                      ms
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon
                icon={faHeadset}
                color="#a3b18a"
                style={{ marginRight: "5px" }}
              />
              <span>Destek</span>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon
                icon={faPhone}
                style={{
                  marginRight: "5px",
                }}
                color="#a3b18a"
              />{" "}
              {phoneNumbertext}: {phoneNumber}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
