import axios, { AxiosError } from 'axios';

// POST isteği için gönderilecek veri türü
interface PostData {
  [key: string]: any;
}

// API'den alınacak yanıtın türü
interface ResponseData {
  [key: string]: any;
}

// Axios POST isteği yapan yardımcı fonksiyon
const postRequest = async (url: string, data: PostData, token: string): Promise<ResponseData> => {
  try {
    const response = await axios.post<ResponseData>(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log(`Response: ${url}`, response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios kaynaklı hata
      console.error('Axios error:', error.message);

      if (error.response) {
        // Sunucu yanıt verdi ancak hata durum kodu döndü
        console.error('Response error data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);

        if (error.response.status === 401) {
          throw new Error('Unauthorized: Invalid or missing token.');
        }

        throw new Error(`Server responded with status code ${error.response.status}: ${JSON.stringify(error.response.data)}`);
      } else if (error.request) {
        // İstek gönderildi ancak yanıt alınmadı
        console.error('Request error:', error.request);
        throw new Error('No response received from server');
      } else {
        // İstek hazırlığında bir hata oluştu
        console.error('Unexpected error during request setup:', error.message);
        throw new Error('Unexpected error occurred during request setup');
      }
    } else {
      // Axios dışında bir hata oluştu
      console.error('Unexpected non-Axios error:', (error as Error).message);
      throw new Error('An unexpected error occurred');
    }
  }
};

export default postRequest;
