import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../../../Context/LanguageContext/LanguageContext";
import { ListGroup, ProgressBar, Tab, Table, Tabs } from "react-bootstrap";
import Modal from "../../../../Modal/Modal/Modal";
import { MisafirIslemeriApi } from "../MisafirIslemleriApi/MisafirIslemleriApi";
import { LoyaltyTanimlamalarApi } from "../../LoyaltyTanimlamalar/LoyaltyTanimlamalarApi";
import OnayBekleyenMisafirler from "./OnayBekleyenMisafirler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RxUpdate } from "react-icons/rx";
import {
  faTrashAlt,
  faTimes,
  faEllipsisV,
  faClose,
  faUpRightAndDownLeftFromCenter,
  faCreditCard,
  faUser,
  faGift,
  faHourglass,
  faHourglass1,
} from "@fortawesome/free-solid-svg-icons";
import "./MisafirIslemleri.css";
import useContextMenu from "../../../../Modal/SagTikMenu/ContextMenu";
import axios from "axios";
import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import "@progress/kendo-theme-default/dist/all.css";
import DynamicTable from "../../../../../Components/DinamikTable/DynamicTable";
import FirsatKullanimListesi from "./FirsatKullanimListesi";
import InfoPanel from "../../../../../Components/InfoPanel/InfoPanel";
import { FaCreditCard, FaHourglass, FaNodeJs, FaReact } from "react-icons/fa";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
// Misafir türünü tanımla
type Misafir = {
  misafirID: number;
  misafirAdi: string;
  misafirSoyadi: string;
  misafirKarti: string;
  misafirKartID: number;
};
interface UpdateResult {
  [key: string]: {
    puan: number;
    harcananPuanToplami: number;
    sonPuan: number;
    hata?: boolean;
  };
}
type Firsat = {
  firsatID: number;
};
type MisafirIslemleriRequest = {
  otelGuid: string;
  misafirID: number;
  firsatID?: number; // Eğer firsatID opsiyonel ise
  // veya
};
type ModalState = {
  isOpen: boolean;
  modalType:
    | "firsatTanimla"
    | "puanGuncelle"
    | "firsatHareketGoruntule"
    | "kartTipiGuncelle"
    | "PuanGuncelle"
    | null; // Modal türlerini tanımla
  selectedMisafir?: Misafir;
  selectedFirsat?: Firsat;
  onConfirm?: () => void;
  title: string;
  content?: React.ReactNode;
};
interface Hareket {
  firsatID: string;
  hareketTipi: "Tanımlama" | "Iptal";
  // Diğer olası alanlar...
}
const getDefaultDate = () => {
  const today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1; // getMonth() 0'dan başlar
  let day = today.getDate();
  let hours = today.getHours();
  let minutes = today.getMinutes();

  // Ay, gün, saat ve dakika için iki haneli string değerler oluştur
  const monthStr = month < 10 ? `0${month}` : month.toString();
  const dayStr = day < 10 ? `0${day}` : day.toString();
  const hoursStr = hours < 10 ? `0${hours}` : hours.toString();
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes.toString();

  return `${year}-${monthStr}-${dayStr}T${hoursStr}:${minutesStr}`;
};

const LoyaltyMisafirTabPanel: React.FC<{ otelGuid: string }> = ({
  otelGuid,
}) => {
  const [activeTab, setActiveTab] = useState<string>("Misafirlistele");
  const [refreshKartList, setRefreshKartList] = useState<boolean>(false);
  const languageContext = useContext(LanguageContext);
  const { translations = {} } = languageContext || {};

  return (
    <Tabs activeKey={activeTab} onSelect={(k) => k && setActiveTab(k)}>
      <Tab eventKey="Misafirlistele" title="Kayıtlı Misafirler">
        <MisafirIslemleri otelGuid={otelGuid} />
      </Tab>
      <Tab eventKey="OnayBekleyen" title="Onay Bekleyen Misafirler">
        <OnayBekleyenMisafirler otelGuid={otelGuid} />{" "}
      </Tab>
      <Tab eventKey="FirsatKullanimListesi" title="Fırsat Kullanım Listesi">
        <FirsatKullanimListesi otelGuid={otelGuid} />{" "}
      </Tab>
    </Tabs>
  );
};

const MisafirIslemleri: React.FC<{ otelGuid: string }> = ({ otelGuid }) => {
  const [guests, setGuests] = useState<any[]>([]); // Adjust the type based on your guest structure
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [firsatListesi, setFirsatListesi] = useState<any[]>([]);
  const [kartlar, setKartlar] = useState<any[]>([]);
  const menuItems = [
    {
      label: "Fırsat tanımla",
      onClick: () => {
        if (selectedItem) {
          tanimlaFirsat(selectedItem);
          openFirsatTanimlaModal(selectedItem);
        }
      },
    },
    // {
    //   label: "Puan Güncelle",
    //   onClick: () => {
    //     if (selectedItem) {
    //       tanimlaFirsat(selectedItem);
    //       openPuanGuncelleModal(selectedItem);
    //     }
    //   },
    // },
    // {
    //   label: "Fırsat Hareketlerini Görüntüle",
    //   onClick: () => {
    //     if (selectedItem) {
    //       openFirsatHareketGoruntuleModal(selectedItem);
    //     }
    //   },
    // },
    {
      label: "Kart Tipini Güncelle",
      onClick: () => {
        if (selectedItem) {
          openKartTipiGuncelleModal(selectedItem);
        }
      },
    },
  ];
  const { handleContextMenu, renderContextMenu } = useContextMenu(menuItems);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPuanGuncelleOpen, setIsModalPuanGuncelleOpen] = useState(false);
  const [selectedMisafir, setSelectedMisafir] = useState<Misafir | null>(null);
  const [selectedFirsat, setSelectedFirsat] = useState<Firsat | null>(null);
  //selectedItem state'i kullanımı
  const [selectedItem, setSelectedItem] = useState<any>([]);
  //selectedItem state'i kullanımı

  const [selectedKart, setSelectedKart] = useState<any>([]); // Kart tipi güncelleme için kullanılacak
  const [modalState, setModalState] = useState<ModalState>({
    isOpen: false,
    modalType: null,
    title: "",
  });
  const [selectedDate, setSelectedDate] = useState<string>(getDefaultDate());
  const [firsatHareketleri, setFirsatHareketleri] = useState<any[]>([]);
  const [updatePuan, setUpdatePuan] = useState<string>(""); // Puan güncelleme için kullanılacak

  useEffect(() => {
    console.log("selectedItem", selectedItem);
    setSelectedMisafir(selectedItem);
    console.log("selectedMisafir", selectedMisafir);
  }, [selectedItem]); // Buradaki dizi, bu effect'in yalnızca `selectedItem` değişkeni değiştiğinde çalışmasını sağlar.

  const tanimlaFirsat = (misafir: Misafir) => {
    setSelectedMisafir(misafir);
  };

  const handleSelectMisafir = (misafir: Misafir, event: React.MouseEvent) => {
    event.preventDefault();
    setSelectedMisafir(misafir);
    handleContextMenu(event); // useContextMenu hook'undan gelen fonksiyon
  };
  const handleFirsatTanimla = async () => {
    try {
      if (!selectedMisafir || !selectedFirsat) {
        console.error("Misafir veya Fırsat seçilmedi!");
        // İşlemi durdur veya hata mesajı göster
        return;
      }

      // selectedMisafir ve selectedFirsat burada null olamaz
      const response = await MisafirIslemeriApi.LoyaltyMisafirIslemleri({
        islem: "firsattanimla",
        // otelGuid,
        misafirID: selectedMisafir.misafirID,
        firsatId: selectedFirsat.firsatID, // Changed firsatID to firsatId
        // islemTarihi: selectedDate || getDefaultDate(),
      });
      fetchGuests(); // Misafirleri yeniden yükle
      console.log("Fırsat tanımlandı:", response.data);
      // setIsModalOpen(false); // İşlem başarılıysa modalı kapat
      setModalState({ ...modalState, isOpen: false });
    } catch (error) {
      console.error("Fırsat tanımlama hatası:", error);
    }
  };

  const fetchGuests = async () => {
    try {
      setIsLoading(true);
      const response = await MisafirIslemeriApi.LoyaltyMisafirIslemleri({
        otelGuid,
        islem: "listele",
        firsatId: 0,
      });
      console.log("response", response.data);
      // Sadece ilgili alanları filtreleyin
      const filteredData = response.data.map((guest: any) => ({
        misafirID: guest.misafirID,
        misafirAdi: guest.misafirAdi,
        misafirSoyadi: guest.misafirSoyadi,
        misafirMail: guest.misafirMail,
        misafirPuan: guest.misafirPuan,
        misafirKarti: guest.misafirKarti,
        misafirKartID: guest.misafirKartID,
      }));

      setGuests(filteredData); // Filtrelenmiş veriyi state'e kaydet
      console.log("Misafirler:", filteredData);
    } catch (error) {
      console.error("Error fetching guests:", error);
      // Hata yönetimi
    } finally {
      setIsLoading(false);
    }
  };

  const puanGuncelle = async () => {
    try {
      if (!selectedMisafir) {
        console.error("Misafir seçilmedi!");
        return;
      }

      const response = await MisafirIslemeriApi.LoyaltyMisafirIslemleri({
        misafirID: selectedMisafir.misafirID,
        misafirPuan: parseInt(updatePuan), // Seçilen puanı gönder
        islem: "puanguncelle",
      });
      fetchGuests(); // Misafir listesini yenile
      console.log("Puan güncellendi:", response.data);
      setModalState({ ...modalState, isOpen: false });
    } catch (error) {
      console.error("Puan güncelleme hatası:", error);
    }
  };
  // const fetchFirsatlar = async () => {
  //   try {
  //     const response = await LoyaltyTanimlamalarApi.LoyaltyFirsatCek(otelGuid);
  //     if(response?.status === 200){
  //       setFirsatListesi(response.data);
  //       console.log("Fırsatlar: 200", response.data);
  //     }
  //     else{
  //       console.log("Fırsatlar listelenemedi",response?.status);
  //     }

  //   } catch (error) {
  //     console.error("Error fetching firsatlar:", error);
  //     // Handle the error appropriately
  //   }
  // };
  const FectchKartlar = async () => {
    try {
      const response = await LoyaltyTanimlamalarApi.LoyaltyKartIslemleri({
        otelGuid,
        islem: "listele",
      });
      setKartlar(response.data || []);
      console.log("Kartlar:", response.data);
    } catch (error) {
      console.error("Error fetching kartlar:", error);
    }
  };
  // kartlar dizisi değiştiğinde, selectedKart'ı ilk kartın ID'si ile güncelle
  useEffect(() => {
    if (kartlar.length > 0) {
      setSelectedKart(kartlar[0].kartID);
    }
  }, [kartlar]); // Bağımlılıklar dizisinde kartlar var, bu yüzden kartlar değiştiğinde bu useEffect çalışır
  // const uygunFirsatlar = firsatListesi.filter(
  //   (firsat) => firsat.kartAdi === selectedMisafir?.misafirKarti
  // );
  // useEffect hook'u kullanarak bileşen yüklendiğinde ilk fırsatı seç
  // useEffect(() => {
  //   if (uygunFirsatlar.length > 0) {
  //     setSelectedFirsat(uygunFirsatlar[0]);
  //   }
  // }, [uygunFirsatlar]); // Bağımlılık dizisine uygunFirsatlar'ı ekleyin, böylece bu liste her değiştiğinde useEffect tekrar çalışır
  // console.log("Uygun fırsatlar:", uygunFirsatlar);

  useEffect(() => {
    FectchKartlar();
    fetchGuests();
    // fetchFirsatlar();
  }, [otelGuid]); // Refetch when otelGuid changes

  if (isLoading) {
    return <div>Loading...</div>; // Render a loading state
  }
  const renderModalContent = () => {
    switch (modalState.modalType) {
      case "firsatTanimla":
        return (
          <>
            <div className="modal-header1">
              Fırsat Tanımla
              <FontAwesomeIcon
                icon={faClose}
                className="close-icon"
                onClick={() => setModalState({ ...modalState, isOpen: false })}
              />
            </div>
            <div className="modal-content1">
              <form className="p-4">
                <div className="mb-3">
                  <div className="row">
                    <div className="col-6">
                      <p className="text-start">
                        <FontAwesomeIcon icon={faUser} />
                        <span className="ms-3"></span>
                        Misafir
                      </p>
                    </div>
                    <div className="col-6">
                      <Input
                        type="text"
                        className="form-control r-input-sm"
                        id="misafirAdi"
                        value={selectedMisafir?.misafirAdi}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-6">
                      <p className="text-start">
                        <FontAwesomeIcon icon={faGift} />
                        <span className="ms-3"></span>
                        Fırsat Seçimi
                      </p>
                    </div>
                    <div className="col-6">
                      <select
                        className="form-select r-input-sm "
                        id="firsatSecimi"
                        value={selectedFirsat?.firsatID || ""}
                        onChange={(e) => {
                          const firsat = firsatListesi.find(
                            (f) => f.firsatID === parseInt(e.target.value)
                          );
                          setSelectedFirsat(firsat || null);
                        }}
                      >
                        {firsatListesi
                          .filter((firsat) => firsat.puanMi)
                          .map((firsat) => (
                            <option
                              key={firsat.firsatID}
                              value={firsat.firsatID}
                            >
                              {firsat.firsatAdi} puan: {firsat.puan}
                            </option>
                          ))}
                        {/* {uygunFirsatlar.map((firsat) => (
                      <option key={firsat.firsatID} value={firsat.firsatID}>
                        {firsat.firsatAdi} puan: {firsat.firsatPuani}
                      </option>
                    ))} */}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-6">
                      <p className="text-start">
                        <FontAwesomeIcon icon={faHourglass1} />
                        <span className="ms-3"></span>
                        İşlem Tarihi ve Saati (opsiyonel):
                      </p>
                    </div>
                    <div className="col-6">
                      <input
                        type="datetime-local"
                        className="form-control r-input-sm"
                        id="islemTarihi"
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </div>
                    <div className="col-12">
                      <div id="tarihAciklama" className="form-text">
                        <FontAwesomeIcon icon={faHourglass} />
                        <span className="ms-3"></span>
                        Tarih alanı boş geçilebilir. Boş geçilirse, fırsat bugün
                        için tanımlanır. İleri bir tarih seçmek isterseniz,
                        tarih seçiniz.
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer1">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleFirsatTanimla}
              >
                <i className="fa fa-check" aria-hidden="true"></i> Fırsatı
                Tanımla
              </button>
            </div>
          </>
        );

      case "puanGuncelle":
        return (
          <>
            <div className="modal-header">Puan Güncelle</div>
            <form className="modal-content" onSubmit={puanGuncelle}>
              <label>
                Yeni Puan:
                <input
                  type="number"
                  value={updatePuan}
                  onChange={(e) => setUpdatePuan(e.target.value)}
                  required
                />
              </label>
              <button type="submit">Puanı Güncelle</button>
            </form>
          </>
        );
      case "firsatHareketGoruntule":
        return (
          <>
            <div className="modal-header">Fırsat Hareketlerini Görüntüle</div>
            <div className="modal-content">
              <table className="table">
                <thead>
                  <tr>
                    <th>Fırsat adı</th>
                    <th>İşlem Tipi</th>
                    <th>tanımlanan Tarih</th>
                    <th>Kullanılacağı Tarih</th>
                    <th>Fırsat puanı</th>
                    <th>Fırsat iptal</th>
                  </tr>
                </thead>
                <tbody>
                  {firsatHareketleri.map((hareket, index) => (
                    <tr key={hareket.hrkID}>
                      <td>{hareket.firsatAdi}</td>
                      <td>{hareket.hareketTipi}</td>
                      <td>
                        {new Date(hareket.tarih).toLocaleDateString("tr-TR")}
                      </td>
                      <td>
                        {new Date(hareket.islemTarihi).toLocaleString("tr-TR")}
                      </td>
                      <td>{hareket.firsatPuani}</td>
                      <td>
                        {ButonFilter(hareket, firsatHareketleri) && (
                          <button
                            className="btn btn-light "
                            onClick={() => {
                              FirsatIptal(
                                hareket.hrkID,
                                selectedMisafir?.misafirID
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} /> iptal et
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        );
      case "kartTipiGuncelle":
        return (
          <>
            <div className="modal-header1">
              {modalState.title}{" "}
              <FontAwesomeIcon
                icon={faClose}
                className="close-icon"
                onClick={() => setModalState({ ...modalState, isOpen: false })}
              />
            </div>
            <div className="modal-content1">
              {/* <select
                value={selectedKart}
                onChange={(e) => setSelectedKart(e.target.value)}
              >
                {kartlar.map((kart) => (
                  <option key={kart.kartID} value={kart.kartID}>
                    {kart.kartAdi}
                  </option>
                ))}
              </select> */}
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <p className=" text-start m-0 p-0 mt-1">
                      <FontAwesomeIcon icon={faCreditCard} />
                      <span className="ms-2"></span>
                      Kart Tipi Seçiniz
                    </p>
                  </div>
                  <div className="col-6">
                    <DropDownList
                      data={kartlar}
                      textField="kartAdi"
                      dataItemKey="kartID"
                      value={selectedKart || "Kart Tipi Seçiniz"}
                      onChange={(e) => {
                        setSelectedKart(e.target.value);
                      }}
                      className="r-input-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer1">
              <button
                className="btn r-buton-orange"
                onClick={handleKartTipiGuncelle}
              >
                <RxUpdate /> <span className="ms-2"></span> Kartı Güncelle
              </button>
            </div>
          </>
        );
      case "PuanGuncelle":
        return (
          <>
            <div className="modal-header">Puan Güncelle</div>
            <GuestList guests={guests} />
          </>
        );
      default:
        return <div>Geçersiz İşlem</div>;
    }
  };
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };
  const ButonFilter = (hareket: Hareket, firsatHareketleri: Hareket[]) => {
    const sameFirsatIDHareketler = firsatHareketleri.filter(
      (h: Hareket) => h.firsatID === hareket.firsatID
    );
    const hasTanımlama = sameFirsatIDHareketler.some(
      (h: Hareket) => h.hareketTipi === "Tanımlama"
    );
    const hasIptal = sameFirsatIDHareketler.some(
      (h: Hareket) => h.hareketTipi === "Iptal"
    );
    return hareket.hareketTipi === "Tanımlama" && hasTanımlama && !hasIptal;
  };
  const FirsatIptal = async (firsatHareketID: number, miasfirid?: number) => {
    if (!miasfirid) {
      console.error("Misafir seçilmedi!");
      return;
    }
    // Onaylama mesajı
    const onay = window.confirm(
      "Fırsatı iptal etmek istediğinize emin misiniz?"
    );
    if (!onay) {
      console.log("Fırsat iptal edilmedi.");
      return;
    }
    console.log("Fırsat iptal ediliyor:", firsatHareketID, miasfirid);
    try {
      const response = await MisafirIslemeriApi.LoyaltyMisafirIslemleri({
        islem: "firsattanimlamaiptal",
        hrkID: firsatHareketID,
        misafirID: miasfirid,
      });
      console.log("Fırsat iptal edildi:", response.data);
      fetchGuests(); // Misafir listesini yenile
      setModalState({ ...modalState, isOpen: false });
    } catch (error) {
      console.error("Fırsat iptal hatası:", error);
    }
  };

  const openFirsatTanimlaModal = (misafir: Misafir) => {
    setModalState({
      isOpen: true,
      modalType: "firsatTanimla",
      selectedMisafir: misafir,
      title: "Fırsat Tanımla",
    });
    MisafirIdFırsatCek(misafir.misafirKartID);
    console.log("misafir:", misafir);
    console.log("Fırsatlar:", firsatListesi);
    console.log("Misafir:", selectedMisafir);
  };
  const MisafirIdFırsatCek = async (kartID: any) => {
    try {
      const response = await axios.post(
        "https://api.rmosloyalty.com/api/FirsatIslemleri?islem=listele",
        { otelGuid: otelGuid, kartID: kartID }
      );
      if (response?.status === 200) {
        setFirsatListesi(response.data);
        console.log("Fırsatlar: 200", response.data);
      } else {
        console.log("Fırsatlar listelenemedi", response?.status);
      }
    } catch (error) {
      console.error("Error fetching firsatlar:", error);
      // Handle the error appropriately
    }
  };

  const openPuanGuncelleModal = (misafir: Misafir) => {
    setModalState({
      isOpen: true,
      modalType: "puanGuncelle",
      selectedMisafir: misafir,
      title: "Puan Güncelle",
    });
  };
  const openPuanGuncelle = () => {
    setModalState({
      isOpen: true,
      modalType: "PuanGuncelle",
      title: "Puan Güncelle",
    });
  };

  const openFirsatHareketGoruntuleModal = async (misafir: Misafir) => {
    try {
      const response = await LoyaltyTanimlamalarApi.LoyaltyFirsatIslemleri({
        misafirID: misafir.misafirID,
        islem: "Hareketlistele",
      });
      setFirsatHareketleri(response.data || []);
      console.log("Fırsat Hareketleri:", response.data);
      setModalState({
        isOpen: true,
        modalType: "firsatHareketGoruntule",
        title: "Fırsat Hareketlerini Görüntüle",
      });
    } catch (error) {
      console.error("Fırsat hareketlerini alma hatası:", error);
    }
  };
  const openKartTipiGuncelleModal = (misafir: Misafir) => {
    setModalState({
      isOpen: true,
      modalType: "kartTipiGuncelle",
      selectedMisafir: misafir,
      title: "Kart Tipini Güncelle",
    });
  };
  const handleKartTipiGuncelle =async () => {
    console.log("Güncellenen Kart ID:", selectedKart, "Misafir ID:", selectedMisafir?.misafirID);
    try {
      if (!selectedMisafir) {
        console.error("Misafir seçilmedi!");
        return;
      }
      if (!selectedKart) {
        console.error("Kart tipi seçilmedi!");
        return;
      }
      const response =await MisafirIslemeriApi.LoyaltyMisafirIslemleri({
        misafirID: selectedItem.misafirID,
        otelGuid:otelGuid,
        misafirKarti: selectedKart.kartAdi,
        islem: "kartguncelle",
      });
      setModalState({ ...modalState, isOpen: false });
      setTimeout(fetchGuests, 500);
    } catch (error) {
      console.error("Kart tipi güncelleme hatası:", error);
    }
    console.log(
      "Güncellenen Kart ID:",
      selectedKart,
      "Misafir ID:",
      selectedMisafir?.misafirID
    );
  };

  const puang = async () => {
    try {
      let misafirID = 29;
      const response = await axios.post(
        `https://api.rmosloyalty.com/PuanGuncelleme/${misafirID}`
      );
      console.log("Puan güncellendi:", response.data);
    } catch (error) {
      console.error("Puan güncelleme hatası:", error);
    }
  };
  const columnNamesMap1 = {
    misafirID: "#",
    misafirAdi: "Misafir Adı",
    misafirSoyadi: "Misafir Soyadı",
    misafirMail: "Misafir Mail",
    misafirPuan: " Misafir Puanı",
    misafirKarti: " Misafir Kartı",
    misafirKartID: " Misafir Kart ID",
  };
  const items = [
    {
      id: 1,
      title: "React",
      description: "",
      Icon: FaReact,
    },
    {
      id: 2,
      title: "Node.js",
      description: "",
      Icon: FaNodeJs,
    },
    // Daha fazla öğe ekleyebilirsiniz
  ];
  return (
    <div className="container-fluid mt-2">
      <div className="row p-2">
        <div className="col-md-12 rounded r-shadow-sm">
          <div className="d-flex flex-wrap ">
            <div className="col-8">
              <DynamicTable
                data={guests}
                menuItems={menuItems}
                onItemSelected={setSelectedItem}
                columnNamesMap={columnNamesMap1}
                // hiddenColumns={['misafirID', 'misafirKartID',  ]} // Örnek olarak bu sütunlar başlangıçta görünür olacak
              />
            </div>
            <div className="col-4 p-2 m-0">
              <InfoPanel items={items} header="Günlük Puan Güncelleme" />
              <button
                className="btn r-buton-orange mt-3"
                onClick={openPuanGuncelle}
              >
                <RxUpdate />
                <span className="ms-2"></span>
                Güncelleme İşlemi Başlat
              </button>
            </div>
          </div>
          {/* <h1>Misafir İşlemleri</h1> */}
          {/* <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Adı</th>
            <th>Soyadı</th>
            <th>Email</th>
            <th>puan</th>
            <th>Kart Tipi</th>
          </tr>
        </thead>
        <tbody>
          {guests.map((guest, index) => (
            <tr
              key={index}
              onContextMenu={(e) => handleSelectMisafir(guest, e)}
            >
              <td>{guest.misafirID}</td>
              <td>{guest.misafirAdi}</td>
              <td>{guest.misafirSoyadi}</td>
              <td>{guest.misafirMail}</td>
              <td>{guest.misafirPuan}</td>
              <td>{guest.misafirKarti}</td>
            </tr>
          ))}
        </tbody>
      </Table> */}
          {/* <Ferhat></Ferhat> */}
          {renderContextMenu()} {/* Context menüyü render edin */}
          {/* <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="medium"
      >
        <div className="modal-header">Fırsat Tanımla</div>
        <div className="modal-content">
          <p>Misafir: {selectedMisafir?.misafirAdi}</p>
          <select
            value={selectedFirsat?.firsatID || ""}
            onChange={(e) => {
              const firsat = firsatListesi.find(
                (f) => f.firsatID === parseInt(e.target.value)
              );
              setSelectedFirsat(firsat || null);
            }}
          >
            {firsatListesi.map((firsat) => (
              <option key={firsat.firsatID} value={firsat.firsatID}>
                {firsat.firsatAdi} puan: {firsat.firsatPuani}
              </option>
            ))}
          </select>
        </div>
        <div className="modal-footer">
          <button onClick={handleFirsatTanimla}>Fırsatı Tanımla</button>
        </div>
      </Modal> */}
          <Modal
            isOpen={modalState.isOpen}
            onClose={() => {
              setModalState({ ...modalState, isOpen: false });
              setFirsatListesi([]);
            }}
            size="medium"
          >
            {renderModalContent()}
          </Modal>
        </div>
      </div>
    </div>
  );
};
export default LoyaltyMisafirTabPanel;

const GuestList: React.FC<{ guests: Misafir[] }> = ({ guests }) => {
  const [updateResults, setUpdateResults] = useState<UpdateResult>({});
  const [loadingState, setLoadingState] = useState<{
    [misafirID: string]: boolean;
  }>({});
  const [completedRequests, setCompletedRequests] = useState<number>(0); // Güncellenen misafir sayısını tutacak state

  useEffect(() => {
    const updateGuestPointsSequentially = async () => {
      for (const guest of guests) {
        setLoadingState((prevState) => ({
          ...prevState,
          [guest.misafirID]: true,
        }));

        try {
          const response = await axios.post(
            `https://api.rmosloyalty.com/PuanGuncelleme/${guest.misafirID}`
          );
          setUpdateResults((prevResults) => ({
            ...prevResults,
            [guest.misafirID]: response.data,
          }));
        } catch (error) {
          console.error("API isteği sırasında bir hata oluştu", error);
          setUpdateResults((prevResults) => ({
            ...prevResults,
            [guest.misafirID]: { hata: true },
          }));
        }

        setLoadingState((prevState) => ({
          ...prevState,
          [guest.misafirID]: false,
        }));

        // Misafir sayısını aşmamak üzere completedRequests'i artır
        setCompletedRequests((prevCount) => {
          const newCount = prevCount + 1;
          return newCount > guests.length ? guests.length : newCount;
        });
      }
    };

    if (guests.length > 0) {
      updateGuestPointsSequentially();
    }
  }, [guests]); // guests array'i değiştiğinde bu useEffect çalışır

  const getProgressValue = (guestId: number) =>
    loadingState[guestId]
      ? 50
      : updateResults[guestId]?.hata
      ? 100
      : updateResults[guestId]
      ? 100
      : 0;

  const getProgressLabel = (guestId: number) =>
    updateResults[guestId]?.hata
      ? "Başarısız"
      : `${getProgressValue(guestId)}%`;

  return (
    <>
      <div>
        {completedRequests === guests.length
          ? `Tamamlandı ${completedRequests} / ${guests.length}`
          : `${completedRequests} / ${guests.length}`}
      </div>
      <ListGroup>
        {guests.map((guest) => (
          <ListGroup.Item
            key={guest.misafirID}
            className="d-flex justify-content-between align-items-center"
          >
            <div className="me-auto">
              <div className="fw-bold">{guest.misafirAdi}</div>
              {updateResults[guest.misafirID] &&
                !updateResults[guest.misafirID].hata && (
                  <div> Son Puan: {updateResults[guest.misafirID].sonPuan}</div>
                )}
            </div>
            <ProgressBar
              now={getProgressValue(guest.misafirID)}
              label={getProgressLabel(guest.misafirID)}
              striped={loadingState[guest.misafirID]}
              animated={loadingState[guest.misafirID]}
              variant={
                updateResults[guest.misafirID]?.hata ? "danger" : "primary"
              }
              style={{ height: "20px", width: "200px" }}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};

const MisafirFirsatHareket: React.FC<{ otelGuid: string }> = ({ otelGuid }) => {
  return <div></div>;
};

const FirsatHareketMenu: React.FC<{
  firsatHareketID: number;
  misafirID: number | 0;
  onDelete: any;
  onCancel: any;
}> = ({ firsatHareketID, misafirID, onDelete, onCancel }) => {
  /*
  kullanılıcağı fonksiyona göre 
  const handleDelete = (firsatHareketID:any) => {
    // Silme işlemlerini gerçekleştir
    console.log(`Silme işlemi için fırsat hareket ID: ${firsatHareketID}`);
  };
  
  const handleCancel = (firsatHareketID:number) => {
    // İptal etme işlemlerini gerçekleştir
    console.log(`İptal etme işlemi gerçekleştirildi ${firsatHareketID}`);
  };

  ÇAĞRILACAĞI YERİ AYARLA 

     <FirsatHareketMenu
     firsatHareketID={hareket.hrkID}
     misafirID={selectedMisafir?.misafirID || 0}
     onDelete={handleDelete}
     onCancel={handleCancel}
   />
  
  */
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = () => {
    onDelete(firsatHareketID);
    setIsOpen(false);
  };

  const handleCancel = () => {
    onCancel(firsatHareketID);
    setIsOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <button className="btn w-100" onClick={handleMenuClick}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      {isOpen && (
        <div className="menu2">
          <button /*onClick={handleDelete}*/>
            <FontAwesomeIcon icon={faTrashAlt} /> Sil
          </button>
          <button /*onClick={handleCancel}*/>
            <FontAwesomeIcon icon={faTimes} /> İptal Et
          </button>
        </div>
      )}
    </div>
  );
};

interface IProduct {
  ProductID: number;
  ProductName: string;
  UnitPrice: number;
  UnitsInStock: number;
}

const initialDataState: State = {
  take: 10,
  skip: 0,
  sort: [],
  filter: undefined, // filter null yerine undefined olarak ayarlandı
};

const Ferhat: React.FC = () => {
  const [dataState, setDataState] = React.useState<State>(initialDataState);
  const [data, setData] = React.useState<IProduct[]>([]);
  const [filteredData, setFilteredData] = React.useState<IProduct[]>([]);

  React.useEffect(() => {
    // Sahte veri örneği
    const products: IProduct[] = [
      { ProductID: 1, ProductName: "Chai", UnitPrice: 18, UnitsInStock: 39 },
      { ProductID: 2, ProductName: "Chang", UnitPrice: 19, UnitsInStock: 17 },
      {
        ProductID: 3,
        ProductName: "Aniseed Syrup",
        UnitPrice: 10,
        UnitsInStock: 13,
      },
      {
        ProductID: 4,
        ProductName: "Chef Anton's Cajun Seasoning",
        UnitPrice: 22,
        UnitsInStock: 53,
      },
      {
        ProductID: 5,
        ProductName: "Chef Anton's Gumbo Mix",
        UnitPrice: 21.35,
        UnitsInStock: 0,
      },
      {
        ProductID: 6,
        ProductName: "Grandma's Boysenberry Spread",
        UnitPrice: 25,
        UnitsInStock: 120,
      },
      {
        ProductID: 7,
        ProductName: "Uncle Bob's Organic Dried Pears",
        UnitPrice: 30,
        UnitsInStock: 15,
      },
      {
        ProductID: 8,
        ProductName: "Northwoods Cranberry Sauce",
        UnitPrice: 40,
        UnitsInStock: 6,
      },
      {
        ProductID: 9,
        ProductName: "Mishi Kobe Niku",
        UnitPrice: 97,
        UnitsInStock: 29,
      },
      { ProductID: 10, ProductName: "Ikura", UnitPrice: 31, UnitsInStock: 31 },
      {
        ProductID: 11,
        ProductName: "Queso Cabrales",
        UnitPrice: 21,
        UnitsInStock: 22,
      },
      {
        ProductID: 12,
        ProductName: "Queso Manchego La Pastora",
        UnitPrice: 38,
        UnitsInStock: 86,
      },
      { ProductID: 13, ProductName: "Konbu", UnitPrice: 6, UnitsInStock: 24 },
      {
        ProductID: 14,
        ProductName: "Tofu",
        UnitPrice: 23.25,
        UnitsInStock: 35,
      },
      {
        ProductID: 15,
        ProductName: "Genen Shouyu",
        UnitPrice: 15.5,
        UnitsInStock: 39,
      },
      {
        ProductID: 16,
        ProductName: "Pavlova",
        UnitPrice: 17.45,
        UnitsInStock: 29,
      },
      {
        ProductID: 17,
        ProductName: "Alice Mutton",
        UnitPrice: 39,
        UnitsInStock: 0,
      },
      {
        ProductID: 18,
        ProductName: "Carnarvon Tigers",
        UnitPrice: 62.5,
        UnitsInStock: 42,
      },
      {
        ProductID: 19,
        ProductName: "Teatime Chocolate Biscuits",
        UnitPrice: 9.2,
        UnitsInStock: 25,
      },
      {
        ProductID: 20,
        ProductName: "Sir Rodney's Marmalade",
        UnitPrice: 81,
        UnitsInStock: 40,
      },
      {
        ProductID: 21,
        ProductName: "Sir Rodney's Scones",
        UnitPrice: 10,
        UnitsInStock: 3,
      },
      {
        ProductID: 22,
        ProductName: "Gustaf's Knäckebröd",
        UnitPrice: 21,
        UnitsInStock: 104,
      },

      // Daha fazla ürün ekleyin
    ];
    setData(products);
    const processedData = process(products, dataState) as any; // process'in dönüş tipi any olarak cast edildi
    setFilteredData(processedData.data); // DataResult içinden data'yı ayıklıyoruz
  }, [dataState]);

  const handleDataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  // Stil için CSS sınıfı tanımlayın ve .k-grid-header .k-header'e uygulayın
  return (
    <div>
      <Grid
        data={process(filteredData, dataState).data} // process sonucu doğrudan kullanılıyor
        {...dataState}
        onDataStateChange={handleDataStateChange}
        pageable={true}
        sortable={true}
        filterable={true}
        // style prop'u yerine className kullanılabilir
      >
        <Column field="ProductID" title="ID" filter="numeric" />
        <Column field="ProductName" title="Product Name" />
        <Column field="UnitPrice" title="Unit Price" filter="numeric" />
        <Column field="UnitsInStock" title="Units In Stock" filter="numeric" />
      </Grid>
    </div>
  );
};
